import { ButtonGroup, Flex } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import Loader from "../../sistema/components/Loader";
import { ListHeader } from "../../sistema/components/ListHeader";
import Layout from "../../sistema/components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { ProdutoBobjeto } from "../components/ProdutoBobjeto";
import { Produto } from "../types/produto";
import ResponsiveTable, {
  TableHeaders,
} from "../../sistema/components/ResponsiveTable";
import { StockCurrent } from "../types/stock_current";
import { entradaesaidaActions } from "../recucer";
import { Section } from "../../sistema/components/Section";
import { formatValor } from "../../utils/formatValor";
import { getDecimalPlacesOfSystem } from "../../sistema/utils/getDecimalPlacesOfSystem";
import { IncludeStock } from "../components/IncludeStock";
import { useEffect, useState } from "react";
import { pdvActions } from "../../pdv/reducer";
import { CalculateProductStock } from "../components/CalculateProductStock";

export const StockFind = () => {
  const isLoading = useSelector(
    (state: RootState) => !!state.entradaesaida?.isLoading
  );
  const stocksCurrent = useSelector(
    (state: RootState) => state.entradaesaida?.stocksCurrent ?? []
  );
  const success = useSelector(
    (state: RootState) => state.entradaesaida?.success
  );

  const dispatch = useDispatch();

  const [currentItem, setCurrentItem] = useState<Produto | null>(null);

  const getProductData = (product: Produto) => {
    setCurrentItem(product);
    dispatch(entradaesaidaActions.stocksCurrentRequest(product?.id ?? null));
  };

  useEffect(() => {
    dispatch(pdvActions.getLocationsStockRequest());
  }, []);

  useEffect(() => {
    if (success) {
      if (currentItem) {
        getProductData(currentItem);
      }
      dispatch(entradaesaidaActions.success(""));
    }
  }, [success]);

  const headers: TableHeaders<StockCurrent>[] = [
    {
      label: "Local de Estoque",
      wrapped: true,
      render: (item) => (
        <div className="font-semibold">{item?.stockLocation?.name ?? ""}</div>
      ),
      notClicable: true,
    },
    {
      label: "Quantidade Disponível",
      wrapped: true,
      render: (item) => (
        <div className="font-semibold">
          {formatValor(item?.quantity_available, getDecimalPlacesOfSystem())}
        </div>
      ),
      notClicable: true,
    },
    {
      label: "Ações",
      wrapped: true,
      render: (item) => (
        <ButtonGroup>
          {/* <DefaultButton
            size={"sm"}
            className="flex flex-wrap gap-1 items-center align-center"
          >
            <FaClipboardList /> Movimentação
          </DefaultButton> */}
          <IncludeStock
            produto_id={item.produto_id}
            stock_location_id={item.stock_location_id}
          />
        </ButtonGroup>
      ),
      notClicable: true,
    },
  ];

  return (
    <Layout>
      <ListHeader label={"Buscar Estoque do Produto"}>
        {currentItem && (
          <>
            {" "}
            <CalculateProductStock product_id={currentItem?.id ?? ""} />
          </>
        )}
      </ListHeader>

      <Loader isLoading={isLoading} />

      <div className="w-full my-4"></div>

      <Section>
        <div className="p-6">
          <Formik
            enableReinitialize
            initialValues={{ produto_id: "" }}
            onSubmit={(val, { setErrors }) => {}}
          >
            {({ values }) => (
              <Form>
                <Flex width="full" wrap="wrap">
                  <ProdutoBobjeto
                    label="Selecione o produto"
                    name="produto_id"
                    onSelectItem={getProductData}
                  />
                </Flex>
              </Form>
            )}
          </Formik>

          <div className="w-full sm:w-full">
            <ResponsiveTable headers={headers} data={stocksCurrent} />
          </div>
          <div className="w-full font-semibold text-gray-700 p-6">
            {stocksCurrent.length === 0 ? "Nenhum estoque localizado." : ""}
          </div>
        </div>
      </Section>
    </Layout>
  );
};
