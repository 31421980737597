import { useIsAuth } from "../../hooks/useIsAuth"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { FiTrash } from "react-icons/fi";
import { cadastroActions } from "../reducer";
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import { ListInput } from "../../sistema/components/ListInput";
import ResponsiveTable, { TableHeaders } from "../../sistema/components/ResponsiveTable";
import { Pagination } from "../../sistema/components/Pagination";
import { ConfirmButton } from "../../sistema/components/ConfirmButton";
import { Rota } from "../types/rota";

export const RotaList = () => {
  useIsAuth();

  const rotas = useSelector((state: RootState) => state.cadastro.rotas);
  const isLoading = useSelector((state: RootState) => !!state.cadastro.isLoading);
  const success = useSelector((state: RootState) => !!state.cadastro.success);
  const dispatch = useDispatch()
  const [page, setPage] = useState(1);
  const [termo, setTermo] = useState("");

  useEffect(() => {
    dispatch(cadastroActions.rotasRequest({
      page,
      termo,
    }))
  }, [dispatch, page, termo])

  useEffect(() => {
    if (!success) {
      return;
    }
    dispatch(cadastroActions.rotasRequest({
      page,
      termo,
    }))
  }, [dispatch, success, page, termo])

  const headers: TableHeaders<Rota>[] = [
    {
      label: "Código",
      wrapped: true,
      render: (item) => item.codigo,
    },
    {
      label: "Descrição",
      wrapped: true,
      render: (item) => item.descricao,
    },
    {
      label: "Qtd. Cidades",
      wrapped: true,
      render: (item) => item.cidades?.length,
    },
    {
      label: "Ações",
      wrapped: true,
      render: (item, index) => <>{<ConfirmButton confirm={() => onDeleteItem(item.id as number)}><FiTrash /></ConfirmButton>}</>,
      notClicable: true,
    },
  ];

  const onDeleteItem = (id: number) => {
    dispatch(cadastroActions.deleteRotaRequest(id));
  }

  const navigate = useNavigate();

  return (
    <Layout>
      <ListHeader label={`Rotas`}>
        <DefaultButton ml={4} to={`/rotas/novo`}> Novo </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      <ListInput label="Filtrar por código, descrição ou cidade" onSubmit={setTermo} />

      {rotas && <ResponsiveTable isSmall onClick={(item: any) => navigate(`/rotas/${item.item.id}`)} headers={headers} data={rotas.data} />}

      {rotas && <Pagination info={rotas} paginate={setPage} />}
    </Layout>
  )
}