import { NotaPromissoriaItem } from "../types/nota_promissoria_item";

export const novaNotaPromissoriaItem = (): NotaPromissoriaItem => {
  return {
    quantidade: "1",
    valor: "0",
    produto_id: "",
    nota_promissoria_id: "",
  };
};
