export function formatNumber(val: number | string, decimalPlaces = 0, showZero = false) {
  if (!val) {
    const zeroVal = 0;
    return showZero ? zeroVal.toFixed(decimalPlaces).replace(".", ",") : "";
  }

  if (decimalPlaces > 3) {
    const number = typeof val === "number" ? val : parseFloat(val);

    const formattedNumber = number.toFixed(decimalPlaces);

    const firstPart = formattedNumber.split('.')[0];
    const lastPart = formattedNumber.split('.')[1];

    return `${firstPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".")},${lastPart}`;
  }
  
  if (typeof val === "number") {
    return val
      .toFixed(decimalPlaces)
      .replace(".", ",")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  } else {
    return parseFloat(val)
      .toFixed(decimalPlaces)
      .replace(".", ",")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
}
