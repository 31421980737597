import { PayloadAction } from "@reduxjs/toolkit";
import { put, all, takeLatest } from "redux-saga/effects";
import { call } from "redux-saga/effects";
import { apiCall } from "../utils/apiCall";
import { formatError } from "../utils/formatError";
import { cashRegisterActions } from "./reducer";
import { CashRegister } from "./types/cash_register";
import { ApiPaginationRes } from "../sistema/types/apiPaginationRes";
import { AxiosResponse } from "axios";
import { ListPayload } from "../sistema/types/listPayload";

function* cashRegistersRequestWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<CashRegister[]>> =yield call(apiCall, {
      url: `cash-register?page=${payload.page}`,
      method: "get",
      data: payload,
    });
    yield put(cashRegisterActions.cashRegistersRequestSuccess({ data: res.data, page: payload.page }));
  } catch (error: any) {
    yield put(cashRegisterActions.error(formatError(error)));
  }
}

function* cashRegisterRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (! payload) {
      yield put(cashRegisterActions.cashRegisterRequestSuccess(null));
      return;
    }
    const res: AxiosResponse<CashRegister> =yield call(apiCall, {
      url: `cash-register/${payload}?include=transactions`,
      method: "get",
    });
    yield put(cashRegisterActions.cashRegisterRequestSuccess(res.data));
  } catch (error: any) {
    yield put(cashRegisterActions.error(formatError(error)));
  }
}

export function* cashRegisterSaga() {
  yield all([
    takeLatest("cashRegister/cashRegistersRequest", cashRegistersRequestWorker),
    takeLatest("cashRegister/cashRegisterRequest", cashRegisterRequestWorker),

  ]);
}
