import { Stock, StockStatus, StockType } from "../types/stock";

export const newStock = (): Stock => {
  return {
    stock_location_id: "",
    quantity: 1,
    produto_id: "",
    type: StockType.Input,
    canceled: 0,
    description: "",
    stockable_id: "",
    stockable_type: "",
    status: StockStatus.Approved,
  };
};
