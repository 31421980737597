export const formatValor = (valor: number | string, digits = 2) => {
  if (typeof valor == "string") {
    valor = parseFloat(valor);
  }
  if (typeof valor == "undefined") {
    return "0";
  }
  return valor.toLocaleString("pt-br", {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
};
