export const getFieldsTypes = () => {
  return [
    {
      name: "Texto Livre",
      value: "string",
    },
    {
      name: "Lista de Seleção",
      value: "array",
    },
    {
      name: "Sim ou Não",
      value: "simnao",
    },
    {
      name: "Uma Data",
      value: "data",
    },
  ];
};
