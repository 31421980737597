import { all } from "redux-saga/effects";
import { sistemaSaga } from "../sistema/saga";
import { cadastroSaga } from "../cadastros/saga";
import { crmSaga } from "../crm/saga";
import { entradaesaidaSaga } from "../entradaesaida/saga";
import { financeiroSaga } from "../financeiro/saga";
import { pdvSaga } from "../pdv/saga";
import { cashRegisterSaga } from "../cash-register/saga";

export function* mainSaga() {
  yield all([
    sistemaSaga(),
    cadastroSaga(),
    crmSaga(),
    entradaesaidaSaga(),
    financeiroSaga(),
    pdvSaga(),
    cashRegisterSaga(),
  ]);
}
