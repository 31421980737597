import { Box, Button, Flex, Heading } from "@chakra-ui/react"
import { Form, Formik, useField, useFormikContext } from "formik"
import { crmActions } from "../reducer"
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../sistema/components/InputField";
import { RootState } from "../../app/mainReducer";
import InputMultiSelect from "../../sistema/components/InpurMultiSelect";
import { getArrayUfs } from "../../utils/ufs";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { Pessoa } from "../../cadastros/types/pessoa";
import ResponsiveTable, { TableHeaders } from "../../sistema/components/ResponsiveTable";
import { mensagemErro, mensagemSucesso } from "../../utils/toasts";
import { FiTrash } from "react-icons/fi";
import { apiCall } from "../../utils/apiCall";
import { useNavigate } from "react-router-dom";
import { formatData } from "../../utils/data";
import { InputSelectArray } from "../../sistema/components/InputSelectArray";
import { Pagination } from "../../sistema/components/Pagination";
import { CamposPersonalizadosFiltros } from "../../sistema/components/CamposPersonalizadosFiltros";

export type IFichaCrmCriarFiltros = {
  rotas: number[];
  cidades: number[];
  ufs: number[];
  status: number[];
  tipos_pessoa: number[];
  cnpj: string;
  nome: string;
  comprou_inicio: string;
  comprou_fim: string;
  nao_comprou_inicio: string;
  nao_comprou_fim: string;
  empresas: string | number;
  nome_ficha?: string;
  data_integracao: string;
}

export interface IPessoaFichaCrmCriar extends Pessoa {
  rota_codigo: string;
  rota_descricao: string;
  ultima_compra: string;
}

export const FichaCrmCriar = () => {
  const [currentItem, setCurrentItem] = useState<IFichaCrmCriarFiltros>({
    rotas: [],
    cnpj: '',
    nome: '',
    cidades: [],
    ufs: [],
    status: [],
    tipos_pessoa: [],
    comprou_inicio: '',
    comprou_fim: '',
    nao_comprou_inicio: '',
    nao_comprou_fim: '',
    nome_ficha: '',
    empresas: '',
    data_integracao: '',
  })

  const dispatch = useDispatch();

  const isMobile = useSelector((state: RootState) => state.sistema.isMobile);
  const selectInputs = useSelector((state: RootState) => state.crm.fichaSelectInputs);

  const w_1_3 = isMobile ? '100%' : '33.33%';
  const w_1_4 = isMobile ? '100%' : '25%';
  const w_1_5 = isMobile ? '100%' : '20%';
  const w_1_2 = isMobile ? '100%' : '50%';

  const getRotasFormatadas = () => {
    return (selectInputs?.rotas ?? []).map((i) => ({
      label: `${i.codigo} - ${i.descricao}`,
      value: i.id as number,
    }))
  }

  const getCidadesFormatadas = () => {
    return (selectInputs?.cidades ?? []).map((i) => ({
      label: `${i.nome} - ${i.uf}`,
      value: i.id as number,
    }))
  }

  const getUfsFormatadas = () => {
    return getArrayUfs().map((i) => ({
      label: i.name,
      value: i.value,
    }))
  }

  const getStatusFormatados = () => {
    return [
      {
        label: 'Ativo',
        value: 'Ativo',
      },
      {
        label: 'Inativo',
        value: 'Inativo',
      },
    ]
  }

  const getTipoPessoaFormatados = () => {
    return [
      {
        label: 'Pessoa física',
        value: 'Pessoa física',
      },
      {
        label: 'Pessoa jurídica',
        value: 'Pessoa jurídica',
      },
    ]
  }

  const retiraClienteDaLista = (index: number) => {
    dispatch(crmActions.retiraClienteDaLista(index));
  }

  const itensArrayEmpresas = useSelector((state: RootState) => (state.sistema?.listas ?? {})['empresas'] ?? []);

  const headers: TableHeaders<IPessoaFichaCrmCriar>[] = [
    {
      label: "Pessoa",
      wrapped: false,
      render: (item) => `${item.cpf_cnpj} - ${item.nome}`,
    },
    {
      label: "Endereço",
      wrapped: isMobile,
      render: (item) => `${item.endereco} - ${item.numero}`,
    },
    {
      label: "Cidade",
      wrapped: !isMobile,
      render: (item) => `${item.cidade?.nome} - ${item.cidade?.uf} (${item.cep})`,
    },
    // {
    //   label: "Rota",
    //   wrapped: false,
    //   render: (item) => !item.rota_codigo ? 'Nenhuma' : `${item.rota_codigo} - ${item.rota_descricao}`,
    // },
    // {
    //   label: "Empresa",
    //   wrapped: false,
    //   render: (item) => !item.array_empresas ? 'Nenhuma' : `${itensArrayEmpresas.find((i) => i.value == item.array_empresas)?.label}`,
    // },
    {
      label: "Última compra",
      wrapped: false,
      render: (item) => !item.ultima_compra ? 'Nenhuma' : `${formatData(item.ultima_compra)}`,
    },
    {
      label: "Status",
      wrapped: !isMobile,
      render: (item) => `${item.status}`,
    },
    {
      label: "Ações",
      wrapped: true,
      render: (item, index) => <>{<Button colorScheme="red" size={"xs"} onClick={() => retiraClienteDaLista(index)}><FiTrash /></Button>}</>,
      notClicable: true,
    },
  ];

  const novaFichaPessoas = useSelector((state: RootState) => state.crm.novaFichaPessoas ?? []);

  const ButtonGerarFicha = () => {
    const [, { value }, { setError }] = useField('nome_ficha');
    const formik = useFormikContext();

    const geraFicha = () => {
      if (!value) {
        setError('Coloque o nome da ficha!')
        mensagemErro('Para gerar uma nova ficha é necessário colocar o nome!')
        return;
      }

      formik.submitForm();
    }

    return (
      <Button mb={-6} colorScheme="green" type="button" onClick={geraFicha}>Gerar ficha CRM com {novaFichaPessoas.length} cliente(s)</Button>
    )
  }

  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);

  // Função de paginação
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const itemsPerPage = 40;
  // Divida o array com base na página atual
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = novaFichaPessoas.slice(startIndex, endIndex);

  return (
    <Box width={"full"}>
      {currentItem && <Formik
        enableReinitialize
        initialValues={currentItem}
        onSubmit={async (val, { setErrors }) => {

          setCurrentItem(val);

          if (val.nome_ficha) {
            try {
              dispatch(crmActions.isLoading(true))

              const clientesIds = novaFichaPessoas.map((i) => i.id as number);

              const { data } = await apiCall({
                url: `fichas-gerar`, method: 'POST', data: {
                  clientes_ids: clientesIds,
                  nome: val.nome_ficha,
                }
              });

              mensagemSucesso(data.message)

              dispatch(crmActions.isLoading(false))
              dispatch(crmActions.buscaFichaPessoasRequestSuccess([]))

              navigate(`/fichas-crm/${data.ficha_id}`)
            } catch (err: any) {
              dispatch(crmActions.isLoading(false))
              console.log(err);
              mensagemErro("Erro ao gerar ficha crm");
            }
            return;
          }

          dispatch(crmActions.buscaFichaPessoasRequest({
            ...val,
            rotas: (val.rotas as any).map((i: any) => i.value),
            cidades: (val.cidades as any).map((i: any) => i.value),
            ufs: (val.ufs as any).map((i: any) => i.value),
            status: (val.status as any).map((i: any) => i.value),
            tipos_pessoa: (val.tipos_pessoa as any).map((i: any) => i.value),
          }));
        }}
      >
        {({ values, submitForm }) => (
          <Form>
            <Flex width="full" wrap="wrap">
              <InputField label="CNPJ/CPF" name="cnpj" width={w_1_4} />
              <InputField label="Nome" name="nome" width={w_1_4} />
              {/* <InputMultiSelect label="Rotas" name="rotas" options={getRotasFormatadas()} width={w_1_4} /> */}
              {/* <InputSelectArray arrayName="empresas" name="empresas" label="Empresa Origem" width={w_1_4} /> */}
              <InputMultiSelect label="Cidades" name="cidades" options={getCidadesFormatadas()} width={w_1_4} />
              <InputMultiSelect label="UFS" name="ufs" options={getUfsFormatadas()} width={w_1_3} />
              <InputMultiSelect label="Status" name="status" options={getStatusFormatados()} width={w_1_3} />
              <InputMultiSelect label="Tipo Pessoa" name="tipos_pessoa" options={getTipoPessoaFormatados()} width={w_1_3} />
              <InputField type="date" label="Comprou no período" name="comprou_inicio" width={w_1_5} />
              <InputField type="date" label="a" name="comprou_fim" width={w_1_5} />
              <InputField type="date" label="Não comprou no período" name="nao_comprou_inicio" width={w_1_5} />
              <InputField type="date" label="a" name="nao_comprou_fim" width={w_1_5} />
              <InputField type="date" label="Data integração" name="data_integracao" width={w_1_5} />

              <Flex mt={2} ml={1} width={"full"} />
              <CamposPersonalizadosFiltros onFilter={submitForm} />
              <DefaultButton ml={1} type="submit">Buscar Clientes</DefaultButton>

              <Heading size={"md"} width={'full'} my={4} ml={1}>
                Total de {novaFichaPessoas.length} cliente(s)
              </Heading>


              {novaFichaPessoas.length > 0 && <Flex width={w_1_2} wrap={"wrap"} alignItems={'center'}>
                <InputField name="nome_ficha" label="Nome da nova ficha" width={w_1_2} />
                <ButtonGerarFicha />
              </Flex>}
              {currentItems && <ResponsiveTable isSmall headers={headers} data={currentItems} />}
            </Flex>
          </Form>)}
      </Formik>}

      <Pagination
        info={{
          per_page: 40,
          current_page: currentPage,
          last_page: Math.ceil(novaFichaPessoas.length / itemsPerPage),
          total: novaFichaPessoas.length,
        }}
        paginate={paginate}
      />
    </Box>
  )
}