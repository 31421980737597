import { Box, Card, CardBody, CardHeader, Flex, Grid, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, StackDivider, Text, useDisclosure } from "@chakra-ui/react";
import { DefaultButton } from "../../sistema/components/DefaultButton"
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { Formik, Form } from "formik";
import validateForm from "../../utils/validateForm";
import { useRef, useState } from 'react'
import { crmActions } from "../reducer";
import { useDispatch, useSelector } from "react-redux";
import { apiCall } from "../../utils/apiCall";
import { RootState } from "../../app/mainReducer";
import InputField from "../../sistema/components/InputField";
import { mensagemErro } from "../../utils/toasts";
import { formatError } from "../../utils/formatError";
import { formatData } from "../../utils/data";
import { Agendamento } from "../types/agendamento";
import { novoAgendamento } from "../data/agendamento";
import { ButtonCardActions } from "../../cadastros/components/ButtonCardActions";

export const AgendamentosButton = ({ pessoa_id }: { pessoa_id: number | string }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const [agendamentos, setAgendamentos] = useState<Agendamento[]>([]);
  const [currentItem, setCurrentItem] = useState<Agendamento | null>(null);
  const [showForm, setShowForm] = useState<boolean>(false);
  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile)

  const onBuscaDados = async () => {
    try {
      dispatch(crmActions.isLoading(true))

      const { data } = await apiCall({
        url: `agendamentos-realizados/${pessoa_id}`, method: 'GET'
      });

      setAgendamentos(data);

      dispatch(crmActions.isLoading(false))
    } catch (err: any) {
      dispatch(crmActions.isLoading(false))
      console.log(err);
    }
  }

  const onSaveAgendamento = async (values: Agendamento) => {
    try {
      dispatch(crmActions.isLoading(true))

      await apiCall({
        url: `agendamentos`, method: 'POST', data: {
          ...values,
          changed: true,
          pessoa_id,
        }
      });

      onBuscaDados();
      setShowForm(false);

      dispatch(crmActions.isLoading(false))
    } catch (err: any) {
      mensagemErro(formatError(err))
      dispatch(crmActions.isLoading(false))
      console.log(err);
    }
  }

  const onClickAdicionarContato = () => {
    if (showForm) {
      setShowForm(false)
      return;
    }
    setCurrentItem(novoAgendamento());
    setShowForm(true);
  }

  const meuDivRef = useRef<any>(null)
  const onScrollToTop = () => {
    meuDivRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  const onDeleteItem = async (values: Agendamento) => {
    if (!window.confirm('Deseja realmente excluir o agendamento?')) {
      return;
    }

    try {
      dispatch(crmActions.isLoading(true))

      await apiCall({
        url: `agendamentos/${values.id}`, method: 'DELETE', data: {
          ...values,
          changed: true,
        }
      });

      onBuscaDados();
      setShowForm(false);

      dispatch(crmActions.isLoading(false))
    } catch (err: any) {
      mensagemErro(formatError(err))
      dispatch(crmActions.isLoading(false))
      console.log(err);
    }
  }

  return (
    <>
      {pessoa_id && <DefaultButton onClick={() => { onOpen(); onBuscaDados() }} borderRadius={"2px"} m={"2px"} size={"sm"}>
        Agendamentos
      </DefaultButton>}

      <Modal autoFocus={false} returnFocusOnClose={false} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"3xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ListHeaderSecondary label={'Agendamentos'}>
              <DefaultButton onClick={onClose} ml={2}>Voltar</DefaultButton>
            </ListHeaderSecondary>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={2}>
            <Flex ref={meuDivRef} width={"full"} wrap={"wrap"}>
              <DefaultButton onClick={() => onClickAdicionarContato()} borderRadius={"2px"} m={"2px"} size={"sm"}>
                {showForm ? 'Fechar' : 'Adicionar'}
              </DefaultButton>
            </Flex>

            {currentItem && showForm && <Formik
              initialValues={currentItem}
              enableReinitialize
              onSubmit={async (values, { setErrors }) => {
                const validation = validateForm({
                  data: 'required',
                }, values);

                if (Object.keys(validation).length > 0) {
                  setErrors(validation)
                  return;
                }

                onSaveAgendamento(values);
              }}
            >
              {({ values }) => (
                <Form>
                  <Flex width="full" wrap={"wrap"}>
                    <InputField type="datetime-local" label="Data / Hora" width={"full"} name="data" />

                    <InputField label="Descrição" name="descricao" width={"100%"} textarea={true} rows={3} />

                    <DefaultButton mt={1} type="submit" width={"full"}>
                      Salvar Agendamento
                    </DefaultButton>
                  </Flex>
                </Form>)}
            </Formik>}

            {agendamentos.length === 0 && <Heading size={"md"}>
              Nenhum encontrado
            </Heading>}

            <Grid mt={4} templateColumns={isMobile ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)'} gap={2}>
              {(agendamentos ?? []).map((i, index) => (
                <Card bg={"gray.100"} key={`${index}`}>
                  <CardHeader>
                    <Heading size='sm'>
                      {formatData(i.data)}

                      <ButtonCardActions onEdit={() => { setCurrentItem(i); setShowForm(true); onScrollToTop() }} onDelete={() => { onDeleteItem(i) }} />
                    </Heading>
                    <Text size=''>
                      {i.criador}
                    </Text>
                  </CardHeader>

                  <CardBody>
                    <Stack divider={<StackDivider />} spacing='4'>
                      <Box>
                        <Heading size='xs' textTransform='uppercase'>
                          Descrição
                        </Heading>
                        <Text pt='2' fontSize='sm'>
                          {i.descricao}
                        </Text>
                      </Box>
                    </Stack>
                  </CardBody>
                </Card>
              ))}

            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}