import { useState } from "react";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { DeleteButton } from "../../sistema/components/DeleteButton";
import { DialogModal } from "../../sistema/components/DialogModal";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import ResponsiveTable, { TableHeaders } from "../../sistema/components/ResponsiveTable";
import { getDecimalPlacesOfSystem } from "../../sistema/utils/getDecimalPlacesOfSystem";
import { apiCall } from "../../utils/apiCall";
import { formatData } from "../../utils/data";
import { formatError } from "../../utils/formatError";
import { formatValor } from "../../utils/formatValor";
import { mensagemErro } from "../../utils/toasts";
import { FinanceiroParcela } from "../types/financeiro_parcela";
import { FinanceiroParcelaQuitacao } from "../types/financeiro_quitacao";

type QuitacoesDaParcelaProps = {
  isOpen: boolean;
  onClose: () => void;
  onDeleted: (parcelas: FinanceiroParcela) => void;
  financeiroParcela: FinanceiroParcela;
}

export const QuitacoesDaParcela = ({ isOpen, onClose, onDeleted, financeiroParcela }: QuitacoesDaParcelaProps) => {
  const [isLoadingApagaQuitacao, setIsLoadingApagaQuitacao] = useState(false);

  const onDeleteQuitacao = async (item: FinanceiroParcelaQuitacao) => {
    try {
      setIsLoadingApagaQuitacao(true);
      const { data } = await apiCall({
        url: `apaga-quitacao/${item.id}`,
        method: "POST",
      });
      onDeleted(data);
      setIsLoadingApagaQuitacao(false);
    } catch (err) {
      setIsLoadingApagaQuitacao(false);
      mensagemErro(formatError(err));
    }
  };

  return (
    <DialogModal
      show={isOpen}
      close={onClose}
      maxWidth="4xl"
      title={
        <ListHeaderSecondary>
          Quitações da parcela { }
          <DefaultButton ml={2} onClick={onClose}>
            Voltar
          </DefaultButton>
        </ListHeaderSecondary>
      }
      content={
        <div className="w-full">
          {(financeiroParcela?.quitacoes ?? []).length > 0 && (
            <ResponsiveTable
              isSmall
              headers={
                [
                  {
                    label: "Bruto",
                    wrapped: true,
                    render: (item) =>
                      `R$ ${formatValor(
                        item.valor,
                        getDecimalPlacesOfSystem()
                      )}`,
                  },
                  {
                    label: "Juros",
                    wrapped: true,
                    render: (item) =>
                      `R$ ${formatValor(
                        item.valor_juros,
                        getDecimalPlacesOfSystem()
                      )}`,
                  },
                  {
                    label: "Desconto",
                    wrapped: true,
                    render: (item) =>
                      `R$ ${formatValor(
                        item.valor_desconto,
                        getDecimalPlacesOfSystem()
                      )}`,
                  },
                  {
                    label: "Líquido",
                    wrapped: true,
                    render: (item) =>
                      `R$ ${formatValor(
                        item.valor_liquido,
                        getDecimalPlacesOfSystem()
                      )}`,
                  },
                  {
                    label: "Data Baixa",
                    wrapped: false,
                    render: (item) =>
                      formatData(item.data_hora, "dd/MM/yyy"),
                  },
                  {
                    label: "Quando foi feito",
                    wrapped: true,
                    render: (item) =>
                      formatData(
                        item?.created_at ?? "",
                        "dd/MM/yyy HH:mm"
                      ),
                  },
                  {
                    label: "Ações",
                    wrapped: false,
                    render: (item) => (
                      <DeleteButton
                        isLoading={isLoadingApagaQuitacao}
                        confirmou={() => onDeleteQuitacao(item)}
                      />
                    ),
                  },
                ] as TableHeaders<FinanceiroParcelaQuitacao>[]
              }
              data={financeiroParcela?.quitacoes ?? []}
            />
          )}
        </div>
      }
      footer={
        <div className="w-full">
          <DefaultButton onClick={onClose}>Voltar</DefaultButton>
        </div>
      }
    />
  )
}