import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import { formatData, getCurrentData } from "../../utils/data";
import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { FichaPessoa } from "../../crm/types/ficha_pessoa";
import { retornaApenasNumero } from "../../utils/formatString";
import { formatCpfCnpj } from "../../utils/formatCpfCnpj";

Font.register({
  family: "Arial",
  fontStyle: "normal",
  fontWeight: "normal",
  src: "/fonts/Arial Unicode.ttf",
});

Font.register({
  family: "Arial",
  fontStyle: "normal",
  fontWeight: "bold",
  src: "/fonts/Arial Bold.ttf",
});

Font.register({
  family: "Arial",
  fontStyle: "italic",
  fontWeight: "normal",
  src: "/fonts/Arial Italic.ttf",
});

Font.register({
  family: "Arial",
  fontStyle: "italic",
  fontWeight: "bold",
  src: "/fonts/Arial Bold Italic.ttf",
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    marginTop: "1cm",
    marginBottom: "1cm",
    marginLeft: "1cm",
    marginRight: "1cm",
    width: "19cm",
    fontFamily: "Arial",
  },
  topoDemonstrativo: {
    flexDirection: "row",
    border: 1,
    borderRadius: 5,
    maxWidth: "19cm",
  },
  topoDadosBasicos: {
    flexDirection: "column",
    fontSize: 10,
    flexGrow: 1,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  topoDescricao: {
    marginLeft: "5mm",
    marginRight: "5mm",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  containerInfoFuncionario: {
    flexDirection: "column",
    marginTop: "3mm",
    paddingLeft: "1mm",
    paddingRight: "1mm",
    border: 1,
    borderRadius: 5,
    fontSize: 10,
    marginBottom: "3mm",
    maxWidth: "19cm",
  },
  headerInfoFuncionario: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  containerInfoHolerith: {
    flexDirection: "column",
    border: 1,
    borderRadius: 5,
    fontSize: 10,
    maxWidth: "19cm",
    marginTop: '0.5cm',
  },
  containerItensHolerith: {
    flexDirection: "row",
    minHeight: "18cm",
    borderBottom: 1,
  },
  containerColunaHolerith: {
    flexDirection: "column",
    borderRight: 1,
    paddingLeft: "2mm",
    paddingRight: "2mm",
    paddingBottom: "2mm",
    fontSize: '8px',
  },
  containerDatas: {
    flexDirection: "column",
    borderRight: 1,
    paddingLeft: "2mm",
    paddingRight: "2mm",
    paddingBottom: "2mm",
    alignItems: "center",
    fontSize: '8px',
  },
  containerColunaHolerithDescricao: {
    flexDirection: "column",
    borderRight: 1,
    flexGrow: 1,
    paddingLeft: "2mm",
    paddingRight: "2mm",
    paddingBottom: "2mm",
    fontSize: '8px',
  },
  textBold: {
    fontWeight: "bold",
  },
  textRight: {
    textAlign: "right",
  },
});

export const FichaCrmPdf = ({ fichaPessoas, parametros, filtros }: { fichaPessoas: FichaPessoa[], parametros: any, filtros: string[] }) => {
  return (
    <Document title={`FichaCRM-${getCurrentData()}`}>
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={{ fontSize: 9 }}>Ficha CRM emitida em {formatData(getCurrentData())} </Text>
        </View>
        <View style={styles.topoDemonstrativo}>
          <Image
            src="/logo512.png"
            cache
            style={{ width: "50px", height: "50px", margin: "5mm" }}
          />

          <View style={styles.topoDadosBasicos}>
            <Text style={styles.textBold}>
              {parametros?.nome_empresa ? parametros?.nome_empresa : 'AG Solution'}
            </Text>
          </View>
          <View style={styles.topoDescricao}>
            <Text style={{ ...styles.textBold, fontSize: '9px' }}>Filtros utilizados</Text>{"\n"}
            <Text>
              <>
                {filtros.map((item, index) => (
                  <Text key={index} style={{ fontSize: '7px' }}>
                    &bull; {item} {"\n"}
                  </Text>
                ))}
              </>
            </Text>
          </View>
          <View style={styles.topoDescricao}>
            <Text style={styles.textBold}>Ficha</Text>
            <Text style={styles.textBold}>CRM</Text>
          </View>
        </View>
        <View style={styles.containerInfoHolerith}>
          <View style={styles.containerItensHolerith}>
            <View style={styles.containerColunaHolerith}>
              <Text style={styles.textBold}>CPF/CNPJ</Text>
              {fichaPessoas?.map((item, i: number) => (
                <Text key={`${i}`}>{formatCpfCnpj(retornaApenasNumero(item?.pessoa?.cpf_cnpj ?? ''))}</Text>
              ))}
            </View>
            <View style={styles.containerColunaHolerithDescricao}>
              <Text style={styles.textBold}>Razão Social</Text>
              {fichaPessoas?.map((item, i: number) => (
                <Text key={`${i}`}>{item?.pessoa?.nome?.slice(0, 40)}</Text>
              ))}
            </View>
            <View style={styles.containerColunaHolerithDescricao}>
              <Text style={styles.textBold}>Cidade</Text>
              {fichaPessoas?.map((item, i: number) => (
                <Text key={`${i}`}>{item?.pessoa?.cidade?.nome} - {item?.pessoa?.cidade?.uf}</Text>
              ))}
            </View>
            <View style={styles.containerDatas}>
              <Text style={styles.textBold}>Última Agenda</Text>
              {fichaPessoas?.map((item: any, i: number) => (
                <Text key={`${i}`}>{item?.data_ultima_agenda ? formatData(item?.data_ultima_agenda) : 'Nenhuma'}</Text>
              ))}
            </View>
            <View style={styles.containerDatas}>
              <Text style={styles.textBold}>Última Compra</Text>
              {fichaPessoas?.map((item: any, i: number) => (
                <Text key={`${i}`}>{item?.data_ultimo_pedido ? formatData(item?.data_ultimo_pedido) : 'Nenhuma'}</Text>
              ))}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
