import { Empresa } from "../types/empresa";

export const novaEmpresa = (): Empresa => {
  return {
    nome: "",
    cpf_cnpj: "",
    celular: "",
    email: "",
  };
};
