import { Button, useDisclosure } from "@chakra-ui/react"
import React, { useState } from "react"
import { FiEdit } from "react-icons/fi"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/mainReducer";
import { DialogModal } from "../../../sistema/components/DialogModal";
import { ListHeaderSecondary } from "../../../sistema/components/ListHeaderSecondary";
import { Form, Formik } from "formik";
import { DefaultButton } from "../../../sistema/components/DefaultButton";
import InputField from "../../../sistema/components/InputField";
import { apiCall } from "../../../utils/apiCall";
import { mensagemErro, mensagemSucesso } from "../../../utils/toasts";
import { formatError } from "../../../utils/formatError";
import { cashRegisterActions } from "../../reducer";

export const EditObservations = () => {
  const selectedCashRegister = useSelector((state: RootState) => state.cashRegister?.cashRegister);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <Button onClick={onOpen} colorScheme="blackAlpha" size={'xs'}><FiEdit /></Button>

      <DialogModal
        show={isOpen}
        close={onClose}
        maxWidth="lg"
        title={
          <ListHeaderSecondary>
            Editar Observações
            <Button
              type="button"
              onClick={onClose}
              size={"sm"}
              ml={1}
              colorScheme="blackAlpha"
            >
              Voltar
            </Button>
          </ListHeaderSecondary>
        }
        content={
          <div className="w-full">
            {selectedCashRegister && <Formik
              enableReinitialize
              initialValues={selectedCashRegister}
              onSubmit={async (values, { setErrors }) => {
                try {
                  setIsLoading(true);
                  await apiCall({ url: 'cash-register', method: 'POST', data: {
                    ...values,
                    changed: true,
                  } })
                  setIsLoading(false);
                  mensagemSucesso("Salvo com sucesso!");
                  dispatch(cashRegisterActions.cashRegistersRequest({ page: 1, termo: '' }));
                  onClose();
                } catch (error: any) {
                  setIsLoading(false);
                  mensagemErro(formatError(error));
                }
              }}
            >
              {({ values, setFieldValue }) => (
                <Form style={{ width: "100%" }}>
                  <InputField
                    textarea
                    label="Observações"
                    name="observations"
                    width={'fulll'}
                    rows={3}
                    focus={true}
                  />
                  <div className="p-1">
                    <DefaultButton isLoading={isLoading} type="submit" mt={2} width={"full"}>
                      SALVAR
                    </DefaultButton>
                  </div>
                </Form>
              )}
            </Formik>}
          </div>
        }
        footer={
          <div className="w-full">
            <Button
              type="button"
              onClick={onClose}
              size={"sm"}
              colorScheme="blackAlpha"
            >
              Voltar
            </Button>
          </div>
        }
      />
    </React.Fragment>
  )
}