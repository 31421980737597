import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { FiEdit2, FiPlus, FiShoppingCart } from "react-icons/fi";
import { Pedido } from "../../../entradaesaida/types/pedido";
import { useDisclosure } from "@chakra-ui/react";
import { ItemForm } from "./ItemForm";
import { ItemsView } from "./ItemsView";
import { useDispatch } from "react-redux";
import { pdvActions } from "../../reducer";

export const Items = () => {
  const formValues = useFormikContext();

  const items = (formValues.values as Pedido)?.itens ?? [];

  const [itemId, setItemId] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const {
    isOpen: isOpenItemForm,
    onOpen: onOpenItemForm,
    onClose: onCloseItemForm,
  } = useDisclosure();

  const onEditItem = (index: number) => {
    setItemId(index as any as string);
    setIsEditing(false);
    onOpenItemForm();
  };

  const dispatch = useDispatch();

  useEffect(() => {
    console.log('items updated')
    dispatch(pdvActions.updateOrder(formValues.values as Pedido));
  }, [items]);

  return (
    <React.Fragment>
      <ItemForm
        isOpen={isOpenItemForm}
        isEditing={isEditing}
        onClose={() => {
          setItemId("");
          setIsEditing(false);
          onCloseItemForm();
        }}
        id={itemId}
      />
      <div className="rounded-md flex  bg-white w-full text-center text-gray-800 p-2 min-h-[65vh] max-h-[65vh] overflow-auto">
        {items.length === 0 && (
          <div className="m-auto text-blue-700 text-center">
            <FiShoppingCart
              size={"70px"}
              className="m-auto text-blue-700 mb-6"
            />
            Nenhum produto adicionado.
          </div>
        )}

        {items.length > 0 && (
          <ItemsView items={items} onEditItem={onEditItem} />
        )}
      </div>

      <div className="pt-2 min-h-[20vh] max-h-[20vh] flex w-full flex-wrap justify-around text-blue-700">
        <div
          onClick={() => {
            setIsEditing(false);
            setItemId("novo");
            onOpenItemForm();
          }}
          className="cursor-pointer shadow p-4 rounded flex justify-center flex-col font-semibold items-center bg-white hover:bg-blue-50 text-blue-700 font-semibold text-lg"
        >
          <FiPlus size={"55px"} className="mx-auto" />
          <span className="pt-6">Adicionar produto</span>
        </div>
        <div
          onClick={() => {
            setItemId("");
            setIsEditing(true);
            onOpenItemForm();
          }}
          className="cursor-pointer shadow p-4 rounded flex justify-center flex-col font-semibold items-center bg-white hover:bg-blue-50 text-blue-700 font-semibold text-lg"
        >
          <FiEdit2 size={"40px"} className="mx-auto" />
          <span className="pt-6">Editar produto</span>
        </div>
      </div>
    </React.Fragment>
  );
};
