const formatCNPJ = (cnpj: string): string => {
  return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
};

/**
 * @param cpf somente números
 * @returns cpf formatado no formato 000.000.000-00
 */
const formatCPF = (cpf: string): string => {
  return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};

export const formatCpfCnpj = (documento: string | number): string => {
  if (typeof documento != "string" && typeof documento != "number") {
    documento = "";
  }
  if (typeof documento == "number") {
    documento = documento.toString();
  }
  if (documento.length === 11) {
    return formatCPF(documento);
  }
  return formatCNPJ(documento);
};
