import { Rota } from "../types/rota";

export const novaRota = (): Rota => {
  return {
    codigo: "",
    descricao: "",
    ativo: 1,
    cidades: [],
  };
};
