import { Produto } from "../types/produto";

export const novoProduto = (): Produto => {
  return {
    codigo: '',
    nome: '',
    preco: '0',
    codigo_barras: '',
    ativo: 1,
    array_unit_measure: 1,
    controls_stock: 1,
    camposPersonalizados: [],
  }
}