import { useState, useEffect, useCallback } from "react";
import { PessoaCampoPersonalizado } from "../types/pessoa_campo_personalizado";
import { apiCall } from "../../utils/apiCall";
import InputField from "./InputField";
import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import InputSelect from "./InputSelect";
import { Flex, Link, useDisclosure } from "@chakra-ui/react";
import { CampoPersonalizado } from "../types/campo_personalizado";
import { novoCampoPersonalizado } from "../data/campo_personalizado";
import Loader from "./Loader";
import { CamposPersonalizadosGerenciar } from "./CamposPersonalizadosGerenciar";
import { CampoPersonalizadoForm } from "./CampoPersonalizadoForm";
import { useField } from "formik";

export const CamposPersonalizadosForm = ({
  par,
  idp,
  name = "camposPersonalizados",
  qtPorLinha = 4,
  tamanho = "default",
  retiraOpcaoAdicionarMais = false,
  atualiza = true,
  setQuantidadeFiltrosAtivos,
}: {
  par: string;
  idp: string | number;
  name?: string;
  qtPorLinha?: number;
  tamanho?: string;
  retiraOpcaoAdicionarMais?: boolean;
  atualiza?: boolean;
  setQuantidadeFiltrosAtivos?: (value: number) => void;
}) => {
  const [, { value }, { setValue }] = useField<PessoaCampoPersonalizado[]>({
    name,
  });
  const isMobile = useSelector((state: RootState) => !!state.sistema?.isMobile);

  const larguraInput = () => {
    if (tamanho != "default") return isMobile ? "100%" : tamanho;
    return isMobile ? "100%" : `${(100 / qtPorLinha).toFixed(2)}%`;
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    typeof setQuantidadeFiltrosAtivos === "function" &&
      setQuantidadeFiltrosAtivos((value ?? []).filter((i) => !!i.valor).length);
  }, [value]);

  useEffect(() => {
    if (!idp) {
      const buscaCampos = async () => {
        const { data } = await apiCall({
          url: `campos-personalizados/${par}/novo`,
          method: "GET",
        });

        setValue(data);
      };

      if (atualiza || !value) {
        buscaCampos();
      }
    }
  }, [idp, par, setValue]);

  const InputLocal = ({
    item,
    index,
  }: {
    item: PessoaCampoPersonalizado;
    index: number;
  }) => {
    const [, { value: valueLocal }, { setValue: setValueLocal }] =
      useField<string>({ name: `${name}[${index}].valor` });

    if (!item) {
      return <></>;
    }

    if (item.tipo.trim() == "string") {
      return (
        <InputField
          label={item.nome}
          name={`${name}[${index}].valor`}
          width={larguraInput()}
        />
      );
    }

    const onChangeSelect = (valor: string) => {
      if (valor === "add_option") {
        setValueLocal(valueLocal, false);
        onOpenModal(item.campo_personalizado_id);
        return;
      }

      setValueLocal(valor, false);
    };

    if (item.tipo.trim() == "array" || item.tipo.trim() == "simnao") {
      return (
        <InputSelect
          noPlaceholer
          onChangeSetValue={(evt) => onChangeSelect(evt)}
          label={item.nome}
          name={`${name}[${index}].valor`}
          width={larguraInput()}
        >
          <option selected value="">
            Selecione...
          </option>
          {typeof item.array != "string" &&
            item.array
              .filter((j) => j.ativo == 1)
              .map((i, ind) => (
                <option value={i.value} key={ind}>
                  {i.label}
                </option>
              ))}
          <option
            value="add_option"
            style={{
              backgroundColor: "#a3a3a3",
              cursor: "pointer",
              color: "white",
            }}
          >
            Adicionar mais uma opção
          </option>
        </InputSelect>
      );
    }

    if (item.tipo.trim() == "data") {
      return (
        <InputField
          label={item.nome}
          name={`${name}[${index}].valor`}
          type="date"
          width={larguraInput()}
        />
      );
    }

    return <></>;
  };

  const MemoizedInputLocal = useCallback(InputLocal, []);

  const [currentItem, setCurrentItem] = useState<CampoPersonalizado | null>(
    null
  );
  const [isLoadingLocal, setIsLoadingLocal] = useState(false);

  const onOpenModal = async (id: string | number) => {
    if (id == "novo") {
      setCurrentItem({
        ...novoCampoPersonalizado(),
        par,
      });
      onOpen();
      return;
    }
    setIsLoadingLocal(true);
    const { data } = await apiCall({
      url: `campos-personalizados/${id}`,
      method: "GET",
    });

    setCurrentItem(data);
    setIsLoadingLocal(false);
    onOpen();
  };

  return (
    <>
      <Loader isLoading={isLoadingLocal} />

      {(value ?? []).map((i: PessoaCampoPersonalizado, index: number) => (
        <MemoizedInputLocal item={i} key={`${index}`} index={index} />
      ))}
      <Flex width={"full"} />
      {!retiraOpcaoAdicionarMais && (
        <Link onClick={() => onOpenModal("novo")} px={1}>
          Adicionar campo
        </Link>
      )}
      {!retiraOpcaoAdicionarMais && <> </>}
      <CamposPersonalizadosGerenciar
        par={par}
        idp={idp as string}
        valuesInForm={value}
        setNewValuesInForm={setValue}
        retiraOpcaoAdicionarMais={retiraOpcaoAdicionarMais}
      />

      <CampoPersonalizadoForm
        isOpen={isOpen}
        onClose={onClose}
        par={par}
        idp={idp as string}
        currentItem={currentItem}
        savedForm={({ current, fields }) => {
          const campoEncontradoIndex = (
            value ?? ([] as PessoaCampoPersonalizado[])
          ).findIndex(
            (i: PessoaCampoPersonalizado) =>
              i.campo_personalizado_id == current.id
          );

          const campo = fields.find(
            (i: PessoaCampoPersonalizado) =>
              i.campo_personalizado_id == (current as any).id
          );

          const itens = JSON.parse(JSON.stringify(value));

          if (campoEncontradoIndex < 0) {
            itens.push(campo);
          } else {
            itens[campoEncontradoIndex] = campo;
          }
          setValue(itens);
        }}
      />
    </>
  );
};
