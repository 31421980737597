import { PedidoItem } from "../types/pedido_item";

export const novoPedidoItem = (): PedidoItem => {
  return {
    quantidade: 1,
    valor_unitario: 0,
    valor_total: 0,
    valor_desconto: 0,
    produto: "",
    pedido_id: "",
    peso: "0",
    produto_id: "",
    stock_location_id: "",
    comission_percentage: 0,
    comission_value: 0,
  };
};
