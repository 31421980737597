import { Button, ButtonProps } from "@chakra-ui/react"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../app/mainReducer";

interface IProps extends ButtonProps {
  to?: any;
}

export const DefaultButton = ({ children, to, ...props }: IProps) => {
  const navigate = useNavigate();
  const cor_primaria = useSelector((state: RootState) => state.sistema?.parametros?.cor_primaria ? state.sistema?.parametros?.cor_primaria : state.sistema.cor_primaria)
  const cor_primaria_hover = useSelector((state: RootState) => state.sistema?.parametros?.cor_primaria_hover ? state.sistema?.parametros?.cor_primaria_hover : state.sistema.cor_primaria_hover)

  const onClick = () => {
    if (!to) return;

    navigate(to);
  }

  return (
    <Button onClick={onClick} bg={cor_primaria} _hover={{ backgroundColor: cor_primaria_hover }} {...props} colorScheme="blackAlpha">{children}</Button>
  )
}