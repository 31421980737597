import { getCurrentData } from "../../utils/data";
import { CashRegister } from "../types/cash_register";

export const newCashRegister = (): CashRegister => ({
  date: getCurrentData('yyyy-MM-dd'),
  finished_at: null,
  observations: "",
  initial_value: 0,
  final_value: 0,
  transactions: [],
})