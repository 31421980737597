import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import { formatData, getCurrentData, parseData } from "../../utils/data";
import { retornaApenasNumero } from "../../utils/formatString";
import { formatCpfCnpj } from "../../utils/formatCpfCnpj";
import { IResponseRelatorioContatosRealizados, IResponseRelatorioPedido } from "../reducer";
import { Pedido } from "../../entradaesaida/types/pedido";
import { formatValor } from "../../utils/formatValor";
import { Pessoa } from "../../cadastros/types/pessoa";

Font.register({
  family: "Arial",
  fontStyle: "normal",
  fontWeight: "normal",
  src: "/fonts/Arial Unicode.ttf",
});

Font.register({
  family: "Arial",
  fontStyle: "normal",
  fontWeight: "bold",
  src: "/fonts/Arial Bold.ttf",
});

Font.register({
  family: "Arial",
  fontStyle: "italic",
  fontWeight: "normal",
  src: "/fonts/Arial Italic.ttf",
});

Font.register({
  family: "Arial",
  fontStyle: "italic",
  fontWeight: "bold",
  src: "/fonts/Arial Bold Italic.ttf",
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    marginTop: "1cm",
    marginBottom: "1cm",
    marginLeft: "1cm",
    marginRight: "1cm",
    width: "19cm",
    fontFamily: "Arial",
  },
  topoDemonstrativo: {
    flexDirection: "row",
    border: 1,
    borderRadius: 5,
    maxWidth: "19cm",
  },
  topoDadosBasicos: {
    flexDirection: "column",
    fontSize: 10,
    flexGrow: 1,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  topoDescricao: {
    marginLeft: "5mm",
    marginRight: "5mm",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  containerInfoHolerith: {
    flexDirection: "column",
    border: 1,
    borderRadius: 5,
    fontSize: 10,
    maxWidth: "19cm",
    marginTop: '0.5cm',
  },
  containerItensHolerith: {
    flexDirection: "row",
    minHeight: "24cm",
  },
  containerColunaHolerith: {
    flexDirection: "column",
    borderRight: 1,
    paddingLeft: "2mm",
    paddingRight: "2mm",
    paddingBottom: "2mm",
    fontSize: '8px',
  },
  textBold: {
    fontWeight: "bold",
  },
  textRight: {
    textAlign: "right",
  },
  table: {
    width: '100%',
    borderWidth: 2,
    display: 'flex',
    flexDirection: 'column',
    marginVertical: 12
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  cell: {
    borderWidth: 1,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    flexWrap: 'wrap'
  }
});

export const RelacaoContatosRealizadosPdf = ({ relatorio, parametros, filtros, itensArrayEmpresas }: { relatorio: IResponseRelatorioContatosRealizados, parametros: any, filtros: string[], itensArrayEmpresas: any }) => {
  return (
    <Document title={`Relação de Contatos Realizados-${getCurrentData()}`}>
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={{ fontSize: 9 }}>Relação de contatos realizados emitida em {formatData(getCurrentData())} </Text>
        </View>
        <View style={styles.topoDemonstrativo}>
          <Image
            src="/logo512.png"
            cache
            style={{ width: "50px", height: "50px", margin: "5mm" }}
          />

          <View style={styles.topoDadosBasicos}>
            <Text style={styles.textBold}>
              {parametros?.nome_empresa ? parametros?.nome_empresa : 'AG Solution'}
            </Text>
          </View>
          <View style={styles.topoDescricao}>
            <Text style={{ ...styles.textBold, fontSize: '9px' }}>Filtros utilizados</Text>{"\n"}
            <Text>
              <>
                {filtros.map((item, index) => (
                  <Text key={index} style={{ fontSize: '7px' }}>
                    &bull; {item} {"\n"}
                  </Text>
                ))}
              </>
            </Text>
          </View>
          <View style={styles.topoDescricao}>
            <Text style={styles.textBold}>Relação De</Text>
            <Text style={styles.textBold}>Contatos</Text>
            <Text style={styles.textBold}> Realizados</Text>
          </View>
        </View>
        <View style={styles.containerInfoHolerith}>
          {(relatorio?.itens as any ?? [])?.map((item: Pessoa, i: number) => (
            <View key={i} style={{ padding: '5px', borderBottom: '1px solid #d3d3d3', maxWidth: "19cm", }}>
              <Text style={{ fontWeight: 700, fontSize: '10px', color: 'black', width: '100%' }}>
                {item?.nome}
              </Text>
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '60%' }}>
                  <Text style={{ fontSize: '7px' }}>
                    CPF/CNPJ: <Text style={styles.textBold}> {formatCpfCnpj(retornaApenasNumero(item?.cpf_cnpj ?? ''))}</Text> {"\n"}
                    Data Integração: <Text style={styles.textBold}> {!item.data_integracao ? 'Nenhuma' : parseData(item.data_integracao, 'dd/MM/yyyy')}</Text> {"\n"}
                    Status: <Text style={styles.textBold}> {item.status}</Text> {"\n"}
                  </Text>
                  <Text style={{ fontSize: '7px', marginTop: '8px' }}>
                    <Text style={{ fontSize: '8px', marginTop: '5px', ...styles.textBold }}>
                      Contatos Realizados
                    </Text> {"\n"}
                  </Text>
                  {(item?.contatosRealizados ?? []).map((j, index) => (
                    <Text key={index} style={{ width: '10cm', marginBottom: '3px', paddingBottom: '3px', borderBottom: index === (item?.contatosRealizados ?? []).length - 1 ? '' : '1px solid #f3f3f3', fontSize: '7px' }}>
                      Data: <Text style={styles.textBold}>{formatData(j.data)}</Text> {"\n"}
                      Forma de contato: <Text style={styles.textBold}>{j.forma_contato}</Text> {"\n"}
                      Motivo não venda: <Text style={styles.textBold}>{j.motivo_nao_compra}</Text> {"\n"}
                      Conversa: <Text style={styles.textBold}>{j.observacoes}</Text> {"\n"}
                    </Text>
                  ))}
                </View>
                <View style={{ maxWidth: '40%' }}>
                  <Text style={{ fontSize: '7px' }}>
                    Endereço: <Text style={styles.textBold}> {item?.endereco}, n° {item?.numero} </Text>
                  </Text>
                  <Text style={{ fontSize: '7px' }}>
                    Cidade: <Text style={styles.textBold}> {item?.cidade?.nome} - {item?.cidade?.uf} </Text>
                  </Text>
                  {/* <Text style={{ fontSize: '7px' }}>
                    Empresa: <Text style={styles.textBold}>{item?.array_empresas ? itensArrayEmpresas.find((j: any) => j.value == item?.array_empresas as any)?.label : 'Nenhuma'} </Text>
                  </Text> */}
                </View>
              </View>
            </View>
          ))}
        </View>
      </Page >
    </Document >
  );
};
