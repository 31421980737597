import { Box, Button, Card, CardBody, CardHeader, Flex, Grid, Heading, Stack, StackDivider, Text } from "@chakra-ui/react"
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../app/mainReducer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { crmActions } from "../reducer";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import InputSelect from "../../sistema/components/InputSelect";
import { formatData, parseData } from "../../utils/data";
import InputField from "../../sistema/components/InputField";
import InputMultiSelect from "../../sistema/components/InpurMultiSelect";
import { Pagination } from "../../sistema/components/Pagination";
import { formatValor } from "../../utils/formatValor";
import { apiCall } from "../../utils/apiCall";
import { AgendamentosButton } from "./AgendamentosButton";
import { ContatosButton } from "./ContatosButton";
import { UltimosPedidosButton } from "./UltimosPedidosButton";
import { DadosPessoaButton } from "./DadosPessoaButton";
import { InputSelectArray } from "../../sistema/components/InputSelectArray";
import { CamposPersonalizadosView } from "../../sistema/components/CamposPersonalizadosView";
import { CamposPersonalizadosFiltros } from "../../sistema/components/CamposPersonalizadosFiltros";

export const coresStatusCards = [
  {
    name: 'Em branco',
    value: 0,
    bgColor: 'white',
    color: 'black',
    bgColorCard: 'white',
  },
  {
    name: 'Não ligar',
    value: 1,
    bgColor: '#dc3545',
    color: 'white',
    bgColorCard: 'rgb(255, 160, 122)',
  },
  {
    name: 'Ligou',
    value: 2,
    bgColor: '#ffc107',
    color: 'black',
    bgColorCard: 'rgb(255, 215, 0)',
  },
  {
    name: 'Vendeu',
    value: 3,
    bgColor: '#28a745',
    color: 'white',
    bgColorCard: 'rgb(152, 251, 152)',
  },
  {
    name: 'Aguardando',
    value: 4,
    bgColor: 'rgb(189, 80, 0)',
    color: 'white',
    bgColorCard: 'rgb(189, 100, 0)',
  },
  {
    name: 'Incomunicável',
    value: 5,
    bgColor: 'rgb(205, 151, 255)',
    color: 'black',
    bgColorCard: 'rgb(205, 181, 255)',
  },
  {
    name: 'Solicitou visita representante',
    value: 6,
    bgColor: 'rgb(91, 119, 248)',
    color: 'black',
    bgColorCard: 'rgb(91, 149, 248)',
  },
  {
    name: 'Clientes potencial',
    value: 7,
    bgColor: 'rgb(211, 211, 211)',
    color: 'black',
    bgColorCard: 'rgb(221, 211, 211)',
  },
]


export interface IFiltros {
  ficha_id: string;
  nome: string;
  status: { label: string; value: number }[];
  ufs: { label: string; value: string }[];
  cidades: { label: string; value: number }[];
  rotas: { label: string; value: number }[];
  status_item: string | number;
  page: number;
  order: string;
  empresas: string | number;
  data_integracao: string;
}

export const FichaCrmGerenciar = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fichas = useSelector((state: RootState) => state.crm.fichas ?? []);
  const ficha = useSelector((state: RootState) => state.crm.ficha);
  const isMobile = useSelector((state: RootState) => state.sistema.isMobile);

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (id != 'novo' && id) {
      setCurrentItem({
        ...currentItem,
        ficha_id: id as string,
        page: page,
      })
      dispatch(crmActions.fichaRequest({
        ...currentItem,
        ficha_id: id as string,
        page: page,
      }));
    } else {
      dispatch(crmActions.fichaRequest(currentItem));
    }
  }, [dispatch, id, page])

  const [currentItem, setCurrentItem] = useState<IFiltros>({ ficha_id: '', nome: '', status: [], ufs: [], cidades: [], rotas: [], status_item: '', page: 1, order: 'nome', empresas: '', data_integracao: '' });

  useEffect(() => {
    dispatch(crmActions.setaFiltrosFichaGerenciamento(currentItem));
  }, [currentItem, dispatch])

  useEffect(() => {
    setCurrentItem({
      ...currentItem,
      ficha_id: id as string,
    })
  }, [id])

  const getLarguraPagina = (partes: number) => {
    if (isMobile) return '100%';

    return `${100 / partes}%`;
  }

  const getStatusFormatados = () => {
    return [
      {
        label: 'Ativo',
        value: 'Ativo',
      },
      {
        label: 'Inativo',
        value: 'Inativo',
      },
    ]
  }

  const onChangeStatus = async (id: number, status: number) => {
    try {
      dispatch(crmActions.isLoading(true))
      await apiCall({
        url: `fichas-altera-status`, method: 'POST', data: {
          id,
          status,
        }
      });
      dispatch(crmActions.fichaRequest(currentItem));
    } catch (err: any) {
      dispatch(crmActions.isLoading(false))
    }
  }

  const itensArrayEmpresas = useSelector((state: RootState) => (state.sistema?.listas ?? {})['empresas'] ?? []);

  const onSearch = () => {
    dispatch(crmActions.fichaRequest({
      ...currentItem,
      ficha_id: id as string,
      page: page,
    }));
  }

  return (
    <Box width={"full"}>
      {currentItem && <Formik
        enableReinitialize
        initialValues={currentItem}
        onSubmit={async (val, { setErrors }) => {

          console.log('submit', val)
          setCurrentItem(val);

          dispatch(crmActions.fichaRequest(val));
        }}
      >
        {({ values, submitForm, setFieldValue }) => (
          <Form>
            <Flex width="full" wrap="wrap" alignItems={"center"}>

              <InputSelect
                label="Ficha"
                name="ficha_id"
                width={getLarguraPagina(7)}
                onChangeVal={(i) => i && navigate(`/fichas-crm/${i}`)}
              >
                {fichas && fichas.length >= 0 && fichas.map((i) => (
                  <option style={{ backgroundColor: i.ativo == '0' ? '#d3d3d3' : 'white' }} value={i.id} key={i.id}>
                    {i.nome} - {formatData(i.data)} {i.ativo == '0' ? 'Finalizada' : ''}
                  </option>
                ))}
              </InputSelect>
              <InputField
                label="Nome"
                name="nome"
                width={getLarguraPagina(7)}
              />
              {/* <InputMultiSelect label="Rotas" name="rotas" options={(ficha?.rotas ?? [])} width={getLarguraPagina(7)} /> */}
              <InputMultiSelect label="Cidades" name="cidades" options={(ficha?.cidades ?? [])} width={getLarguraPagina(7)} />
              <InputMultiSelect label="UFS" name="ufs" options={(ficha?.ufs ?? [])} width={getLarguraPagina(7)} />
              <InputMultiSelect label="Status" name="status" options={getStatusFormatados()} width={getLarguraPagina(7)} />
              <InputSelect
                label="Ordem"
                name="order"
                width={getLarguraPagina(7)}
              >
                <option value="nome">
                  Nome
                </option>
                <option value="cpfcnpj">
                  CPF / CNPJ
                </option>
                <option value="cidade">
                  Cidade
                </option>
                {/* <option value="rota">
                  Rota
                </option> */}
                <option value="nao_compra_mais_tempo">
                  Não compra a mais tempo
                </option>
                <option value="nao_compra_menos_tempo">
                  Não compra a menos tempo
                </option>
              </InputSelect>
              {/* <InputSelectArray arrayName="empresas" name="empresas" label="Empresa Origem" width={getLarguraPagina(7)} /> */}
              <InputField name="data_integracao" type="date" label="Data integração" width={getLarguraPagina(7)} />

              <Flex alignItems={"end"}>
                <CamposPersonalizadosFiltros onFilter={submitForm} />
              </Flex>
              <Flex width={getLarguraPagina(7)} alignItems={"end"}>
                <DefaultButton ml="1" type="submit" width={"80%"}>Filtrar</DefaultButton>
              </Flex>

              <Flex width={"full"} wrap={"wrap"}>
                {coresStatusCards.map((i) => (
                  <Box key={i.bgColorCard} px={2} py={1} textAlign={"center"} width={getLarguraPagina(4)}>
                    <Box onClick={() => {
                      setFieldValue('status_item', i.value);
                      submitForm();
                    }} cursor={"pointer"} border={typeof values.status_item === 'number' && values.status_item == i.value ? "3px solid #b3b3b3" : ''} textAlign={"center"} width={"100%"} bg={i.bgColor} color={i.color} p={1} borderRadius={"10px"}>
                      {ficha?.quantidade ? (ficha.quantidade as any)[i.value] : '0'} clientes
                    </Box>

                    <Text fontSize={'12px'}>{i.name}</Text>
                  </Box>
                ))}
              </Flex>
            </Flex>
          </Form>)}
      </Formik>}

      <Heading size={"md"}>
        Total de {ficha?.itens?.total} clientes
      </Heading>

      <Grid mt={4} templateColumns={isMobile ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)'} gap={6}>
        {(ficha?.itens?.data ?? []).map((i, index) => (
          <Card bg={coresStatusCards.find((cor) => cor.value == i.status)?.bgColorCard} key={`${index}`}>
            <CardHeader>
              <Heading size='sm'>
                {i.pessoa?.nome}
              </Heading>
              <Text size=''>
                CPF/CNPJ: {i.pessoa?.cpf_cnpj}
              </Text>
              <Text size=''>
                Status: <b>{i.pessoa?.status}</b> 
              </Text>
            </CardHeader>

            <CardBody>
              <Stack divider={<StackDivider />} spacing='4'>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    Resumo
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    {/* Rota: <b>{!!(i as any)?.rota_codigo ? <>{(i as any)?.rota_codigo} - {(i as any)?.rota_descricao}</> : <>Nenhuma cadastrada</>}</b> <br /> */}
                    Cidade: <b>{i.pessoa?.cidade?.nome} - {i.pessoa?.cidade?.uf} </b> <br />
                    Limite de crédito: <b>{formatValor(i.pessoa?.limite_credito ?? 0)}</b> <br />
                    Data última agenda: <b>{(i as any)?.data_ultima_agenda ? <> {formatData((i as any).data_ultima_agenda)} </> : <>Nenhuma encontrada</>}</b>  <br />
                    Última compra: <b>{(i as any)?.data_ultimo_pedido ? <> {formatData((i as any).data_ultimo_pedido)} </> : <>Nenhum encontrado</>} </b> <br />
                    Integração: <b>{i?.pessoa?.data_integracao ? parseData(i?.pessoa?.data_integracao, 'dd/MM/yyyy') : 'Nenhuma'} </b> <br />
                    <CamposPersonalizadosView values={i.pessoa?.camposPersonalizados ?? []} />
                  </Text>
                </Box>
                <Box>
                  <Text fontSize='sm'>
                    {coresStatusCards.map((j) => (
                      <Button _hover={{ backgroundColor: j.bgColor }} onClick={() => onChangeStatus(i.id as number, j.value)} borderRadius={"2px"} m={"2px"} size={"xs"} key={j.bgColor} bg={j.bgColor} color={j.color}>
                        {j.name}
                      </Button>
                    ))}
                  </Text>
                </Box>
                <Box>
                  <AgendamentosButton pessoa_id={i.pessoa_id} />
                  <ContatosButton pessoa_id={i.pessoa_id} />
                  <UltimosPedidosButton pessoa_id={i.pessoa_id} />
                  <DadosPessoaButton pessoa_id={i.pessoa_id} onDelete={onSearch} />
                </Box>
              </Stack>
            </CardBody>
          </Card>
        ))}

      </Grid>

      {ficha?.itens && <Pagination info={ficha?.itens} paginate={setPage} />}

    </Box>
  )
}