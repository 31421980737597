import { Button, useDisclosure } from "@chakra-ui/react"
import React from "react"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/mainReducer";
import { DialogModal } from "../../../sistema/components/DialogModal";
import { ListHeaderSecondary } from "../../../sistema/components/ListHeaderSecondary";
import { formatData } from "../../../utils/data";
import { formatValor } from "../../../utils/formatValor";
import { DefaultButton } from "../../../sistema/components/DefaultButton";
import { cashRegisterActions } from "../../reducer";

export const LastCashRegisters = () => {
  const cashRegisters = useSelector((state: RootState) => state.cashRegister?.cashRegisters?.data ?? []);
  const pagination = useSelector((state: RootState) => state.cashRegister?.cashRegisters);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const dispatch = useDispatch();

  const loadMore = () => {
    dispatch(cashRegisterActions.cashRegistersRequest({ page: (pagination?.current_page ?? 0) + 1, termo: '' }));
  }

  return (
    <React.Fragment>
      <Button onClick={onOpen} colorScheme="blackAlpha" className="ml-3">
        Ver caixas anteriores
      </Button>

      <DialogModal
        show={isOpen}
        close={onClose}
        maxWidth="2xl"
        title={
          <ListHeaderSecondary>
            Caixas anteriores
            <Button
              type="button"
              onClick={onClose}
              size={"sm"}
              ml={1}
              colorScheme="blackAlpha"
            >
              Voltar
            </Button>
          </ListHeaderSecondary>
        }
        content={
          <div className="w-full  max-h-[80vh] overflow-y-auto p-1">

            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              {cashRegisters.map((cashRegister) => (
                <div key={cashRegister?.id} className="p-2 rounded-md shadow-md bg-gray-50 text-center">
                  <div className="font-bold text-gray-700 text-lg">{formatData(cashRegister.date, 'dd/MM/yyyy')}</div>
                  Saldo Inicial <br /> <small>R$ {formatValor(cashRegister.initial_value)} <br /></small>
                  Saldo Final <br /> <small>R$ {formatValor(cashRegister.final_value)}</small>

                  <DefaultButton onClick={() => {
                    dispatch(cashRegisterActions.cashRegisterRequest(cashRegister?.id as number));
                    onClose();
                  }} className="w-full" size={'xs'}>
                    Selecionar
                  </DefaultButton>
                </div>
              ))}
            </div>

            {(pagination?.total ?? 0) > cashRegisters.length && <DefaultButton onClick={loadMore} className="w-full mt-4" size={'xs'}>
              Ver Mais
            </DefaultButton>}
          </div>
        }
        footer={
          <div className="w-full">
            <Button
              type="button"
              onClick={onClose}
              size={"sm"}
              colorScheme="blackAlpha"
            >
              Voltar
            </Button>
          </div>
        }
      />
    </React.Fragment>
  )
}