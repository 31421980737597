import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useIsAuth } from "../../hooks/useIsAuth"
import { useParams } from "react-router-dom";
import { useEffect } from 'react'
import { cadastroActions } from "../reducer";
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import InputField from "../../sistema/components/InputField";
import { Form, Formik, useField } from "formik";
import { Box, Flex } from "@chakra-ui/react";
import validateForm from "../../utils/validateForm";
import InputSelect from "../../sistema/components/InputSelect";
import { ButtonHistorico } from "../../sistema/components/ButtonHistorico";
import { getArrayUfs } from "../../utils/ufs";
import InputCheck from "../../sistema/components/InputCheck";
import { Filhos } from "../../sistema/components/Filhos";
import { novaRotaCidade } from "../data/rota_cidade";
import { BobjetoPadrao } from "../../sistema/components/BobjetoPadrao";
import { CidadeBobjeto } from "../components/CidadeBobjeto";

export const RotaEdit = () => {
  useIsAuth();

  const { id } = useParams();

  const rota = useSelector((state: RootState) => state.cadastro.rota)
  const isLoading = useSelector((state: RootState) => !!state.cadastro.isLoading)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!id) return;

    dispatch(cadastroActions.rotaRequest(id))
  }, [dispatch, id])

  const CidadeInput = () => {
    const [, , { setValue }] = useField('cidade');

    return (
      <CidadeBobjeto onSelectItem={setValue} />
    )
  }

  return (
    <Layout>
      <ListHeader label={'Rota'}>
        <DefaultButton ml={4} to={`/rotas`}> Voltar </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      {rota && <Formik
        enableReinitialize
        initialValues={rota}
        onSubmit={(val, { setErrors }) => {
          const validation = validateForm({ codigo: 'required', descricao: 'required' }, val)
          if (Object.keys(validation).length > 0) {
            setErrors(validation)
            return;
          }

          dispatch(cadastroActions.saveRotaRequest({
            ...val,
            changed: true,
          }));
        }}
      >
        {({ values }) => (
          <Form>
            <Flex width="full" wrap="wrap">
              <InputField label="Código" name="codigo" width={`50%`} />
              <InputField label="Descrição" name="descricao" width={`50%`} />
              <InputCheck name="ativo" label="Rota ativa?" />

              <Filhos
                name="cidades"
                tituloForm="Cidades da Rota"
                editTituloForm="Cidade na Rota"
                novoItemObject={novaRotaCidade()}
                validation={{ cidade_id: 'required' }}
                headers={[
                  {
                    label: "Cidade",
                    wrapped: false,
                    render: (item) => item.cidade?.nome,
                  },
                  {
                    label: "UF",
                    wrapped: false,
                    render: (item) => item.cidade?.uf,
                  },
                ]}
                form={(values) =>
                  <Flex pb={"150px"} width={"full"} wrap={"wrap"}>
                    <CidadeInput />
                  </Flex>
                }
              />


              <Flex width={"full"} />
              <Box
                bg={"white"}
                mt={2}
                width={"full"}
                position="sticky"
                bottom="10px"
                zIndex="10"
                p={2}
              >
                <DefaultButton type="submit">Salvar rota</DefaultButton>
                {values.id && <ButtonHistorico par="rota" idpar={values.id} ml={2} />}
              </Box>
            </Flex>
          </Form>
        )}
      </Formik>}
    </Layout>
  )
}