import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/mainReducer";
import { formatValor } from "../../../utils/formatValor"
import { getDecimalPlacesOfSystem } from "../../../sistema/utils/getDecimalPlacesOfSystem";
import { Button, useDisclosure } from "@chakra-ui/react";
import { FiMinus, FiPlus, FiTrash } from "react-icons/fi";
import ResponsiveTable, { TableHeaders } from "../../../sistema/components/ResponsiveTable";
import { CashRegisterTransaction, CashRegisterTransactionType } from "../../types/cash_register_transaction";
import { ConfirmButton } from "../../../sistema/components/ConfirmButton";
import { useState } from "react";
import { AddTransaction } from "./AddTransaction";
import { newCashRegisterTransaction } from "../../data/cash_register_transaction";
import { cashRegisterActions } from "../../reducer";
import { apiCall } from "../../../utils/apiCall";
import { mensagemErro, mensagemSucesso } from "../../../utils/toasts";
import { formatError } from "../../../utils/formatError";
import { formatData } from "../../../utils/data";

export const CashRegisterList = () => {
  const selectedCashRegister = useSelector((state: RootState) => state.cashRegister?.cashRegister);

  const [currentForm, setCurrentForm] = useState<CashRegisterTransaction>();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const dispatch = useDispatch();

  const onDeleteItem = async (id: number) => {
    try {
      cashRegisterActions.setLoading(true);
      await apiCall({ url: `cash-register-transaction/${id}`, method: 'DELETE' })
      cashRegisterActions.setLoading(false);
      mensagemSucesso("Registro adicionado com sucesso!");
      dispatch(cashRegisterActions.cashRegistersRequest({ page: 1, termo: '' }));
    } catch (error: any) {
      cashRegisterActions.setLoading(false);
      mensagemErro(formatError(error));
    }
  }

  const headers: TableHeaders<CashRegisterTransaction>[] = [
    {
      label: "Descrição",
      render: (item) => item.description,
    },
    {
      label: "Data",
      wrapped: true,
      render: (item) => formatData(item?.date),
    },
    {
      label: "Entrada",
      wrapped: true,
      render: (item) => item.type == CashRegisterTransactionType.Input ? `R$ ${formatValor(item.value, getDecimalPlacesOfSystem())}` : '',
    },
    {
      label: "Saída",
      wrapped: true,
      render: (item) => item.type == CashRegisterTransactionType.Output ? `R$ ${formatValor(item.value, getDecimalPlacesOfSystem())}` : '',
    },
    {
      label: "Ações",
      wrapped: true,
      render: (item, index) => <>{selectedCashRegister && !selectedCashRegister?.finished_at && selectedCashRegister?.id == lastCashRegister?.id && <ConfirmButton size={'xs'} confirm={() => onDeleteItem(item.id as number)}><FiTrash /></ConfirmButton>}</>,
      notClicable: true,
    },
  ];

  const cashRegisters = useSelector((state: RootState) => state.cashRegister?.cashRegisters?.data ?? []);

  const lastCashRegister = cashRegisters[0];

  return (
    <div className="w-full">
      {currentForm && <AddTransaction
        isOpen={isOpen}
        onClose={() => {
          onClose();
          dispatch(cashRegisterActions.cashRegistersRequest({ page: 1, termo: '' }))
        }}
        form={currentForm}
      />}

      {selectedCashRegister && !selectedCashRegister?.finished_at && selectedCashRegister?.id == lastCashRegister?.id && <div className="w-full flex flex-wrap justify-center py-1 text-xl gap-2 mt-2">
        <Button onClick={() => {
          setCurrentForm({
            ...newCashRegisterTransaction(),
            type: CashRegisterTransactionType.Input,
          })
          onOpen();
        }} colorScheme="green" size={'sm'} className="uppercase flex gap-1 items-center">
          <FiPlus /> Adicionar entrada
        </Button>
        <Button onClick={() => {
          setCurrentForm({
            ...newCashRegisterTransaction(),
            type: CashRegisterTransactionType.Output,
          })
          onOpen();
        }} colorScheme="red" size={'sm'} className="uppercase flex gap-1 items-center">
          <FiMinus /> Adicionar saída
        </Button>
      </div>}

      <div className="w-full mt-2 px-2">
        <ResponsiveTable isSmall headers={headers} data={selectedCashRegister?.transactions ?? []} />
      </div>
    </div>
  )
}