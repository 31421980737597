import { Flex, Img, Text } from "@chakra-ui/react";
import { useIsAuth } from "../../hooks/useIsAuth";
import Layout from "../components/Layout";
import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import logoPadrao from "../../../images/logoPadrao.png";
import { APIURL } from "../../utils/apiCall";

const Home = () => {
  useIsAuth();
  const parametros = useSelector((state: RootState) => state.sistema?.parametros);
  const logoEmpresa = useSelector((state: RootState) => state.sistema.parametros?.logo)
  const isMobile = useSelector((state: RootState) => state.sistema.isMobile)

  return (
    <Layout>
      <Flex height={"50vh"} width={"full"} wrap={"wrap"} justifyContent={"center"} alignItems={"center"}>
        <Flex textAlign={"center"} direction={"column"}>
          <Img mx={"auto"} height={{ base: '180px', md: 'auto' }} width={isMobile ? 'auto' : "180px"} src={logoEmpresa ? APIURL.replace('/api', '/storage/images/') + logoEmpresa : logoPadrao} />

          <Text mt={6} fontSize={"20px"}>Bem vindo(a) ao sistema {parametros?.nome_empresa ? parametros?.nome_empresa : 'AG Solution'}</Text>
        </Flex>
      </Flex>
    </Layout>
  )
}

export default Home;
