import { useDisclosure } from "@chakra-ui/react";
import { Pedido } from "../../../entradaesaida/types/pedido";
import { DialogModal } from "../../../sistema/components/DialogModal";
import { useEffect } from "react";
import { ListHeaderSecondary } from "../../../sistema/components/ListHeaderSecondary";
import { DefaultButton } from "../../../sistema/components/DefaultButton";
import { FiCheckCircle } from "react-icons/fi";

type OrderSuccessProps = {
  order: Pedido | null;
};

export const OrderSuccess = ({ order }: OrderSuccessProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (order) {
      onOpen();
    }
  }, [order]);

  return (
    <DialogModal
      show={isOpen}
      close={onClose}
      maxWidth="md"
      title={
        <ListHeaderSecondary>Venda registrada com sucesso!</ListHeaderSecondary>
      }
      content={
        <div className="w-full h-full">
          <FiCheckCircle size={"100px"} className="m-auto text-green-700" />

          <br />
          <div className="font-semibold text-gray-800 text-center w-full">
            <span>Pedido </span>
            <span className="text-gray-700 font-normal">{order?.numero}</span>
          </div>
        </div>
      }
      footer={
        <div className="w-full">
          <DefaultButton className="w-full" size={"sm"} onClick={onClose}>
            Fechar
          </DefaultButton>
        </div>
      }
    />
  );
};
