import { getCurrentData } from "../../utils/data";
import { CashRegisterTransaction, CashRegisterTransactionType } from "../types/cash_register_transaction";

export const newCashRegisterTransaction = (): CashRegisterTransaction => ({
  cash_register_id: "",
  financeiro_parcela_id: "",
  value: 0,
  type: CashRegisterTransactionType.Output,
  description: "",
  date: getCurrentData(),
})