import React, { useState } from "react";
import { ListHeader } from "../../../sistema/components/ListHeader"
import { formatData, getCurrentData } from "../../../utils/data";
import { DefaultButton } from "../../../sistema/components/DefaultButton";
import { RootState } from "../../../app/mainReducer";
import { useDispatch, useSelector } from "react-redux";
import { Button, useDisclosure } from "@chakra-ui/react";
import { OpenCashRegister } from "./OpenCashRegister";
import { cashRegisterActions } from "../../reducer";
import { ConfirmationModal } from "../../../sistema/components/ConfirmationModal";
import { SecondaryButton } from "../../../sistema/components/SecondaryButton";
import { DangerButton } from "../../../sistema/components/DangerButton";
import { apiCall } from "../../../utils/apiCall";
import { CashRegister } from "../../types/cash_register";
import { mensagemErro, mensagemSucesso } from "../../../utils/toasts";
import { formatError } from "../../../utils/formatError";
import Loader from "../../../sistema/components/Loader";
import { LastCashRegisters } from "./LastCashRegisters";

export const Header = () => {
  const cashRegisters = useSelector((state: RootState) => state.cashRegister?.cashRegisters?.data ?? []);
  const selectedCashRegister = useSelector((state: RootState) => state.cashRegister?.cashRegister);

  const lastCashRegister = cashRegisters[0];

  const { isOpen, onClose, onOpen } = useDisclosure();

  const dispatch = useDispatch();
  const onCloseOpenCashRegister = () => {
    onClose();
    dispatch(cashRegisterActions.cashRegistersRequest({ page: 1, termo: '' }));
  }

  const {
    isOpen: isOpenConfirmationFinish,
    onOpen: onOpenConfirmationFinish,
    onClose: onCloseConfirmationFinish,
  } = useDisclosure();

  const [isLoading, setIsLoading] = useState(false);

  const onFinishCashRegister = async () => {
    if (!selectedCashRegister) {
      return;
    }
    try {
      setIsLoading(true);
      await apiCall({
        url: 'cash-register', method: 'POST', data: {
          ...selectedCashRegister,
          finished_at: getCurrentData(),
          changed: true,
        } as CashRegister
      })
      setIsLoading(false);
      onCloseConfirmationFinish();
      dispatch(cashRegisterActions.cashRegistersRequest({ page: 1, termo: '' }));
      mensagemSucesso("Caixa finalizada com sucesso!");

    } catch (error: any) {
      setIsLoading(false);
      mensagemErro(formatError(error));
    }
  }

  return (
    <ListHeader label={`Fluxo de caixa ${selectedCashRegister ? `do dia ${formatData(selectedCashRegister.date, 'dd/MM/yyyy')}` : ''}`}>
      {selectedCashRegister && selectedCashRegister.id == lastCashRegister?.id && <React.Fragment>
        {selectedCashRegister.finished_at && <DefaultButton onClick={onOpen} className="ml-3">
          Abrir caixa
        </DefaultButton>}
        {!selectedCashRegister.finished_at && <DefaultButton onClick={onOpenConfirmationFinish} className="ml-3">
          Fechar caixa
        </DefaultButton>}
      </React.Fragment>}
      {!selectedCashRegister && <React.Fragment>
        <DefaultButton onClick={onOpen} className="ml-3"
        >
          Abrir caixa
        </DefaultButton>
      </React.Fragment>}
      {cashRegisters.length > 1 && <React.Fragment>
        <LastCashRegisters />
      </React.Fragment>}
      <OpenCashRegister isOpen={isOpen} onClose={onCloseOpenCashRegister} />
      <ConfirmationModal
        show={isOpenConfirmationFinish}
        close={onCloseConfirmationFinish}
        title={"Fechar caixa?"}
        content={"Você tem certeza que deseja fechar o caixa?"}
        footer={
          <React.Fragment>
            <Loader isLoading={isLoading} />
            <SecondaryButton
              externalClass="w-auto"
              onClick={onCloseConfirmationFinish}
            >
              Cancelar
            </SecondaryButton>

            <DangerButton externalClass="ms-3" onClick={onFinishCashRegister}>
              Fechar caixa
            </DangerButton>
          </React.Fragment>
        }
      />
    </ListHeader>
  )
}