import { useState } from "react";
import { PessoaBobjeto } from "../../../cadastros/components/PessoaBobjeto";
import { Pessoa } from "../../../cadastros/types/pessoa";
import { ListHeaderSecondary } from "../../../sistema/components/ListHeaderSecondary";
import { formatValor } from "../../../utils/formatValor";
import {
  getTotalCartValue,
  getTotalDiscountCartValue,
  getTotalFinalCartValue,
} from "../../utils/cartUtils";
import { CustomerView } from "./CustomerView";
import { DadosPessoaButton } from "../../../crm/components/DadosPessoaButton";
import { CreateCustomer } from "./CreateCustomer";
import { useFormikContext } from "formik";
import { Pedido } from "../../../entradaesaida/types/pedido";
import { getDecimalPlacesOfSystem } from "../../../sistema/utils/getDecimalPlacesOfSystem";
import { FinalizeSale } from "./FinalizeSale";

export const Actions = () => {
  const [customer, setCustomer] = useState<Pessoa | null>(null);

  const formik = useFormikContext();

  const items = (formik.values as Pedido)?.itens ?? [];

  return (
    <div className="w-full px-5">
      <div className="w-full bg-white rounded py-2 px-3 mb-3">
        <ListHeaderSecondary label="Cliente">
          &nbsp;
          {customer && (
            <DadosPessoaButton
              pessoa_id={customer.id as number}
              onSavedCustomer={setCustomer}
            />
          )}
          {!customer && (
            <CreateCustomer
              onSavedCustomer={(customer) =>
                formik.setFieldValue("pessoa_id", customer.id)
              }
            />
          )}
        </ListHeaderSecondary>

        <PessoaBobjeto
          label=""
          placeholder="Cliente"
          name="pessoa_id"
          width="100%"
          includes="contatos"
          onSelectItemOnLoad={setCustomer}
        />

        {!customer && (
          <div className="w-full font-semibold text-gray-600 text-center uppercase text-sm">
            Cliente não identificado
          </div>
        )}
        {customer && (
          <div className="w-full">
            <CustomerView customer={customer} />
          </div>
        )}
      </div>

      <div className="w-full bg-white rounded text-center p-3">
        <span className="text-gray-800">TOTAL BRUTO</span> <br />
        <span className="text-2xl text-green-800 font-bold">
          R$ {formatValor(getTotalCartValue(items), getDecimalPlacesOfSystem())}
        </span>
        <div className="w-full border-b my-4"></div>
        <span className="text-gray-800">DESCONTOS</span> <br />
        <span className="text-2xl text-red-700 font-bold">
          - R${" "}
          {formatValor(
            getTotalDiscountCartValue(items),
            getDecimalPlacesOfSystem()
          )}
        </span>
        <div className="w-full border-b my-4"></div>
        <span className="text-gray-800">TOTAL LIQUIDO</span> <br />
        <span className="text-2xl text-green-800 font-bold">
          R${" "}
          {formatValor(
            getTotalFinalCartValue(items),
            getDecimalPlacesOfSystem()
          )}
        </span>
      </div>

      <div className="mt-3 w-full">
        <FinalizeSale />
      </div>
    </div>
  );
};
