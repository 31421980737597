import { Box, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { Pessoa } from "../../../cadastros/types/pessoa";
import { formatCpfCnpj } from "../../../utils/formatCpfCnpj";
import { CamposPersonalizadosView } from "../../../sistema/components/CamposPersonalizadosView";
import { formatCelular } from "../../../utils/formatCelular";

type CustomerViewProps = {
  customer: Pessoa;
};

export const CustomerView = ({ customer }: CustomerViewProps) => {
  return (
    <div className="max-h-[130px] overflow-y-auto">
      <Box className="text-gray-800">
        <Text fontSize="sm">
          CPF/CNPJ: <b>{formatCpfCnpj(customer.cpf_cnpj)}</b> <br />
          Endereço:{" "}
          <b>
            {customer.endereco}, n° {customer.numero}
          </b>{" "}
          <br />
          E-mail:<b> {customer.email ? customer.email : "Nenhum"}</b>
          <br />
          {(customer?.contatos ?? []).map((contact) => (
            <Box key={contact.id}>
              <div className="py-2">
                <hr />
              </div>
              <Heading size="xs" textTransform="uppercase">
                Contato {contact.tipo}
              </Heading>
              <Text pt="2" fontSize="sm">
                Nome: <b>{contact.nome}</b> |{" "}
                <b>
                  {" "}
                  <span>
                    <u>
                      {" "}
                      <a
                        href={`https://wa.me/${contact.celular}`}
                        target="_blank"
                      >
                        {" "}
                        {formatCelular(contact.celular)}{" "}
                      </a>
                    </u>
                  </span>{" "}
                </b>{" "}
                <br />
                {contact.observacoes}
              </Text>
            </Box>
          ))}
          <CamposPersonalizadosView
            values={customer.camposPersonalizados ?? []}
          />
        </Text>
      </Box>
    </div>
  );
};
