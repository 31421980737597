import { Formik, Form } from "formik";
import { DefaultButton } from "../../../sistema/components/DefaultButton";
import { DialogModal } from "../../../sistema/components/DialogModal";
import { ListHeaderSecondary } from "../../../sistema/components/ListHeaderSecondary";
import { newCashRegister } from "../../data/cash_register";
import InputField from "../../../sistema/components/InputField";
import { Button } from "@chakra-ui/react";
import InputNumber from "../../../sistema/components/InputNumber";
import { useEffect, useState } from "react";
import { CashRegister } from "../../types/cash_register";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/mainReducer";
import { apiCall } from "../../../utils/apiCall";
import { mensagemErro, mensagemSucesso } from "../../../utils/toasts";
import { formatError } from "../../../utils/formatError";

type OpenCashRegisterProps = {
  onClose: () => void;
  isOpen: boolean;
}

export const OpenCashRegister = ({ isOpen, onClose }: OpenCashRegisterProps) => {
  const [currentForm, setCurrentForm] = useState<CashRegister | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const cashRegisters = useSelector((state: RootState) => state.cashRegister?.cashRegisters);

  useEffect(() => {
    if (isOpen) {
      setCurrentForm({
        ...newCashRegister(),
        initial_value: (cashRegisters?.data ?? [])[0]?.final_value ?? 0,
      });
    } else {
      setCurrentForm(null);
    }
  }, [isOpen]);

  return (
    <DialogModal
      show={isOpen}
      close={onClose}
      maxWidth="lg"
      title={
        <ListHeaderSecondary>
          Abrir caixa
          <Button
            type="button"
            onClick={onClose}
            size={"sm"}
            ml={1}
            colorScheme="blackAlpha"
          >
            Voltar
          </Button>
        </ListHeaderSecondary>
      }
      content={
        <div className="w-full">
          {currentForm && <Formik
            enableReinitialize
            initialValues={currentForm}
            onSubmit={async (values, { setErrors }) => {
              try {
                setIsLoading(true);
                await apiCall({ url: 'cash-register', method: 'POST', data: values })
                setIsLoading(false);
                mensagemSucesso("Caixa aberto com sucesso!");
                onClose();
              } catch (error: any) {
                setIsLoading(false);
                mensagemErro(formatError(error));
              }
            }}
          >
            {({ values, setFieldValue }) => (
              <Form style={{ width: "100%" }}>
                <InputField
                  label="Data do caixa"
                  name="date"
                  width={"full"}
                  type="date"
                  disabled
                />
                <InputNumber
                  label="Valor inicial"
                  name="initial_value"
                  width={"full"}
                  disabled={(cashRegisters?.data ?? []).length > 0}
                />
                <InputField
                  textarea
                  label="Observações"
                  name="observations"
                  width={'fulll'}
                  rows={3}
                />
                <div className="p-1">
                  <DefaultButton isLoading={isLoading} type="submit" mt={2} width={"full"}>
                    ABRIR CAIXA
                  </DefaultButton>
                </div>
              </Form>
            )}
          </Formik>}
        </div>
      }
      footer={
        <div className="w-full">
          <Button
            type="button"
            onClick={onClose}
            size={"sm"}
            colorScheme="blackAlpha"
          >
            Voltar
          </Button>
        </div>
      }
    />
  )
}