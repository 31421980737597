import { FiRefreshCcw } from "react-icons/fi";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { useDispatch } from "react-redux";
import { entradaesaidaActions } from "../recucer";

type CalculateProductStockProps = {
  product_id: string | number;
};

export const CalculateProductStock = ({
  product_id,
}: CalculateProductStockProps) => {
  const dispatch = useDispatch();

  const onRecalculateStock = () => {
    dispatch(entradaesaidaActions.stockRecalculate(product_id));
  };

  return (
    <DefaultButton
      className="flex gap-1 items-center"
      onClick={onRecalculateStock}
    >
      <FiRefreshCcw /> Recalcular estoque
    </DefaultButton>
  );
};
