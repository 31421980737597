import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { mensagemErro, mensagemSucesso } from "../utils/toasts";
import { Pedido } from "../entradaesaida/types/pedido";
import { novoPedido } from "../entradaesaida/data/pedido";
import { Seller } from "../cadastros/types/seller";
import { StockLocation } from "../entradaesaida/types/stock_location";

type TInitialState = {
  error?: string;
  success?: string;
  isLoading?: boolean;
  sellers?: Seller[];
  locationsStock?: StockLocation[];
  order: Pedido;
};

const initialState: TInitialState = {
  order: novoPedido(),
};

const reducer = createSlice({
  name: "pdv",
  initialState,
  reducers: {
    error(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
      state.success = "";
      mensagemErro(payload);
    },
    success(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = "";
      state.success = payload;
      mensagemSucesso(payload);
    },
    updateOrder(state, { payload }: PayloadAction<Pedido>) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.order = payload;
    },
    getSellersRequest(state) {
    },
    getSellersRequestSuccess(state, { payload }: PayloadAction<Seller[]>) {
      state.sellers = payload;
    },
    getLocationsStockRequest(state) {
    },
    getLocationsStockRequestSuccess(state, { payload }: PayloadAction<StockLocation[]>) {
      state.locationsStock = payload;
    },
    saveOrderRequest(state, _: PayloadAction<Pedido>) {
      state.isLoading = true;
      state.error = '';
      state.success = '';
    },
    saveOrderRequestSuccess(state, { payload }: PayloadAction<Pedido>) {
      state.isLoading = false;
      state.error = '';
      state.success = '';
      state.order = payload;
    },
  },
});

export const pdvActions = reducer.actions;

export default reducer;
