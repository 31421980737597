import { Flex } from "@chakra-ui/react";
import { DefaultButton } from "./DefaultButton";
import { DialogModal } from "./DialogModal";
import InputField from "./InputField";
import InputCheck from "./InputCheck";
import { Filhos } from "./Filhos";
import InputSelect from "./InputSelect";
import { Form, Formik } from "formik";
import { ListHeader } from "./ListHeader";
import { CampoPersonalizado } from "../types/campo_personalizado";
import { apiCall } from "../../utils/apiCall";
import { mensagemErro } from "../../utils/toasts";
import { formatError } from "../../utils/formatError";
import validateForm from "../../utils/validateForm";
import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { getFieldsTypes } from "../arrays/camposPersonalizados";
import { useState } from "react";
import Loader from "./Loader";
import { PessoaCampoPersonalizado } from "../types/pessoa_campo_personalizado";

type CampoPersonalizadoFormProps = {
  isOpen: boolean;
  onClose: () => void;
  par: string;
  idp: string;
  currentItem: CampoPersonalizado | null;
  savedForm: ({
    current,
    fields,
    allFields,
  }: {
    current: CampoPersonalizado;
    fields: PessoaCampoPersonalizado[];
    allFields: PessoaCampoPersonalizado[];
  }) => void;
};

export const CampoPersonalizadoForm = ({
  isOpen,
  onClose,
  par,
  idp,
  savedForm,
  currentItem,
}: CampoPersonalizadoFormProps) => {
  const [isLoading, setLoading] = useState(false);

  const onSaveModal = async (values: CampoPersonalizado) => {
    try {
      setLoading(true);
      const { data } = await apiCall({
        url: "campos-personalizados",
        method: "POST",
        data: {
          ...values,
          array: JSON.stringify(values.array),
        },
      });

      const { data: camposAtuais } = await apiCall({
        url: `campos-personalizados/${par}/${!idp ? "novo" : idp}`,
        method: "GET",
      });

      const { data: todosCamposAtuais } = await apiCall({
        url: `campos-personalizados-todos/${par}`,
        method: "GET",
      });

      savedForm({
        current: data,
        fields: camposAtuais,
        allFields: todosCamposAtuais,
      });
      setLoading(false);
      onClose();
    } catch (err: any) {
      setLoading(false);
      mensagemErro(formatError(err));
    }
  };

  const isMobile = useSelector((state: RootState) => !!state.sistema?.isMobile);

  return (
    <DialogModal
      maxWidth="xl"
      show={isOpen}
      close={onClose}
      title={
        <ListHeader>
          {currentItem?.id ? "Editar campo" : "Criar campo"}
        </ListHeader>
      }
      content={
        <div className="w-full flex flex-wrap">
          <Loader isLoading={isLoading} />

          {currentItem && (
            <Formik
              enableReinitialize
              initialValues={currentItem}
              onSubmit={(val, { setErrors }) => {
                const validation = validateForm({ nome: "required" }, val);
                if (Object.keys(validation).length > 0) {
                  setErrors(validation);
                  return;
                }

                if (val.tipo.trim() == "array" && val.array.length == 0) {
                  mensagemErro("Coloque ao menos 1 item na lista de seleção.");
                  return;
                }

                if (val.tipo.trim() == "simnao") {
                  onSaveModal({
                    ...val,
                    tipo: "array",
                    array: [
                      {
                        label: "Sim",
                        value: "Sim",
                        ativo: 1,
                      },
                      {
                        label: "Não",
                        value: "Não",
                        ativo: 1,
                      },
                    ],
                    changed: true,
                  });
                  return;
                }

                onSaveModal({
                  ...val,
                  changed: true,
                });
              }}
            >
              {({ values, submitForm }) => (
                <Form>
                  <Flex width="full" wrap="wrap">
                    <InputField
                      label="Nome do campo"
                      name="nome"
                      width={isMobile ? "100%" : "50%"}
                    />
                    <InputSelect
                      noPlaceholer={true}
                      label="Tipo do campo"
                      name="tipo"
                      width={isMobile ? "100%" : "50%"}
                      disabled={!!currentItem?.id}
                    >
                      {getFieldsTypes().map((type) => (
                        <option key={type.value} value={type.value}>
                          {type.name}
                        </option>
                      ))}
                    </InputSelect>
                    <InputCheck
                      name="ativo"
                      label="Campo ativo?"
                      className="mb-2 ml-1"
                    />
                    {values.tipo == "array" && (
                      <Filhos
                        name="array"
                        tituloForm="Itens"
                        editTituloForm="Item"
                        minH="0"
                        novoItemObject={{ label: "", value: "", ativo: 1 }}
                        validation={{ label: "required" }}
                        headers={[
                          {
                            label: "Descrição",
                            wrapped: false,
                            render: (item) => item?.label,
                          },
                          {
                            label: "Ativo?",
                            wrapped: false,
                            render: (item) =>
                              item?.ativo == 1 ? "Sim" : "Não",
                          },
                        ]}
                        hasMenuActions={!values?.id}
                        form={(values) => (
                          <Flex pb={"10px"} width={"full"} wrap={"wrap"}>
                            <InputField
                              name="label"
                              label="Descrição"
                              width={"100%"}
                            />
                            <InputCheck
                              name="ativo"
                              label="Ativo?"
                              width={"100%"}
                            />
                          </Flex>
                        )}
                        formatValuesBeforeSave={(values) => ({
                          ...values,
                          value: values.label,
                        })}
                      />
                    )}

                    <Flex pb={4} width={"full"} />
                    <hr style={{ width: "100%" }} />

                    <DefaultButton type="submit" mt={4}>
                      Salvar Campo
                    </DefaultButton>
                  </Flex>
                </Form>
              )}
            </Formik>
          )}
        </div>
      }
      footer={
        <div className="w-full">
          <DefaultButton onClick={onClose}>Voltar</DefaultButton>
        </div>
      }
    />
  );
};
