import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { Seller } from "../cadastros/types/seller";
import { apiCall } from "../utils/apiCall";
import { pdvActions } from "./reducer";
import { formatError } from "../utils/formatError";
import { Pedido } from "../entradaesaida/types/pedido";
import { PayloadAction } from "@reduxjs/toolkit";
import { StockLocation } from "../entradaesaida/types/stock_location";

function* getSellersRequestWorker() {
  try {
    const res: AxiosResponse<Seller[]> = yield call(apiCall, {
      url: `sellers-actives`,
      method: "get",
    });
    yield put(pdvActions.getSellersRequestSuccess(res.data));
  } catch (error: any) {
    yield put(pdvActions.error(formatError(error)));
  }
}

function* getLocationsStockRequestWorker() {
  try {
    const res: AxiosResponse<StockLocation[]> = yield call(apiCall, {
      url: `locations-stock-actives`,
      method: "get",
    });
    yield put(pdvActions.getLocationsStockRequestSuccess(res.data));
  } catch (error: any) {
    yield put(pdvActions.error(formatError(error)));
  }
}

function* saveOrderRequestWorker({ payload }: PayloadAction<Pedido>) {
  try {
    const res: AxiosResponse<Pedido> = yield call(apiCall, {
      url: `pedidos`,
      method: "POST",
      data: payload,
    });
    yield put(pdvActions.saveOrderRequestSuccess(res.data));
  } catch (error: any) {
    yield put(pdvActions.error(formatError(error)));
  }
}

export function* pdvSaga() {
  yield all([
    takeLatest("pdv/getSellersRequest", getSellersRequestWorker),
    takeLatest("pdv/getLocationsStockRequest", getLocationsStockRequestWorker),
    takeLatest("pdv/saveOrderRequest", saveOrderRequestWorker),
  ]);
}
