import { Box, Flex } from "@chakra-ui/react"
import { FiEdit, FiTrash } from "react-icons/fi"

export const ButtonCardActions = ({ onEdit, onDelete }: { onEdit: () => void; onDelete: () => void }) => {
  return (
    <Flex 
      position="absolute"
      top="0"
      right="0"
        wrap={"wrap"}
      >
      <Box
        p="2"
        _hover={{ backgroundColor: '#d3d3d3' }}
        cursor={'pointer'}
        borderRadius={'full'}
        onClick={onDelete}
        color="red.600"
      >
        <FiTrash />
      </Box>
      <Box
        p="2"
        _hover={{ backgroundColor: '#d3d3d3' }}
        cursor={'pointer'}
        borderRadius={'full'}
        onClick={onEdit}
      >
        <FiEdit />
      </Box>
    </Flex>
  )
}