import { getCurrentData } from "../../utils/data";
import { ContatoRealizado } from "../types/contato_realizado";

export const novoContatoRealizado = (): ContatoRealizado => {
  return {
    data: getCurrentData(),
    pessoa_id: "",
    forma_contato: "Celular",
    motivo_nao_compra: "Não Informado",
    observacoes: "",
    criador: "",
  };
};
