import { Badge, Box, Button, ButtonGroup, Divider, Flex, Td, useDisclosure } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../sistema/components/InputField";
import { RootState } from "../../app/mainReducer";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../sistema/components/Layout";
import { useIsAuth } from "../../hooks/useIsAuth";
import { ListHeader } from "../../sistema/components/ListHeader";
import { Pagination } from "../../sistema/components/Pagination";
import { formatValor } from "../../utils/formatValor";
import Loader from "../../sistema/components/Loader";
import {
  formatData,
  getCurrentData,
  getDataMaisDias,
  getDataMenosDias,
  getLastDayOfCurrentMonth,
  parseData,
} from "../../utils/data";
import { CamposPersonalizadosFiltros } from "../../sistema/components/CamposPersonalizadosFiltros";
import { mensagemErro } from "../../utils/toasts";
import { PessoaBobjeto } from "../../cadastros/components/PessoaBobjeto";
import { financeiroActions } from "../reducer";
import InputSelect from "../../sistema/components/InputSelect";
import ResponsiveTable, {
  TableHeaders,
} from "../../sistema/components/ResponsiveTable";
import { FinanceiroParcela } from "../types/financeiro_parcela";
import { formatCpfCnpj } from "../../utils/formatCpfCnpj";
import { FiDollarSign, FiEye } from "react-icons/fi";
import { CamposPersonalizadosInfoButton } from "../../sistema/components/CamposPersonalizadosInfoButton";
import InputNumber from "../../sistema/components/InputNumber";
import { formatError } from "../../utils/formatError";
import { apiCall } from "../../utils/apiCall";
import InputCheck from "../../sistema/components/InputCheck";
import { getDecimalPlacesOfSystem } from "../../sistema/utils/getDecimalPlacesOfSystem";
import { SellerBobjeto } from "../../cadastros/components/SellerBobjeto";
import { InputSelectArray } from "../../sistema/components/InputSelectArray";
import { ShowArray } from "../../sistema/components/ShowArray";
import { QuitacoesDaParcela } from "../components/QuitacoesDaParcela";

export type IFiltrosRelatorioFinanceiro = {
  pessoa_id: string;
  codigo: string;
  data_vencimento_ini: string;
  data_vencimento_fim: string;
  data_emissao_ini: string;
  data_emissao_fim: string;
  data_quitacao_ini: string;
  data_quitacao_fim: string;
  status: number;
  page: number;
  tipo: string;
  tipo_pagamento: string;
};

export const FinanceiroList = () => {
  useIsAuth();

  // tipo = pagar, receber
  const { tipo } = useParams();

  const [tipoFinanceiro, setTipoFinanceiro] = useState("A Pagar");

  const navigate = useNavigate();

  useEffect(() => {
    if (tipo == "receber") {
      setTipoFinanceiro("A Receber");
      return;
    }
    setTipoFinanceiro("A Pagar");

    if (tipo != "receber" && tipo != "pagar") {
      mensagemErro("Não autorizado.");
      navigate(-1);
    }
  }, [tipo, setTipoFinanceiro]);

  const [page, setPage] = useState(1);

  const [currentItem, setCurrentItem] = useState<IFiltrosRelatorioFinanceiro>({
    pessoa_id: "",
    codigo: "",
    data_vencimento_ini: getDataMenosDias(5),
    data_vencimento_fim: getLastDayOfCurrentMonth(),
    data_emissao_ini: "",
    data_emissao_fim: "",
    data_quitacao_ini: "",
    data_quitacao_fim: "",
    status: 3,
    page: 1,
    tipo: tipo as any,
    tipo_pagamento: "",
  });

  useEffect(() => {
    setCurrentItem({
      ...currentItem,
      page,
    });
    dispatch(
      financeiroActions.buscaRelatorioFinanceiroRequest({
        ...currentItem,
        page,
      })
    );
  }, [page]);

  useEffect(() => {
    setCurrentItem({
      ...currentItem,
      page,
      tipo: tipo as any,
    });
    dispatch(
      financeiroActions.buscaRelatorioFinanceiroRequest({
        ...currentItem,
        page,
        tipo: tipo as any,
      })
    );
  }, [tipo]);

  const dispatch = useDispatch();

  const isMobile = useSelector((state: RootState) => state.sistema.isMobile);
  const isLoading = useSelector(
    (state: RootState) => !!state.financeiro.isLoading
  );
  const relatorioFinanceiro = useSelector(
    (state: RootState) => state.financeiro.relatorioFinanceiro
  );

  const getLarguraPagina = (partes: number) => {
    if (isMobile) return "100%";

    return `${100 / partes}%`;
  };

  const tableHeaders: TableHeaders<FinanceiroParcela>[] = [
    {
      label: "Número/Parcela",
      wrapped: true,
      render: (item) => (
        <>
          {item.financeiro?.codigo}/{item?.codigo}{" "}
          <Link
            target="_blank"
            to={`/financeiro/${tipo}/${item.financeiro_id}`}
          >
            {" "}
            <DefaultButton title="Ver financeiro" size={"xs"}>
              <FiEye /> &nbsp; Ver
            </DefaultButton>
          </Link>
          &nbsp;
          <CamposPersonalizadosInfoButton
            size="xs"
            camposPersonalizados={item?.financeiro?.camposPersonalizados ?? []}
          />
          <br />
          <Badge colorScheme="blue">
            <ShowArray
              arrayName="financeiro_tipo_pagamento"
              value={(item.financeiro?.tipo_pagamento ?? 0) * 1}
            />
          </Badge>
          {tipo == 'receber' && <Badge colorScheme="green" ml={0.5}>
            {formatValor(item.comission_value, 2)} comissão (
            {formatValor(item.comission_percentage, 2)}%)
          </Badge>}
        </>
      ),
    },
    {
      label: "Pessoa",
      wrapped: false,
      render: (item) => (
        <>
          {item.financeiro?.pessoa?.nome}

          {item.financeiro?.pessoa?.cpf_cnpj && (
            <> ({formatCpfCnpj(item.financeiro?.pessoa?.cpf_cnpj ?? "")})</>
          )}

          {item?.financeiro?.seller && (
            <>
              <br />
              <Badge color={"green.500"}>
                Vendedor: {item?.financeiro?.seller?.name}
              </Badge>
            </>
          )}

          {item?.financeiro?.descricao && (
            <>
              <br />
              <small> {item?.financeiro?.descricao} </small>
            </>
          )}
        </>
      ),
    },
    {
      label: "Emissão",
      wrapped: false,
      render: (item) =>
        formatData(item?.financeiro?.data_emissao ?? "", "dd/MM/yyyy"),
    },
    {
      label: "Vencimento",
      wrapped: false,
      render: (item) => parseData(item.data_vencimento, "dd/MM/yyyy"),
    },
    {
      label: "Valor",
      wrapped: false,
      render: (item) => (
        <React.Fragment>
          {formatValor(item.valor, getDecimalPlacesOfSystem())} <br />
        </React.Fragment>
      ),
    },
    {
      label: "Quitado",
      wrapped: true,
      render: (item) =>
        <>
          {formatValor(item.valor_quitado, getDecimalPlacesOfSystem())} &nbsp;
          {(item?.quitacoes ?? []).length > 0 && <Button
            size={"xs"}
            title="Quitações"
            colorScheme="blackAlpha"
            p={2}
            onClick={() => {
              setQuitacoesModel(item);
              onOpenQuitacoes();
            }}
          >
            <FiDollarSign />
            <Badge
              position="absolute"
              top="-2"
              right="-2"
              fontSize="0.9em"
              colorScheme="cyan"
              borderRadius="full"
              px="1.5"
              py="0.5"
            >
              {(item?.quitacoes ?? []).length}
            </Badge>
          </Button>}
        </>
    },
    {
      label: "Status",
      wrapped: false,
      render: (item) =>
        item.status == 1
          ? "Parcialmente Quitado"
          : item.status == 2
            ? "Quitado"
            : "Em aberto",
    },
  ];

  const [selectedItems, setSelectedItems] = useState<FinanceiroParcela[]>([]);

  const getValoresBaixa = () => {
    let valores: any = [];

    for (const parcela of relatorioFinanceiro?.data ?? []) {
      (valores as any)[parcela.id as number] = parcela._valor_baixa;
    }

    return valores;
  };

  const getValoresDesconto = () => {
    let valores: any = [];

    for (const parcela of relatorioFinanceiro?.data ?? []) {
      (valores as any)[parcela.id as number] = parcela._valor_desconto;
    }

    return valores;
  };

  const getValoresJuros = () => {
    let valores: any = [];

    for (const parcela of relatorioFinanceiro?.data ?? []) {
      (valores as any)[parcela.id as number] = parcela._valor_juros;
    }

    return valores;
  };

  const getAtualizaValores = () => {
    let valores: any = [];

    for (const parcela of relatorioFinanceiro?.data ?? []) {
      (valores as any)[parcela.id as number] = parcela._atualiza_valor;
    }

    return valores;
  };


  const getIncludeInCashRegister = () => {
    let valores: any = [];

    for (const parcela of relatorioFinanceiro?.data ?? []) {
      (valores as any)[parcela.id as number] = parcela._include_in_cash_register;
    }

    return valores;
  };

  const [isLoadingLocal, setIsLoadingLocal] = useState(false);

  const {
    isOpen: isOpenQuitacoes,
    onOpen: onOpenQuitacoes,
    onClose: onCloseQuitacoes,
  } = useDisclosure();

  const [quitacoesModel, setQuitacoesModel] =
    useState<FinanceiroParcela | null>(null);

  return (
    <Layout>
      <Box width={"full"}>
        <ListHeader label={`Contas ${tipoFinanceiro}`}>
          <DefaultButton ml={4} to={`/financeiro/${tipo}/novo`}>
            {" "}
            Nova conta {tipoFinanceiro}{" "}
          </DefaultButton>
        </ListHeader>
        <Loader isLoading={isLoading} />
        {quitacoesModel && <QuitacoesDaParcela
          isOpen={isOpenQuitacoes}
          onClose={onCloseQuitacoes}
          financeiroParcela={quitacoesModel}
          onDeleted={(parcelas) => setQuitacoesModel(parcelas)}
        />}
        {currentItem && (
          <Formik
            enableReinitialize
            initialValues={currentItem}
            onSubmit={async (val, { setErrors }) => {
              setCurrentItem(val);

              setPage(1);

              dispatch(
                financeiroActions.buscaRelatorioFinanceiroRequest({
                  ...val,
                  page,
                })
              );
            }}
          >
            {({ values, submitForm }) => (
              <Form>
                <Flex width="full" wrap="wrap">
                  <InputField
                    type="date"
                    label="Data Vencimento Início"
                    name="data_vencimento_ini"
                    width={getLarguraPagina(6)}
                  />
                  <InputField
                    type="date"
                    label="Data Vencimento Fim"
                    name="data_vencimento_fim"
                    width={getLarguraPagina(6)}
                  />
                  <InputField
                    type="date"
                    label="Data Quitação Início"
                    name="data_quitacao_ini"
                    width={getLarguraPagina(6)}
                  />
                  <InputField
                    type="date"
                    label="Data Quitação Fim"
                    name="data_quitacao_fim"
                    width={getLarguraPagina(6)}
                  />
                  <InputField
                    type="date"
                    label="Data Emissão Início"
                    name="data_emissao_ini"
                    width={getLarguraPagina(6)}
                  />
                  <InputField
                    type="date"
                    label="Data Emissão Fim"
                    name="data_emissao_fim"
                    width={getLarguraPagina(6)}
                  />
                  <InputField
                    label="Código"
                    name="codigo"
                    width={getLarguraPagina(6)}
                  />
                  <InputSelect
                    label="Status"
                    name="status"
                    width={getLarguraPagina(6)}
                    noPlaceholer
                  >
                    <option value="0">Em aberto</option>
                    <option value="1">Parcialmente quitados</option>
                    <option value="2">Quitados</option>
                    <option value="3">Todos</option>
                  </InputSelect>
                  <PessoaBobjeto
                    label="Pessoa"
                    name="pessoa_id"
                    width={getLarguraPagina(6)}
                  />
                  <SellerBobjeto width={getLarguraPagina(6)} />
                  <InputField
                    label="Pedido"
                    name="numero_pedido"
                    width={getLarguraPagina(6)}
                  />
                  <InputSelectArray
                    label="Tipo de Pagamento"
                    arrayName="financeiro_tipo_pagamento"
                    name="tipo_pagamento"
                    width={getLarguraPagina(6)}
                  />

                  <ButtonGroup alignItems={"center"} mt={2} ml={1}>
                    <CamposPersonalizadosFiltros
                      par="financeiro"
                      onFilter={submitForm}
                    />
                    <DefaultButton type="submit">
                      Buscar Financeiros
                    </DefaultButton>
                    &nbsp; Total de {relatorioFinanceiro?.total ?? 0} parcelas
                  </ButtonGroup>
                </Flex>
              </Form>
            )}
          </Formik>
        )}
      </Box>

      <Flex gap={2} width={"full"} wrap={"wrap"} mt={1}>
        <Flex
          bg={"white"}
          rounded={"15px"}
          boxShadow={"base"}
          fontSize={"18px"}
          direction={"column"}
          p={4}
          alignItems={"center"}
          textAlign={"center"}
        >
          <b style={{ marginBottom: "10px" }}> VALOR TOTAL </b>
          R${" "}
          {formatValor(
            (relatorioFinanceiro?.data ?? []).reduce(
              (acumulador, atual: FinanceiroParcela) => {
                return (acumulador += parseFloat(atual.valor));
              },
              0
            ),
            getDecimalPlacesOfSystem()
          )}
        </Flex>
        <Flex
          bg={"white"}
          rounded={"15px"}
          boxShadow={"base"}
          fontSize={"18px"}
          direction={"column"}
          p={4}
          alignItems={"center"}
          textAlign={"center"}
        >
          <b style={{ marginBottom: "10px" }}> TOTAL EM ABERTO </b>
          R${" "}
          {formatValor(
            (relatorioFinanceiro?.data ?? []).reduce(
              (acumulador, atual: FinanceiroParcela) => {
                return (acumulador +=
                  parseFloat(atual.valor) - parseFloat(atual.valor_quitado));
              },
              0
            ),
            getDecimalPlacesOfSystem()
          )}
        </Flex>
        {tipo == 'receber' &&
          <Flex
            bg={"white"}
            rounded={"15px"}
            boxShadow={"base"}
            fontSize={"18px"}
            direction={"column"}
            p={4}
            alignItems={"center"}
            textAlign={"center"}
          >
            <b style={{ marginBottom: "10px" }}> TOTAL COMISSÃO </b>
            R${" "}
            {formatValor(
              (relatorioFinanceiro?.data ?? []).reduce(
                (acumulador, atual: FinanceiroParcela) => {
                  return (acumulador += parseFloat(atual.comission_value as any));
                },
                0
              ),
              2
            )}
          </Flex>}
        <Flex
          bg={"white"}
          rounded={"15px"}
          boxShadow={"base"}
          fontSize={"14px"}
          direction={"column"}
          px={4}
          py={2}
          alignItems={"center"}
          textAlign={"center"}
        >
          <b style={{ marginBottom: "0px", fontSize: '18px' }}> TOTAL QUITADO </b>
          <Flex width={'full'} direction={'column'} justifyContent={'left'} textAlign={'left'}>
            <span className="w-full flex justify-between">
              Bruto <b>R${" "}
                {formatValor(
                  (relatorioFinanceiro?.data ?? []).reduce(
                    (acumulador, atual: FinanceiroParcela) => {
                      return (acumulador += (atual?.quitacoes ?? []).reduce((acm, act) => {
                        return (acm += parseFloat(act.valor))
                      }, 0));
                    },
                    0
                  ),
                  getDecimalPlacesOfSystem()
                )}</b>
            </span>
            <span className="w-full flex justify-between">
              Descontos <b>R${" "}
                {formatValor(
                  (relatorioFinanceiro?.data ?? []).reduce(
                    (acumulador, atual: FinanceiroParcela) => {
                      return (acumulador += (atual?.quitacoes ?? []).reduce((acm, act) => {
                        return (acm += parseFloat(act.valor_desconto))
                      }, 0));
                    },
                    0
                  ),
                  getDecimalPlacesOfSystem()
                )} </b>
            </span>
            <span className="w-full flex justify-between">
              Juros <b>R${" "}
                {formatValor(
                  (relatorioFinanceiro?.data ?? []).reduce(
                    (acumulador, atual: FinanceiroParcela) => {
                      return (acumulador += (atual?.quitacoes ?? []).reduce((acm, act) => {
                        return (acm += parseFloat(act.valor_juros))
                      }, 0));
                    },
                    0
                  ),
                  getDecimalPlacesOfSystem()
                )} </b>
            </span>
            <span className="w-full flex justify-between">
              Líquido <b>R${" "}
                {formatValor(
                  (relatorioFinanceiro?.data ?? []).reduce(
                    (acumulador, atual: FinanceiroParcela) => {
                      return (acumulador += (atual?.quitacoes ?? []).reduce((acm, act) => {
                        return (acm += parseFloat(act.valor_liquido))
                      }, 0));
                    },
                    0
                  ),
                  getDecimalPlacesOfSystem()
                )} </b>
            </span>
          </Flex>
        </Flex>
      </Flex>

      <Formik
        enableReinitialize
        initialValues={{
          data_baixa: getCurrentData("yyyy-MM-dd"),
          _valor_baixa: getValoresBaixa() as any,
          _valor_desconto: getValoresDesconto() as any,
          _valor_juros: getValoresJuros() as any,
          _atualiza_valor: getAtualizaValores() as any,
          _include_in_cash_register: getIncludeInCashRegister() as any,
        }}
        onSubmit={async (val, { setErrors }) => {
          console.log(val);

          const parcelasAQuitar = selectedItems
            .map((item) => ({
              ...item,
              _valor_baixa: val._valor_baixa[item.id as number],
              _valor_desconto: val._valor_desconto[item.id as number],
              _valor_juros: val._valor_juros[item.id as number],
              _atualiza_valor: val._atualiza_valor[item.id as number],
              _include_in_cash_register: val._include_in_cash_register[item.id as number],
            }))
            .filter((i) => i._valor_baixa > 0);

          console.log("parcelas a quitar", parcelasAQuitar);

          try {
            setIsLoadingLocal(true);

            await apiCall({
              url: `baixa-parcelas`,
              data: { parcelas: parcelasAQuitar, data_baixa: val.data_baixa },
              method: "POST",
            });

            setIsLoadingLocal(false);
            setSelectedItems([]);

            dispatch(
              financeiroActions.buscaRelatorioFinanceiroRequest(currentItem)
            );
          } catch (err: any) {
            setIsLoadingLocal(false);
            mensagemErro(formatError(err));
          }
        }}
      >
        {({ values }) => (
          <Form>
            <ResponsiveTable
              onSelectForm={(item: FinanceiroParcela) => (
                <>
                  <Td textAlign={"right"} colSpan={2}>
                    <small>
                      <b> DETALHES DA BAIXA</b>{" "}
                    </small>
                  </Td>
                  <Td textAlign={"right"} colSpan={6}>
                    <div className="w-full flex flex-wrap">
                      <InputNumber
                        decimalPlaces={getDecimalPlacesOfSystem()}
                        label="Valor da Baixa"
                        name={`_valor_baixa.[${item.id}]`}
                        width={isMobile ? '100%' : '33%'}
                      />
                      <InputNumber
                        decimalPlaces={getDecimalPlacesOfSystem()}
                        label="Valor do Juros"
                        name={`_valor_juros.[${item.id}]`}
                        width={isMobile ? '100%' : '33%'}
                      />
                      <InputNumber
                        decimalPlaces={getDecimalPlacesOfSystem()}
                        label="Valor do Desconto"
                        name={`_valor_desconto.[${item.id}]`}
                        width={isMobile ? '100%' : '33%'}
                      />
                    </div>
                    <div className="w-full text-left">
                      <InputCheck
                        label="Registra no fluxo de caixa do dia?"
                        name={`_include_in_cash_register.[${item.id}]`}
                      />
                      {item.status == 0 &&
                        values._valor_baixa[item.id as number] !=
                        item._valor_baixa && (
                          <InputCheck
                            label="Atualiza o valor total da parcela pelo o valor da baixa digitado acima?"
                            name={`_atualiza_valor.[${item.id}]`}
                          />
                        )}
                      </div>
                  </Td>
                </>
              )}
              canSelect={(item: FinanceiroParcela) => item.status != 2}
              selectedItems={selectedItems}
              changeItems={setSelectedItems}
              headers={tableHeaders}
              data={relatorioFinanceiro?.data ?? []}
              isSmall
            />

            {relatorioFinanceiro && (
              <Pagination info={relatorioFinanceiro} paginate={setPage} />
            )}

            {selectedItems.length > 0 && (
              <Box
                bg={"white"}
                mt={2}
                width={"full"}
                position="sticky"
                bottom="10px"
                zIndex="10"
                p={2}
                rounded={'md'}
                shadow={'md'}
              >
                <Flex width="full" wrap="wrap">
                  &bull; Total de {selectedItems.length} parcelas (R${" "}
                  {formatValor(
                    selectedItems.reduce(
                      (acumulador, atual: FinanceiroParcela) => {
                        return (acumulador += parseFloat(atual.valor ?? "0"));
                      },
                      0
                    ),
                    getDecimalPlacesOfSystem()
                  )}
                  ) <br />
                  &bull; Total de R${" "}
                  {formatValor(
                    selectedItems.reduce(
                      (acumulador, atual: FinanceiroParcela) => {
                        return (acumulador += parseFloat(
                          values._valor_baixa[atual.id as number] ?? "0"
                        ));
                      },
                      0
                    ),
                    getDecimalPlacesOfSystem()
                  )}{" "}
                  a baixar <br />
                  <div className="w-full"></div>
                  <InputField
                    type="date"
                    label="Data da baixa"
                    name="data_baixa"
                    width={isMobile ? "full" : "250px"}
                  />
                  <Divider my={2} />
                  <DefaultButton isLoading={isLoadingLocal} type="submit">
                    Quitar selecionados
                  </DefaultButton>
                </Flex>
              </Box>
            )}
          </Form>
        )}
      </Formik>
    </Layout>
  );
};
