import { FiInfo } from "react-icons/fi"
import { DefaultButton } from "./DefaultButton"
import { Box, Button, Modal, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import { PessoaCampoPersonalizado } from "../types/pessoa_campo_personalizado";
import { CamposPersonalizadosView } from "./CamposPersonalizadosView";
import { ListHeaderSecondary } from "./ListHeaderSecondary";

export const CamposPersonalizadosInfoButton = ({ camposPersonalizados, size = "sm" }: { camposPersonalizados: PessoaCampoPersonalizado[]; size?: string }) => {

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <DefaultButton title="Dados adicionais" size={size} onClick={onOpen}><FiInfo /></DefaultButton>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"sm"}>
        <ModalOverlay />
        <ModalContent>
        <ModalHeader>
          <ListHeaderSecondary label="Dados adicionais" />
        </ModalHeader>
          <ModalCloseButton />
          <Box p={4}>
            <CamposPersonalizadosView values={camposPersonalizados ?? []} />
          </Box>
          <ModalFooter>
            <Button colorScheme="blackAlpha" onClick={onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}