import { Model } from "../../sistema/types/model";
import { Produto } from "./produto";
import { StockLocation } from "./stock_location";

export enum StockType {
  Input = "input",
  Output = "output",
}

export enum StockStatus {
  Approved = "approved",
  Reserved = "reserved",
}

export interface Stock extends Model {
  stock_location_id: string | number;
  quantity: number;
  produto_id: string | number;
  type: StockType;
  canceled: number;
  description: string;
  stockable_id: string | number;
  stockable_type: string;
  status: StockStatus;
  stockLocation?: StockLocation;
  product?: Produto;
}
