import { Form, Formik } from "formik";
import Layout from "../../sistema/components/Layout"
import Loader from "../../sistema/components/Loader";
import { Header } from "../components/cash-register/Header";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { CashRegister as CashRegisterType } from "../types/cash_register";
import { cashRegisterActions } from "../reducer";
import { CashRegisterList } from "../components/cash-register/CashRegisterList";
import { Side } from "../components/cash-register/Side";

export const CashRegister = () => {
    const selectedCashRegister = useSelector((state: RootState) => state.cashRegister?.cashRegister);
    const cashRegisters = useSelector((state: RootState) => state.cashRegister?.cashRegisters?.data ?? []);
    const isLoading = useSelector((state: RootState) => state.cashRegister?.isLoading ?? false);

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(cashRegisterActions.cashRegistersRequest({ page: 1, termo: ''}))
    }, [])

    return (
      <Layout padding={0}>
        <Loader isLoading={isLoading} />
        <Formik
        enableReinitialize
        initialValues={{}}
        onSubmit={(values, { setErrors }) => {
          
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="w-full border min-h-[200vh] sm:min-h-[100vh] p-5 bg-gray-200">
              <Loader isLoading={false} />

              <div className="w-full flex flex-wrap min-h-[25vh] max-h-[25vh] mb-4 sm:mb-4">
                <div className="w-full mb-1">
                  <Header />
                </div>
                <div className="w-full flex flex-wrap md:w-4/5 mb-4 sm:mb-4">
                  <CashRegisterList />
                </div>
                <div className="w-full flex flex-wrap md:w-1/5 mb-4 sm:mb-4">
                  <Side />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      </Layout>
    )
}