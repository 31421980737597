import { Box, ButtonGroup, Card, CardBody, CardHeader, Flex, Grid, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, StackDivider, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react"
import { Form, Formik } from "formik"
import { IResponseRelatorioContatosRealizados, crmActions } from "../reducer"
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../sistema/components/InputField";
import { RootState } from "../../app/mainReducer";
import InputMultiSelect from "../../sistema/components/InpurMultiSelect";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Layout from "../../sistema/components/Layout";
import { useIsAuth } from "../../hooks/useIsAuth";
import { ListHeader } from "../../sistema/components/ListHeader";
import { Pagination } from "../../sistema/components/Pagination";
import { AgendamentosButton } from "../components/AgendamentosButton";
import { ContatosButton } from "../components/ContatosButton";
import { UltimosPedidosButton } from "../components/UltimosPedidosButton";
import { DadosPessoaButton } from "../components/DadosPessoaButton";
import Loader from "../../sistema/components/Loader";
import { formatData, getCurrentData, getDataMenosDias, parseData } from "../../utils/data";
import { InputSelectArray } from "../../sistema/components/InputSelectArray";
import { apiCall } from "../../utils/apiCall";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { PDFViewer } from "@react-pdf/renderer";
import { arrayMotivoNaoCompra } from "../arrays/motivo_nao_compra";
import { arrayFormaContato } from "../arrays/forma_contato";
import { RelacaoContatosRealizadosPdf } from "../components/RelacaoContatosRealizadosPdf";

export type IFiltrosRelatorioContatosRealizados = {
  cliente_cidade_ufs: { label: string; value: number }[];
  cliente_cidade_nome: string;
  cliente_nome: string;
  data_inicio: string;
  data_fim: string;
  page: number;
  empresas: number | string;
  data_integracao: string;
  forma_contato: { label: string; value: number }[];
  motivo_nao_venda: { label: string; value: number }[];
}

export const RelatorioRelacaoContatosRealizados = () => {
  useIsAuth();

  const [page, setPage] = useState(1);

  const [currentItem, setCurrentItem] = useState<IFiltrosRelatorioContatosRealizados>({
    data_inicio: getDataMenosDias(30),
    data_fim: getCurrentData('yyyy-MM-dd'),
    cliente_nome: '',
    cliente_cidade_nome: '',
    cliente_cidade_ufs: [],
    empresas: '',
    page: 1,
    data_integracao: '',
    forma_contato: [],
    motivo_nao_venda: [],
  })

  useEffect(() => {
    setCurrentItem({
      ...currentItem,
      page,
    })
    dispatch(crmActions.buscaRelatorioContatosRealizadosRequest({
      ...currentItem,
      page,
    }));
  }, [page])

  const dispatch = useDispatch();

  const isMobile = useSelector((state: RootState) => state.sistema.isMobile);
  const isLoading = useSelector((state: RootState) => !!state.crm.isLoading);
  const relatorioContatosRealizados = useSelector((state: RootState) => state.crm.relatorioContatosRealizados);

  const getUfsFormatadas = () => {
    return (relatorioContatosRealizados?.ufs ?? []).map((i) => ({ label: i.label, value: i.value }))
  }

  const getLarguraPagina = (partes: number) => {
    if (isMobile) return '100%';

    return `${100 / partes}%`;
  }

  const itensArrayEmpresas = useSelector((state: RootState) => (state.sistema?.listas ?? {})['empresas'] ?? []);

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [itens, setItens] = useState<IResponseRelatorioContatosRealizados | null>(null);
  const [isLoadingLocal, setIsLoadingLocal] = useState<boolean>(false);
  const onImprimir = async () => {
    setIsLoadingLocal(true);
    const { data } = await apiCall({
      url: `relacao-contatos-realizados?filtros=${JSON.stringify({
        ...currentItem,
        cliente_cidade_ufs: currentItem.cliente_cidade_ufs.map((i) => i.value),
        forma_contato: currentItem.forma_contato.map((i) => i.value),
        motivo_nao_venda: currentItem.motivo_nao_venda.map((i) => i.value),
      })}&page=${currentItem?.page}&apenasDados=sim`, method: 'GET'
    });

    console.log(data)
    setIsLoadingLocal(false);
    setItens(data);
    onOpen();
  }

  const getFiltros = () => {
    const retorno: string[] = [];

    if (currentItem.data_inicio) {
      retorno.push(`Data início: ${formatData(currentItem.data_inicio, "dd/MM/yyyy")}`);
    }
    if (currentItem.data_fim) {
      retorno.push(`Data fim: ${formatData(currentItem.data_fim, "dd/MM/yyyy")}`);
    }
    if (currentItem.data_integracao) {
      retorno.push(`Data integração: ${parseData(currentItem.data_integracao, "dd/MM/yyyy")}`);
    }
    if (currentItem.cliente_nome) {
      retorno.push(`Cliente: ${currentItem.cliente_nome}`);
    }
    if (currentItem.forma_contato.length) {
      retorno.push(`Formas de contato: ${(currentItem.forma_contato.map((j) => j.label) as string[]).join(', ')}`);
    }
    if (currentItem.motivo_nao_venda.length) {
      retorno.push(`Motivos não venda: ${(currentItem.motivo_nao_venda.map((j) => j.label) as string[]).join(', ')}`);
    }
    if (currentItem.cliente_cidade_nome) {
      retorno.push(`Cidade: ${currentItem.cliente_cidade_nome}`);
    }
    if (currentItem.cliente_cidade_ufs.length) {
      retorno.push(`Estados: ${(currentItem.cliente_cidade_ufs.map((j) => j.label) as string[]).join(', ')}`);
    }
    if (currentItem.empresas) {
      retorno.push(`Empresa: ${itensArrayEmpresas?.find((i) => i.value == currentItem.empresas)?.label}`);
    }

    if (retorno.length === 0) return ['Nenhum'];

    return retorno;
  }

  const parametros = useSelector((state: RootState) => state.sistema?.parametros)

  const onSearch = () => {
    dispatch(crmActions.buscaRelatorioContatosRealizadosRequest({
      ...currentItem,
      page,
    }));
  }

  return (
    <Layout>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"full"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ListHeaderSecondary label={'Relatório'} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isOpen && itens && <PDFViewer style={{ width: "100%", height: "80vh" }}>
              <RelacaoContatosRealizadosPdf relatorio={itens} parametros={parametros} filtros={getFiltros()} itensArrayEmpresas={itensArrayEmpresas} />
            </PDFViewer>}
            <DefaultButton onClick={onClose} mt={2} width={"full"}>
              Fechar
            </DefaultButton>

          </ModalBody>
        </ModalContent>
      </Modal>

      <Box width={"full"}>
        <ListHeader label="Relação de Contatos Realizados">
          <DefaultButton ml={1} onClick={onImprimir} isLoading={isLoadingLocal}>
            Imprimir
          </DefaultButton>
        </ListHeader>
        <Loader isLoading={isLoading} />

        {currentItem && <Formik
          enableReinitialize
          initialValues={currentItem}
          onSubmit={async (val, { setErrors }) => {

            setCurrentItem(val);

            setPage(1)

            dispatch(crmActions.buscaRelatorioContatosRealizadosRequest({
              ...val,
              page,
            }));
          }}
        >
          {({ values }) => (
            <Form>
              <Flex width="full" wrap="wrap">
                <InputField type="date" label="Data início" name="data_inicio" width={getLarguraPagina(5)} />
                <InputField type="date" label="Data fim" name="data_fim" width={getLarguraPagina(5)} />
                <InputField label="Cliente" name="cliente_nome" width={getLarguraPagina(5)} />
                <InputField label="Cidade" name="cliente_cidade_nome" width={getLarguraPagina(5)} />
                <InputMultiSelect label="UFS" name="cliente_cidade_ufs" options={getUfsFormatadas()} width={getLarguraPagina(5)} />
                <InputMultiSelect label="Motivo não venda" name="motivo_nao_venda" options={arrayMotivoNaoCompra().map((i) => ({ label: i.name, value: i.name }))} width={getLarguraPagina(5)} />
                <InputMultiSelect label="Forma de contato" name="forma_contato" options={arrayFormaContato().map((i) => ({ label: i.name, value: i.name }))} width={getLarguraPagina(5)} />
                {/* <InputSelectArray label="Empresa Cliente" arrayName="empresas" name="empresas" width={getLarguraPagina(5)} /> */}
                <InputField name="data_integracao" type="date" label="Data integração" width={getLarguraPagina(5)} />
                <ButtonGroup alignItems={"center"}>
                  <DefaultButton type="submit" ml={1}>Buscar</DefaultButton>
                </ButtonGroup>
              </Flex>
            </Form>)}
        </Formik>}
      </Box>

      <Grid mt={4} templateColumns={isMobile ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)'} gap={6}>
        <Box fontSize={"18px"} boxShadow={"base"} p={3} borderRadius={"25px"} bg={"white"} textAlign={"center"}>
          Total de Clientes: <b> {relatorioContatosRealizados?.itens?.total ?? 0} </b>
        </Box>
        <Box fontSize={"18px"} boxShadow={"base"} p={3} borderRadius={"25px"} bg={"white"} textAlign={"center"}>
          Total de Contatos Realizados: <b> {relatorioContatosRealizados?.total_contatos_realizados ?? 0} </b>
        </Box>
      </Grid>

      <Grid mt={4} templateColumns={isMobile ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)'} gap={6}>
        {(relatorioContatosRealizados?.itens?.data ?? []).map((i, index) => (
          <Card key={`${index}`}>
            <CardHeader>
              <Heading size='sm'>
                <b>{i?.nome}</b>
              </Heading>
            </CardHeader>
            <CardBody>
              <Stack divider={<StackDivider />} spacing='4'>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    Cliente
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    CPF/CNPJ: <b> {i?.cpf_cnpj} </b> <br />
                    {/* Empresa: <b> {i?.array_empresas ? itensArrayEmpresas.find((j) => j.value == i?.array_empresas as any)?.label : 'Nenhuma'}</b> <br /> */}
                    Integração: <b>{i?.data_integracao ? parseData(i?.data_integracao, 'dd/MM/yyyy') : 'Nenhuma'} </b> <br />
                    Endereço: <b> {i?.endereco}, n° {i?.numero} </b> <br />
                    Cidade: <b>{i?.cidade?.nome} - {i?.cidade?.uf} </b> <br />
                  </Text>
                </Box>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    Contatos Realizados
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    <Flex maxH={"250px"} overflowY={"auto"} wrap={"wrap"}>
                      {(i?.contatosRealizados ?? []).map((contato) => (
                        <Box py={2} borderTop={"2px solid #d3d3d3"} width={"full"}>
                          <b>Data: </b> {formatData(contato.data)} <br />
                          <b>Forma de contato: </b> {contato.forma_contato} <br />
                          <b>Motivo não venda: </b> {contato.motivo_nao_compra} <br />
                          <b>Conversa: </b> {contato.observacoes} <br />
                        </Box>
                      ))}
                    </Flex>
                  </Text>
                </Box>
                <Box>
                  <AgendamentosButton pessoa_id={i.id as number} />
                  <ContatosButton pessoa_id={i.id as number} />
                  <UltimosPedidosButton pessoa_id={i.id as number} />
                  <DadosPessoaButton pessoa_id={i.id as number} onDelete={onSearch} />
                </Box>
              </Stack>
            </CardBody>
          </Card>
        ))}

      </Grid>

      {relatorioContatosRealizados && <Pagination info={relatorioContatosRealizados.itens} paginate={setPage} />}
    </Layout >
  )

}