import { useCallback } from 'react';
import { PessoaCampoPersonalizado } from '../types/pessoa_campo_personalizado';
import { parseData } from '../../utils/data';
import { Text } from '@react-pdf/renderer';

export const CamposPersonalizadosView = ({ values, isPdf = false }: { values?: PessoaCampoPersonalizado[]; isPdf?: boolean; }) => {
  const InputLocal = ({ item }: { item: PessoaCampoPersonalizado; index: number }) => {
    if (isPdf) {
      if (item.tipo.trim() == 'array' || item.tipo.trim() == 'string') {
        return (
          <>
            {item.nome}: <Text style={{ fontWeight: 700 }}>  {item.valor} </Text>{"\n"}
          </>
        )
      }

      if (item.tipo.trim() == 'data') {
        return (
          <>
            {item.nome}: <Text style={{ fontWeight: 700 }}>  {parseData(item.valor, 'dd/MM/yyyy')} </Text>{"\n"}
          </>
        )
      }
      return <></>
    }

    if (item.tipo.trim() == 'array' || item.tipo.trim() == 'string') {
      return (
        <>
          {item.nome}: <b> {item.valor} </b> <br />
        </>
      )
    }

    if (item.tipo.trim() == 'data') {
      return (
        <>
          {item.nome}: <b> {parseData(item.valor, 'dd/MM/yyyy')} </b> <br />
        </>
      )
    }

    return (
      <></>
    );
  }

  const MemoizedInputLocal = useCallback(InputLocal, []);

  return (
    <>
      {(values ?? []).map((i: PessoaCampoPersonalizado, index: number) => (
        <MemoizedInputLocal item={i} key={`${index}`} index={index} />
      ))}
    </>
  )
}