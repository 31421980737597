import { Badge, Box, ButtonGroup, Flex } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../sistema/components/InputField";
import { RootState } from "../../app/mainReducer";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../sistema/components/Layout";
import { useIsAuth } from "../../hooks/useIsAuth";
import { ListHeader } from "../../sistema/components/ListHeader";
import { formatValor } from "../../utils/formatValor";
import Loader from "../../sistema/components/Loader";
import {
  formatData,
  getFirstDayOfCurrentMonth,
  getLastDayOfCurrentMonth,
  parseData,
} from "../../utils/data";
import { CamposPersonalizadosFiltros } from "../../sistema/components/CamposPersonalizadosFiltros";
import { mensagemErro } from "../../utils/toasts";
import { PessoaBobjeto } from "../../cadastros/components/PessoaBobjeto";
import { financeiroActions } from "../reducer";
import ResponsiveTable, {
  TableHeaders,
} from "../../sistema/components/ResponsiveTable";
import { FinanceiroParcela } from "../types/financeiro_parcela";
import { formatCpfCnpj } from "../../utils/formatCpfCnpj";
import { FiEye } from "react-icons/fi";
import { CamposPersonalizadosInfoButton } from "../../sistema/components/CamposPersonalizadosInfoButton";
import { getDecimalPlacesOfSystem } from "../../sistema/utils/getDecimalPlacesOfSystem";
import { InputSelectArray } from "../../sistema/components/InputSelectArray";
import { ShowArray } from "../../sistema/components/ShowArray";
import { FinanceiroParcelaQuitacao } from "../types/financeiro_quitacao";

export type IFiltrosRelatorioFinanceiroBaixas = {
  pessoa_id: string;
  data_vencimento_ini: string;
  data_vencimento_fim: string;
  data_quitacao_ini: string;
  data_quitacao_fim: string;
  page: number;
  tipo: string;
  tipo_pagamento: string;
};

export const FinanceiroBaixas = () => {
  useIsAuth();

  // tipo = pagar, receber
  const { tipo } = useParams();

  const [tipoFinanceiro, setTipoFinanceiro] = useState("A Pagar");

  const navigate = useNavigate();

  useEffect(() => {
    if (tipo == "receber") {
      setTipoFinanceiro("A Receber");
      return;
    }
    setTipoFinanceiro("A Pagar");

    if (tipo != "receber" && tipo != "pagar") {
      mensagemErro("Não autorizado.");
      navigate(-1);
    }
  }, [tipo, setTipoFinanceiro]);

  const [page, setPage] = useState(1);

  const [currentItem, setCurrentItem] = useState<IFiltrosRelatorioFinanceiroBaixas>({
    pessoa_id: "",
    data_vencimento_ini: "",
    data_vencimento_fim: "",
    data_quitacao_ini: getFirstDayOfCurrentMonth(),
    data_quitacao_fim: getLastDayOfCurrentMonth(),
    page: 1,
    tipo: tipo as any,
    tipo_pagamento: "",
  });

  useEffect(() => {
    setCurrentItem({
      ...currentItem,
      page,
    });
    dispatch(
      financeiroActions.buscaRelatorioFinanceiroBaixasRequest({
        ...currentItem,
        page,
      })
    );
  }, [page]);

  useEffect(() => {
    setCurrentItem({
      ...currentItem,
      page,
      tipo: tipo as any,
    });
    dispatch(
      financeiroActions.buscaRelatorioFinanceiroBaixasRequest({
        ...currentItem,
        page,
        tipo: tipo as any,
      })
    );
  }, [tipo]);

  const dispatch = useDispatch();

  const isMobile = useSelector((state: RootState) => state.sistema.isMobile);
  const isLoading = useSelector(
    (state: RootState) => !!state.financeiro.isLoading
  );
  const relatorioFinanceiroBaixas = useSelector(
    (state: RootState) => state.financeiro.relatorioFinanceiroBaixas ?? []
  );

  const getLarguraPagina = (partes: number) => {
    if (isMobile) return "100%";

    return `${100 / partes}%`;
  };

  const tableHeaders: TableHeaders<FinanceiroParcelaQuitacao>[] = [
    {
      label: "Número/Parcela",
      wrapped: true,
      render: (item) => (
        <>
          {item?.financeiroParcela?.financeiro?.codigo}/{item?.financeiroParcela?.codigo}{" "}
          <Link
            target="_blank"
            to={`/financeiro/${tipo}/${item.financeiroParcela?.financeiro_id}`}
          >
            {" "}
            <DefaultButton title="Ver financeiro" size={"xs"}>
              <FiEye /> &nbsp; Ver
            </DefaultButton>
          </Link>
          &nbsp;
          <CamposPersonalizadosInfoButton
            size="xs"
            camposPersonalizados={item?.financeiroParcela?.financeiro?.camposPersonalizados ?? []}
          />
          <br />
          <Badge colorScheme="blue">
            <ShowArray
              arrayName="financeiro_tipo_pagamento"
              value={(item.financeiroParcela?.financeiro?.tipo_pagamento ?? 0) * 1}
            />
          </Badge>
        </>
      ),
    },
    {
      label: "Pessoa",
      wrapped: false,
      render: (item) => (
        <>
          {item.financeiroParcela?.financeiro?.pessoa?.nome}

          {item.financeiroParcela?.financeiro?.pessoa?.cpf_cnpj && (
            <> ({formatCpfCnpj(item.financeiroParcela?.financeiro?.pessoa?.cpf_cnpj ?? "")})</>
          )}

          {item.financeiroParcela?.financeiro?.seller && (
            <>
              <br />
              <Badge color={"green.500"}>
                Vendedor: {item.financeiroParcela?.financeiro?.seller?.name}
              </Badge>
            </>
          )}

          {item.financeiroParcela?.financeiro?.descricao && (
            <>
              <br />
              <small> {item.financeiroParcela?.financeiro?.descricao} </small>
            </>
          )}
        </>
      ),
    },
    {
      label: "Emissão",
      wrapped: false,
      render: (item) =>
        formatData(item.financeiroParcela?.financeiro?.data_emissao ?? "", "dd/MM/yyyy"),
    },
    {
      label: "Vencimento",
      wrapped: false,
      render: (item) => parseData(item.financeiroParcela?.data_vencimento ?? "", "dd/MM/yyyy"),
    },
    {
      label: "Valor Parcela",
      wrapped: false,
      render: (item) => formatValor(item?.financeiroParcela?.valor ?? 0, getDecimalPlacesOfSystem()),
    },
    {
      label: "Data Baixa",
      wrapped: false,
      render: (item) => formatData(item.data_hora ?? "", "dd/MM/yyyy")
    },
    {
      label: "Valor Baixa",
      wrapped: false,
      render: (item) => formatValor(item.valor, getDecimalPlacesOfSystem())
    },
    {
      label: "Desc.",
      wrapped: false,
      render: (item) => formatValor(item.valor_desconto, getDecimalPlacesOfSystem())
    },
    {
      label: "Juros",
      wrapped: false,
      render: (item) => formatValor(item.valor_juros, getDecimalPlacesOfSystem())
    },
    {
      label: "Quitado",
      wrapped: false,
      render: (item) => formatValor(item.valor_liquido, getDecimalPlacesOfSystem())
    }
  ];


  const getFinanceiros = () => {
    let computeds: number[] = [];
    let financeiros: FinanceiroParcela[] = [];

    (relatorioFinanceiroBaixas ?? []).forEach(item => {
      if (! computeds.includes(item?.financeiroParcela?.id as number)) {
        computeds.push(item?.financeiroParcela?.id as number);
        financeiros.push(item?.financeiroParcela as FinanceiroParcela);
      }
    });
    return financeiros;
  }

  return (
    <Layout>
      <Box width={"full"}>
        <ListHeader label={`Relatório de Baixas ${tipoFinanceiro}`} />
        <Loader isLoading={isLoading} />

        {currentItem && (
          <Formik
            enableReinitialize
            initialValues={currentItem}
            onSubmit={async (val, { setErrors }) => {
              setCurrentItem(val);

              setPage(1);

              dispatch(
                financeiroActions.buscaRelatorioFinanceiroBaixasRequest({
                  ...val,
                  page,
                })
              );
            }}
          >
            {({ values, submitForm }) => (
              <Form>
                <Flex width="full" wrap="wrap">
                <InputField
                    type="date"
                    label="Data Baixa Início"
                    name="data_quitacao_ini"
                    width={getLarguraPagina(6)}
                  />
                  <InputField
                    type="date"
                    label="Data Baixa Fim"
                    name="data_quitacao_fim"
                    width={getLarguraPagina(6)}
                  />
                  <InputField
                    type="date"
                    label="Data Vencimento Início"
                    name="data_vencimento_ini"
                    width={getLarguraPagina(6)}
                  />
                  <InputField
                    type="date"
                    label="Data Vencimento Fim"
                    name="data_vencimento_fim"
                    width={getLarguraPagina(6)}
                  />
                  <PessoaBobjeto
                    label="Pessoa"
                    name="pessoa_id"
                    width={getLarguraPagina(6)}
                  />
                  <InputSelectArray
                    label="Tipo de Pagamento"
                    arrayName="financeiro_tipo_pagamento"
                    name="tipo_pagamento"
                    width={getLarguraPagina(6)}
                  />

                  <ButtonGroup alignItems={"center"} mt={2} ml={1}>
                    <CamposPersonalizadosFiltros
                      par="financeiro"
                      onFilter={submitForm}
                    />
                    <DefaultButton type="submit">
                      Buscar Financeiros
                    </DefaultButton>
                    &nbsp; Total de {relatorioFinanceiroBaixas?.length ?? 0} parcelas
                  </ButtonGroup>
                </Flex>
              </Form>
            )}
          </Formik>
        )}
      </Box>

      <Box width={"full"} mt={1}>
        <div className="flex flex-col m-2 px-3 py-2">
          <div className="shadow-md bg-white flex uppercase flex-col mx-auto w-full md:w-1/3 border rounded-md border-gray-900 px-2 m-2">
            <div className="text-lg text-bold flex justify-between py-2 border-b border-gray-300 text-gray-800"><b>Total dos títulos</b> R$ {formatValor(
              (getFinanceiros()).reduce(
                (acumulador, atual: FinanceiroParcela) => {
                  return (acumulador += parseFloat(atual.valor));
                },
                0
              ),
              getDecimalPlacesOfSystem()
            )}</div>
            <div className="text-lg text-bold flex justify-between py-2 border-b border-gray-300 text-gray-800"><b>Total das baixas</b> R$ {formatValor(
              (relatorioFinanceiroBaixas ?? []).reduce(
                (acumulador, atual: FinanceiroParcelaQuitacao) => {
                  return (acumulador += parseFloat(atual.valor));
                },
                0
              ),
              getDecimalPlacesOfSystem()
            )}</div>
            <div className="text-lg text-bold flex justify-between py-2 border-b border-gray-300 text-gray-800"><b>Total dos descontos</b> R$ {formatValor(
              (relatorioFinanceiroBaixas ?? []).reduce(
                (acumulador, atual: FinanceiroParcelaQuitacao) => {
                  return (acumulador += parseFloat(atual.valor_desconto))
                },
                0
              ),
              getDecimalPlacesOfSystem()
            )}</div>
            <div className="text-lg text-bold flex justify-between py-2 border-b border-gray-300 text-gray-800"><b>Total dos juros</b> R$ {formatValor(
              (relatorioFinanceiroBaixas ?? []).reduce(
                (acumulador, atual: FinanceiroParcelaQuitacao) => {
                  return (acumulador += parseFloat(atual.valor_juros))
                },
                0
              ),
              getDecimalPlacesOfSystem()
            )}</div>
            <div className="text-lg text-bold flex justify-between py-2 border-b border-gray-300 text-gray-800"><b>Total quitado</b> R$ {formatValor(
              (relatorioFinanceiroBaixas ?? []).reduce(
                (acumulador, atual: FinanceiroParcelaQuitacao) => {
                  return (acumulador += parseFloat(atual.valor_liquido))
                },
                0
              ),
              getDecimalPlacesOfSystem()
            )}
            </div>
          </div>
        </div>
      </Box>

      <ResponsiveTable
        isSmall
        headers={tableHeaders}
        data={relatorioFinanceiroBaixas ?? []}
      />
    </Layout>
  );
};
