import { Button } from "@chakra-ui/react";
import { DialogModal } from "../../../sistema/components/DialogModal";
import { ListHeaderSecondary } from "../../../sistema/components/ListHeaderSecondary";
import { CashRegisterTransaction, CashRegisterTransactionType } from "../../types/cash_register_transaction";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { apiCall } from "../../../utils/apiCall";
import { mensagemErro, mensagemSucesso } from "../../../utils/toasts";
import { formatError } from "../../../utils/formatError";
import { DefaultButton } from "../../../sistema/components/DefaultButton";
import InputNumber from "../../../sistema/components/InputNumber";
import InputField from "../../../sistema/components/InputField";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/mainReducer";

type AddTransactionProps = {
  isOpen: boolean;
  onClose: () => void;
  form: CashRegisterTransaction;
}

export const AddTransaction = ({ isOpen, onClose, form }: AddTransactionProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [focusInput, setFocusInput] = useState(true);
  const selectedCashRegister = useSelector((state: RootState) => state.cashRegister?.cashRegister);
  
  useEffect(() => {
    if (isOpen) {
    setTimeout(() => {
      setFocusInput(isOpen);
    }, 50)
  }
  }, [isOpen])

  return (
    <DialogModal
      show={isOpen}
      close={onClose}
      maxWidth="lg"
      title={
        <ListHeaderSecondary>
          Adicionar {form.type == CashRegisterTransactionType.Input ? "Entrada" : "Saída"}
          <Button
            type="button"
            onClick={onClose}
            size={"sm"}
            ml={1}
            colorScheme="blackAlpha"
          >
            Voltar
          </Button>
        </ListHeaderSecondary>
      }
      content={
        <div className="w-full">
          {form && <Formik
            enableReinitialize
            initialValues={form}
            onSubmit={async (values, { setErrors }) => {
              if (values.value <= 0) {
                setErrors({
                  value: "Tem que ser maior que zero!",
                });
                return;
              }
              if (!values.description) {
                setErrors({
                  description: "Tem que ser preenchido!",
                });
                return;
              }
              try {
                setIsLoading(true);
                await apiCall({ url: 'cash-register-transaction', method: 'POST', data: {
                  ...values,
                  cash_register_id: selectedCashRegister?.id,
                } as CashRegisterTransaction })
                setIsLoading(false);
                mensagemSucesso("Registro adicionado com sucesso!");
                onClose();
              } catch (error: any) {
                setIsLoading(false);
                mensagemErro(formatError(error));
              }
            }}
          >
            {({ values, setFieldValue }) => (
              <Form style={{ width: "100%" }}>
                <InputField
                  label="Descrição"
                  name="description"
                  width={'full'}
                  textarea
                  rows={3}
                  focus={focusInput}
                  onBlur={() => setFocusInput(false)}
                />
                <InputNumber
                  label="Valor"
                  name="value"
                  width={"full"}
                />

                <div className="p-1">
                  <DefaultButton isLoading={isLoading} type="submit" mt={2} width={"full"}>
                    ADICIONAR
                  </DefaultButton>
                </div>
              </Form>
            )}
          </Formik>}
        </div>
      }
      footer={
        <div className="w-full">
          <Button
            type="button"
            onClick={onClose}
            size={"sm"}
            colorScheme="blackAlpha"
          >
            Voltar
          </Button>
        </div>
      }
    />
  )
}