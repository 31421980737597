import { PayloadAction } from "@reduxjs/toolkit";
import { put, all, takeLatest } from "redux-saga/effects";
import axios, { AxiosResponse } from "axios";
import { call } from "redux-saga/effects";
import { IApiResponse } from "./types/apiRes";
import { User } from "./types/user";
import { ILista, sistemaActions } from "./recucer";
import { APIURL, apiCall } from "../utils/apiCall";
import { formatError } from "../utils/formatError";
import { ListPayload } from "./types/listPayload";
import { ApiPaginationRes } from "./types/apiPaginationRes";
import { novoUser } from "./data/user";
import { Parametros } from "./types/parametros";
import { Historico } from "./types/historico";
import { ImportadorPessoa } from "./types/importador_pessoa";
import { novaSistemaAssinatura } from "./data/sistema_assinatura";
import { SistemaAssinatura } from "./types/sistema_assinatura";
import { Empresa } from "./types/empresa";
import { novaEmpresa } from "./data/empresa";
import { Importador } from "./types/importador";

interface ILogin {
  email: string;
  password: string;
}

export interface TLoginRes extends IApiResponse {
  data: {
    user: User;
    token: string;
    parametros: Parametros;
  };
}

function loginCall(payload: ILogin) {
  console.log(`${APIURL}/login`);
  return axios.post(`${APIURL}/login`, {
    email: payload.email,
    password: payload.password,
  });
}

function* loginWorker({ payload }: PayloadAction<ILogin>) {
  try {
    const res: AxiosResponse<TLoginRes> = yield call(loginCall, payload);
    yield put(sistemaActions.loginSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.loginError(formatError(error)));
  }
}

function* saveUsuarioWorker({ payload }: PayloadAction<User>) {
  try {
    const res: AxiosResponse<User> = yield call(apiCall, {
      url: `usuario`,
      method: "post",
      data: {
        ...payload,
        acessos: JSON.stringify(payload.acessos),
      },
    });
    yield put(sistemaActions.saveUsuarioRequestSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}
function* deleteUsuariosRequestWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(apiCall, {
      url: `usuarios/${payload}`,
      method: "delete",
      data: payload,
    });
    yield put(sistemaActions.deleteUsuariosRequestSuccess());
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* usuariosRequestWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<User[]>> = yield call(apiCall, {
      url: `usuarios?termo=${payload.termo}&page=${payload.page}`,
      method: "get",
      data: payload,
    });
    yield put(sistemaActions.usuariosRequestSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* usuarioRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "novo") {
      yield put(sistemaActions.usuarioRequestSuccess(novoUser()));
      return;
    }

    const res: AxiosResponse<User> = yield call(apiCall, {
      url: `usuarios/${payload}`,
      method: "get",
      data: payload,
    });
    yield put(sistemaActions.usuarioRequestSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* parametrosRequestWorker({ payload }: PayloadAction<string>) {
  try {
    const res: AxiosResponse<Parametros> = yield call(apiCall, {
      url: `parametros`,
      method: "get",
      data: payload,
    });
    yield put(sistemaActions.parametrosRequestSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* saveParametrosRequestWorker({ payload }: PayloadAction<Parametros>) {
  try {
    const res: AxiosResponse<Parametros> = yield call(apiCall, {
      url: `parametros`,
      method: "post",
      data: {
        ...payload,
        modulos: JSON.stringify(payload.modulos),
      },
    });
    yield put(sistemaActions.saveParametrosRequestSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* historicoRequestWorker({
  payload,
}: PayloadAction<{ par: string; idpar: string }>) {
  try {
    const res: AxiosResponse<Historico[]> = yield call(apiCall, {
      url: `/historico/${payload.par}/${payload.idpar}`,
      method: "get",
    });
    yield put(sistemaActions.historicoRequestSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* getListaRequestWorker({ payload }: PayloadAction<string>) {
  try {
    const res: AxiosResponse<ILista> = yield call(apiCall, {
      url: `/helpers/lista/${payload}`,
      method: "get",
    });
    yield put(
      sistemaActions.getListaRequestSuccess({
        nome: payload,
        itens: res.data,
      })
    );
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* importadorPessoasWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<ImportadorPessoa[]>> = yield call(apiCall, {
      url: `importador-pessoa?termo=${payload.termo}&filtros=${JSON.stringify(payload.filtros)}&page=${payload.page}`,
      method: "get",
      data: payload,
    });
    yield put(sistemaActions.importadorPessoasSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* importadorWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<Importador[]>> = yield call(apiCall, {
      url: `importador?termo=${payload.termo}&filtros=${JSON.stringify(payload.filtros)}&page=${payload.page}`,
      method: "get",
      data: payload,
    });
    yield put(sistemaActions.importadorSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* saveSistemaAssinaturaRequestWorker({ payload }: PayloadAction<SistemaAssinatura>) {
  try {
    const res: AxiosResponse<SistemaAssinatura> = yield call(apiCall, {
      url: `sistema-assinatura`,
      method: "post",
      data: payload,
    });
    yield put(sistemaActions.saveSistemaAssinaturaRequestSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}
function* deleteSistemaAssinaturaRequestWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(apiCall, {
      url: `sistema-assinatura/${payload}`,
      method: "delete",
      data: payload,
    });
    yield put(sistemaActions.deleteSistemaAssinaturaRequestSuccess());
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* sistemaAssinaturasRequestWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<SistemaAssinatura[]>> = yield call(apiCall, {
      url: `sistema-assinatura?termo=${payload.termo}&page=${payload.page}`,
      method: "get",
      data: payload,
    });
    yield put(sistemaActions.sistemaAssinaturasRequestSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* sistemaAssinaturaRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "novo") {
      yield put(sistemaActions.sistemaAssinaturaRequestSuccess(novaSistemaAssinatura()));
      return;
    }

    const res: AxiosResponse<SistemaAssinatura> = yield call(apiCall, {
      url: `sistema-assinatura/${payload}`,
      method: "get",
      data: payload,
    });
    yield put(sistemaActions.sistemaAssinaturaRequestSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}



function* saveEmpresaRequestWorker({ payload }: PayloadAction<Empresa>) {
  try {
    const res: AxiosResponse<Empresa> = yield call(apiCall, {
      url: `empresas`,
      method: "post",
      data: payload,
    });
    yield put(sistemaActions.saveEmpresaRequestSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}
function* deleteEmpresaRequestWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(apiCall, {
      url: `empresas/${payload}`,
      method: "delete",
      data: payload,
    });
    yield put(sistemaActions.deleteEmpresaRequestSuccess());
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* empresasRequestWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<Empresa[]>> = yield call(apiCall, {
      url: `empresas?termo=${payload.termo}&page=${payload.page}`,
      method: "get",
      data: payload,
    });
    yield put(sistemaActions.empresasRequestSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* empresaRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "novo") {
      yield put(sistemaActions.empresaRequestSuccess(novaEmpresa()));
      return;
    }

    const res: AxiosResponse<Empresa> = yield call(apiCall, {
      url: `empresas/${payload}`,
      method: "get",
      data: payload,
    });
    yield put(sistemaActions.empresaRequestSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

function* mensalidadesEmAbertoRequestWorker() {
  try {
    const res: AxiosResponse<SistemaAssinatura[]> = yield call(apiCall, {
      url: `mensalidade-a-pagar`,
      method: "get",
    });
    yield put(sistemaActions.mensalidadesEmAbertoRequestSuccess(res.data));
  } catch (error: any) {
    yield put(sistemaActions.error(formatError(error)));
  }
}

export function* sistemaSaga() {
  yield all([
    takeLatest("sistema/loginRequest", loginWorker),
    takeLatest("sistema/usuarioRequest", usuarioRequestWorker),
    takeLatest("sistema/saveUsuarioRequest", saveUsuarioWorker),
    takeLatest("sistema/deleteUsuariosRequest", deleteUsuariosRequestWorker),
    takeLatest("sistema/usuariosRequest", usuariosRequestWorker),
    takeLatest("sistema/importadorPessoas", importadorPessoasWorker),
    takeLatest("sistema/importador", importadorWorker),
    takeLatest("sistema/parametrosRequest", parametrosRequestWorker),
    takeLatest("sistema/saveParametrosRequest", saveParametrosRequestWorker),
    takeLatest("sistema/historicoRequest", historicoRequestWorker),
    takeLatest("sistema/getListaRequest", getListaRequestWorker),
    takeLatest("sistema/sistemaAssinaturasRequest", sistemaAssinaturasRequestWorker),
    takeLatest("sistema/saveSistemaAssinaturaRequest", saveSistemaAssinaturaRequestWorker),
    takeLatest("sistema/deleteSistemaAssinaturaRequest", deleteSistemaAssinaturaRequestWorker),
    takeLatest("sistema/sistemaAssinaturaRequest", sistemaAssinaturaRequestWorker),
    takeLatest("sistema/empresasRequest", empresasRequestWorker),
    takeLatest("sistema/saveEmpresaRequest", saveEmpresaRequestWorker),
    takeLatest("sistema/deleteEmpresaRequest", deleteEmpresaRequestWorker),
    takeLatest("sistema/empresaRequest", empresaRequestWorker),
    takeLatest("sistema/mensalidadesEmAbertoRequest", mensalidadesEmAbertoRequestWorker),
  ]);
}
