import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Grid,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackDivider,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { IResponseRelatorioPedido, crmActions } from "../reducer";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../sistema/components/InputField";
import { RootState } from "../../app/mainReducer";
import InputMultiSelect from "../../sistema/components/InpurMultiSelect";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Layout from "../../sistema/components/Layout";
import { useIsAuth } from "../../hooks/useIsAuth";
import { ListHeader } from "../../sistema/components/ListHeader";
import { Pagination } from "../../sistema/components/Pagination";
import { AgendamentosButton } from "../components/AgendamentosButton";
import { ContatosButton } from "../components/ContatosButton";
import { UltimosPedidosButton } from "../components/UltimosPedidosButton";
import { DadosPessoaButton } from "../components/DadosPessoaButton";
import Loader from "../../sistema/components/Loader";
import {
  formatData,
  getCurrentData,
  getDataMenosDias,
  parseData,
} from "../../utils/data";
import { apiCall } from "../../utils/apiCall";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import InputSelect from "../../sistema/components/InputSelect";
import { formatValor } from "../../utils/formatValor";
import { Pedido } from "../../entradaesaida/types/pedido";
import { mensagemErro, mensagemSucesso } from "../../utils/toasts";
import { formatError } from "../../utils/formatError";
import { PDFViewer } from "@react-pdf/renderer";
import { RelacaoPedidoPdf } from "../components/RelacaoPedidoPdf";
import { CamposPersonalizadosFiltros } from "../../sistema/components/CamposPersonalizadosFiltros";
import { CamposPersonalizadosView } from "../../sistema/components/CamposPersonalizadosView";
import { getDecimalPlacesOfSystem } from "../../sistema/utils/getDecimalPlacesOfSystem";
import { SellerBobjeto } from "../../cadastros/components/SellerBobjeto";
import { Seller } from "../../cadastros/types/seller";
import { ShowArray } from "../../sistema/components/ShowArray";
import { FiEye } from "react-icons/fi";
import { Link } from "react-router-dom";
import { ConfirmationModal } from "../../sistema/components/ConfirmationModal";
import { SecondaryButton } from "../../sistema/components/SecondaryButton";
import { DangerButton } from "../../sistema/components/DangerButton";

export type IFiltrosRelatorioPedido = {
  cliente_cidade_ufs: { label: string; value: number }[];
  status: string;
  cliente_cidade_nome: string;
  cliente_nome: string;
  data_inicio: string;
  data_fim: string;
  page: number;
  empresas: number | string;
  data_integracao: string;
  seller_id: string;
  pedido_numero: string;
};

export const RelatorioRelacaoPedido = () => {
  useIsAuth();

  const [page, setPage] = useState(1);

  const [sellers, setSellers] = useState<Seller[]>([]);

  const [currentItem, setCurrentItem] = useState<IFiltrosRelatorioPedido>({
    data_inicio: getDataMenosDias(30),
    data_fim: getCurrentData("yyyy-MM-dd"),
    cliente_nome: "",
    cliente_cidade_nome: "",
    cliente_cidade_ufs: [],
    empresas: "",
    status: "Todos",
    page: 1,
    data_integracao: "",
    seller_id: "",
    pedido_numero: "",
  });

  useEffect(() => {
    setCurrentItem({
      ...currentItem,
      page,
    });
    dispatch(
      crmActions.buscaRelatorioPedidosRequest({
        ...currentItem,
        page,
      })
    );
  }, [page]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(crmActions.buscaFichaSelectInputsRequest());
  }, [dispatch]);

  const isMobile = useSelector((state: RootState) => state.sistema.isMobile);
  const isLoading = useSelector((state: RootState) => !!state.crm.isLoading);
  const relatorioPedidos = useSelector(
    (state: RootState) => state.crm.relatorioPedidos
  );

  const getLarguraPagina = (partes: number) => {
    if (isMobile) return "100%";

    return `${100 / partes}%`;
  };

  const itensArrayEmpresas = useSelector(
    (state: RootState) => (state.sistema?.listas ?? {})["empresas"] ?? []
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [itens, setItens] = useState<IResponseRelatorioPedido | null>(null);
  const [isLoadingLocal, setIsLoadingLocal] = useState<boolean>(false);
  const onImprimir = async () => {
    setIsLoadingLocal(true);
    const { data } = await apiCall({
      url: `relacao-pedidos?filtros=${JSON.stringify({
        ...currentItem,
        cliente_cidade_ufs: currentItem.cliente_cidade_ufs.map((i) => i.value),
      })}&page=${currentItem?.page}&apenasDados=sim`,
      method: "GET",
    });

    console.log(data);
    setIsLoadingLocal(false);
    setItens(data);
    onOpen();
  };

  const getFiltros = () => {
    const retorno: string[] = [];

    if (currentItem.data_inicio) {
      retorno.push(
        `Data início: ${formatData(currentItem.data_inicio, "dd/MM/yyyy")}`
      );
    }
    if (currentItem.data_fim) {
      retorno.push(
        `Data fim: ${formatData(currentItem.data_fim, "dd/MM/yyyy")}`
      );
    }
    if (currentItem.pedido_numero) {
      retorno.push(`N° Pedido: ${currentItem.pedido_numero}`);
    }
    if (currentItem.data_integracao) {
      retorno.push(
        `Data integração: ${parseData(
          currentItem.data_integracao,
          "dd/MM/yyyy"
        )}`
      );
    }
    if (currentItem.cliente_nome) {
      retorno.push(`Cliente: ${currentItem.cliente_nome}`);
    }
    if (currentItem.cliente_cidade_nome) {
      retorno.push(`Cidade: ${currentItem.cliente_cidade_nome}`);
    }
    if (currentItem.status) {
      retorno.push(`Status Pedido: ${currentItem.status}`);
    }
    if (currentItem.seller_id) {
      retorno.push(
        `Vendedor: ${
          sellers.find((i) => i.id == (currentItem.seller_id as any))?.name ??
          ""
        }`
      );
    }
    if (currentItem.cliente_cidade_ufs.length) {
      retorno.push(
        `Estados: ${(
          currentItem.cliente_cidade_ufs.map((j) => j.label) as string[]
        ).join(", ")}`
      );
    }
    // if (currentItem.empresas) {
    //   retorno.push(
    //     `Empresa: ${
    //       itensArrayEmpresas?.find((i) => i.value == currentItem.empresas)
    //         ?.label
    //     }`
    //   );
    // }

    if (retorno.length === 0) return ["Nenhum"];

    return retorno;
  };

  const parametros = useSelector(
    (state: RootState) => state.sistema?.parametros
  );

  const onFaturarPedido = async (pedido: Pedido) => {
    try {
      await apiCall({
        url: `pedidos-faturar/${pedido.id}`,
        method: "POST",
      });

      mensagemSucesso("Pedido faturado com sucesso");

      dispatch(
        crmActions.buscaRelatorioPedidosRequest({
          ...currentItem,
          page,
        })
      );
    } catch (err: any) {
      console.log(err);
      mensagemErro(formatError(err));
    }
  };

  const onSearch = () => {
    dispatch(
      crmActions.buscaRelatorioPedidosRequest({
        ...currentItem,
        page,
      })
    );
  };

  const onCancelarPedido = async (id: number) => {
    try {
      await apiCall({
        url: `pedidos-cancelar/${id}`,
        method: "POST",
      });

      setPedidoIdToCancel(-1);

      mensagemSucesso("Pedido cancelado com sucesso");

      dispatch(
        crmActions.buscaRelatorioPedidosRequest({
          ...currentItem,
          page,
        })
      );
    } catch (err: any) {
      console.log(err);
      mensagemErro(formatError(err));
    }
  };

  const [pedidoIdToCancel, setPedidoIdToCancel] = useState(-1);

  const onVoltaPedidoEmCarteira = async (pedido: Pedido) => {
    try {
      await apiCall({
        url: `pedidos-voltar-carteira/${pedido.id}`,
        method: "POST",
      });

      mensagemSucesso("Pedido voltado para em carteira");

      dispatch(
        crmActions.buscaRelatorioPedidosRequest({
          ...currentItem,
          page,
        })
      );
    } catch (err: any) {
      console.log(err);
      mensagemErro(formatError(err));
    }
  };

  // const valorInputPeso = useSelector((state: RootState) => state.crm.pesoMeta);

  // const [inputPeso, setInputPeso] = useState(0);

  // useEffect(() => {
  //   setInputPeso(valorInputPeso ?? 0);
  //   console.log("a");
  // }, []);

  // const onChangeMeta = (valor: number) => {
  //   setInputPeso(valor ?? 0);
  //   dispatch(crmActions.setPesoMeta(valor ?? 0));
  // };

  // const getQuantidadeFaltandoMeta = () => {
  //   return (inputPeso ?? 0) - (relatorioPedidos?.total_geral?.peso ?? 0);
  // };

  return (
    <Layout>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size={"full"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ListHeaderSecondary label={"Relatório"} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isOpen && itens && (
              <PDFViewer style={{ width: "100%", height: "80vh" }}>
                <RelacaoPedidoPdf
                  relatorio={itens}
                  parametros={parametros}
                  filtros={getFiltros()}
                  itensArrayEmpresas={itensArrayEmpresas}
                />
              </PDFViewer>
            )}
            <DefaultButton onClick={onClose} mt={2} width={"full"}>
              Fechar
            </DefaultButton>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Box width={"full"}>
        <ListHeader label="Relação de Pedidos">
          <DefaultButton ml={1} onClick={onImprimir} isLoading={isLoadingLocal}>
            Imprimir
          </DefaultButton>
        </ListHeader>
        <Loader isLoading={isLoading} />

        {currentItem && (
          <Formik
            enableReinitialize
            initialValues={currentItem}
            onSubmit={async (val, { setErrors }) => {
              setCurrentItem(val);

              setPage(1);

              dispatch(
                crmActions.buscaRelatorioPedidosRequest({
                  ...val,
                  page,
                })
              );
            }}
          >
            {({ values, submitForm }) => (
              <Form>
                <Flex width="full" wrap="wrap">
                  <InputField
                    label="Cliente"
                    name="cliente_nome"
                    width={getLarguraPagina(4)}
                  />
                  <InputField
                    label="Cidade"
                    name="cliente_cidade_nome"
                    width={getLarguraPagina(4)}
                  />
                  <InputField
                    type="date"
                    label="Data início"
                    name="data_inicio"
                    width={getLarguraPagina(4)}
                  />
                  <InputField
                    type="date"
                    label="Data fim"
                    name="data_fim"
                    width={getLarguraPagina(4)}
                  />
                  <InputField
                    label="N° Pedido"
                    name="pedido_numero"
                    width={getLarguraPagina(4)}
                  />
                  <InputSelect
                    label="Status"
                    name="status"
                    width={getLarguraPagina(4)}
                    noPlaceholer
                  >
                    <option value="Todos">Todos</option>
                    <option value="Faturados">Faturados</option>
                    <option value="Em Carteira">Em Carteira</option>
                    <option value="Cancelados">Cancelados</option>
                  </InputSelect>
                  {/* <InputSelectArray
                    label="Empresa Cliente"
                    arrayName="empresas"
                    name="empresas"
                    width={getLarguraPagina(4)}
                  /> */}
                  <InputField
                    name="data_integracao"
                    type="date"
                    label="Data integração"
                    width={getLarguraPagina(4)}
                  />
                  <SellerBobjeto
                    label="Vendedor"
                    name="seller_id"
                    width={getLarguraPagina(4)}
                    onSelectItem={(seller) =>
                      setSellers((old) => [
                        ...old,
                        {
                          ...seller,
                        },
                      ])
                    }
                  />
                  <ButtonGroup alignItems={"center"}>
                    <CamposPersonalizadosFiltros onFilter={submitForm} />
                    <DefaultButton type="submit" ml={1}>
                      Buscar Pedidos
                    </DefaultButton>
                  </ButtonGroup>
                  <span className="mt-2">
                    &nbsp; Total de {relatorioPedidos?.itens?.total ?? 0}{" "}
                    pedidos
                  </span>
                </Flex>
              </Form>
            )}
          </Formik>
        )}
      </Box>

      <Flex width={"full"} wrap={"wrap"}>
        {/* <Box width={isMobile ? '100%' : '20%'}>
          <FormLabel htmlFor={'metaemkg'}>Meta de venda em Kg</FormLabel>
          <Input
            bg={'white'}
            color={'black'}
            id="metaemkg"
            value={formatNumber(inputPeso, 0)}
            onChange={(evt) => onChangeMeta(parseInt(evt.target.value.replace('.', '').replace('.', '').replace('.', '').replace('.', '')))}
            borderRadius={2}
          />
        </Box> */}

        {/* {(inputPeso ?? 0) > 0 && <Box my={3} pt={8} pl={4}>
          {getQuantidadeFaltandoMeta() <= 0 ? <>
            Meta batida! Passou em {formatValor(getQuantidadeFaltandoMeta() * -1, 0)} Kg
          </> : <>
            Falta ainda vender {formatValor(getQuantidadeFaltandoMeta(), 0)} Kg
          </>}
        </Box>} */}
      </Flex>

      <ConfirmationModal
        show={pedidoIdToCancel !== -1}
        close={() => setPedidoIdToCancel(-1)}
        title={"Cancelar Pedido?"}
        content={"O financeiro e estoque serão apagados!"}
        footer={
          <React.Fragment>
            <SecondaryButton
              externalClass="w-auto"
              onClick={() => setPedidoIdToCancel(-1)}
            >
              Não cancelar
            </SecondaryButton>

            <DangerButton
              externalClass="ms-3"
              onClick={() => onCancelarPedido(pedidoIdToCancel)}
            >
              Cancelar pedido
            </DangerButton>
          </React.Fragment>
        }
      />

      <Grid
        mt={4}
        templateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(3, 1fr)"}
        gap={6}
      >
        <Box
          fontSize={"18px"}
          boxShadow={"base"}
          p={3}
          borderRadius={"25px"}
          bg={"white"}
          textAlign={"center"}
        >
          Total Geral ({relatorioPedidos?.total_geral?.quantidade ?? 0}){" "}
          <b>
            {" "}
            R${" "}
            {formatValor(
              relatorioPedidos?.total_geral?.valor ?? 0,
              getDecimalPlacesOfSystem()
            )}{" "}
          </b>{" "}
          {/* | <b> {formatValor(relatorioPedidos?.total_geral?.peso ?? 0, 0)} Kg  </b>*/}
        </Box>
        <Box
          fontSize={"18px"}
          boxShadow={"base"}
          p={3}
          borderRadius={"25px"}
          bg={"white"}
          textAlign={"center"}
        >
          Total Faturados ({relatorioPedidos?.total_faturado?.quantidade ?? 0}){" "}
          <b>
            {" "}
            R${" "}
            {formatValor(
              relatorioPedidos?.total_faturado?.valor ?? 0,
              getDecimalPlacesOfSystem()
            )}{" "}
          </b>{" "}
          {/* | <b> {formatValor(relatorioPedidos?.total_faturado?.peso ?? 0, 0)} Kg </b>*/}
        </Box>
        <Box
          fontSize={"18px"}
          boxShadow={"base"}
          p={3}
          borderRadius={"25px"}
          bg={"white"}
          textAlign={"center"}
        >
          Total Em carteira ({relatorioPedidos?.total_carteira?.quantidade ?? 0}
          ){" "}
          <b>
            {" "}
            R${" "}
            {formatValor(
              relatorioPedidos?.total_carteira?.valor ?? 0,
              getDecimalPlacesOfSystem()
            )}{" "}
          </b>{" "}
          {/* | <b> {formatValor(relatorioPedidos?.total_carteira?.peso ?? 0, 0)} Kg </b>*/}
        </Box>
      </Grid>

      <Grid
        mt={4}
        templateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(3, 1fr)"}
        gap={6}
      >
        {(relatorioPedidos?.itens?.data ?? []).map((i, index) => (
          <Card
            bg={
              i.cancelado == 1
                ? "red.100"
                : i.faturado == 1
                ? "green.100"
                : "white"
            }
            key={`${index}`}
          >
            <CardHeader>
              <Heading size="sm">
                {i.numero} - {formatData(i.data)}
              </Heading>
            </CardHeader>
            <CardBody>
              <Stack divider={<StackDivider />} spacing="4">
                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    Cliente
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    CPF/CNPJ: <b> {i?.pessoa?.cpf_cnpj} </b> <br />
                    Vendedor: <b>{i?.seller?.name ?? "Nenhum"} </b> <br />
                    Comissão:{" "}
                    <b>
                      {formatValor(
                        (i.itens ?? []).reduce((prev, curr) => {
                          return prev + parseFloat(curr.comission_value as any);
                        }, 0),
                        2
                      )}{" "}
                      (
                      {formatValor(
                        (i.itens ?? [])[0]?.comission_percentage ?? 0,
                        2
                      )}
                      %){" "}
                    </b>{" "}
                    <br />
                    {/* Empresa:{" "}
                    <b>
                      {" "}
                      {i?.pessoa?.array_empresas
                        ? itensArrayEmpresas.find(
                            (j) => j.value == (i?.pessoa?.array_empresas as any)
                          )?.label
                        : "Nenhuma"}
                    </b>{" "}
                    <br /> */}
                    Integração:{" "}
                    <b>
                      {i?.pessoa?.data_integracao
                        ? parseData(i?.pessoa?.data_integracao, "dd/MM/yyyy")
                        : "Nenhuma"}{" "}
                    </b>{" "}
                    <br />
                    Cliente: <b>{i?.pessoa?.nome}</b> <br />
                    Endereço:{" "}
                    <b>
                      {" "}
                      {i?.pessoa?.endereco}, n° {i?.pessoa?.numero}{" "}
                    </b>{" "}
                    <br />
                    Cidade:{" "}
                    <b>
                      {i?.pessoa?.cidade?.nome} - {i?.pessoa?.cidade?.uf}{" "}
                    </b>{" "}
                    <br />
                    Vendedor: <b>{i?.seller?.name ?? "Nenhum"}</b> <br />
                    <CamposPersonalizadosView
                      values={i.pessoa?.camposPersonalizados ?? []}
                    />
                  </Text>
                </Box>
                <Box>
                  <Heading size="xs" textTransform="uppercase">
                    Resumo
                    {/* - Tipo{" "}
                    <ShowArray
                      arrayName="tipo_pedido"
                      value={i.array_tipo_pedido * 1}
                    /> */}
                  </Heading>
                  <Text pt="2" fontSize="sm">
                    <CamposPersonalizadosView
                      values={i.camposPersonalizados ?? []}
                    />
                    <Table size={"sm"}>
                      <Thead>
                        <Th>Qtd.</Th>
                        <Th>Valor</Th>
                        <Th>Desc.</Th>
                        <Th>Total</Th>
                        {/* <Th>Peso</Th> */}
                      </Thead>
                      <Tbody>
                        {(i?.itens ?? []).map((item, index) => (
                          <>
                            <Tr border={"none"} key={index}>
                              <Td paddingY={"4px"} border={"none"} colSpan={3}>
                                <small>
                                  {" "}
                                  Produto:{" "}
                                  {item?.item ? item.item?.nome : item.produto}
                                </small>
                              </Td>
                            </Tr>
                            <Tr border={"none"}>
                              <Td
                                border={
                                  index == (i.itens ?? []).length - 1
                                    ? "none"
                                    : ""
                                }
                                paddingTop={"0"}
                              >
                                {formatValor(
                                  item.quantidade,
                                  getDecimalPlacesOfSystem()
                                )}
                              </Td>
                              <Td
                                border={
                                  index == (i.itens ?? []).length - 1
                                    ? "none"
                                    : ""
                                }
                                paddingTop={"0"}
                              >
                                {formatValor(
                                  item.valor_unitario,
                                  getDecimalPlacesOfSystem()
                                )}
                              </Td>
                              <Td
                                border={
                                  index == (i.itens ?? []).length - 1
                                    ? "none"
                                    : ""
                                }
                                paddingTop={"0"}
                              >
                                {formatValor(
                                  item.valor_desconto,
                                  getDecimalPlacesOfSystem()
                                )}
                              </Td>
                              <Td
                                border={
                                  index == (i.itens ?? []).length - 1
                                    ? "none"
                                    : ""
                                }
                                paddingTop={"0"}
                              >
                                {formatValor(
                                  item.valor_total,
                                  getDecimalPlacesOfSystem()
                                )}
                              </Td>
                              {/* <Td
                                border={
                                  index == (i.itens ?? []).length - 1
                                    ? "none"
                                    : ""
                                }
                                paddingTop={"0"}
                              >
                                {formatValor(
                                  (item.quantidade as any) *
                                    ((item.peso as any) ?? 0),
                                  getDecimalPlacesOfSystem()
                                )}
                              </Td> */}
                            </Tr>
                          </>
                        ))}
                      </Tbody>
                    </Table>
                  </Text>
                </Box>
                <Box>
                  {
                    <Heading size="xs" textTransform="uppercase">
                      Financeiro
                    </Heading>
                  }
                  <Text pt="2" fontSize="sm">
                    <Table size={"sm"}>
                      <Thead>
                        <Th>Código</Th>
                        <Th>Parcelas</Th>
                        <Th>Tipo</Th>
                        <Th>Total</Th>
                      </Thead>
                      <Tbody>
                        {(i?.financeiros ?? []).map((financeiro, index) => (
                          <Tr border={"none"} key={`${index}`}>
                            <Td paddingY={"4px"} border={"none"}>
                              <div className="flex gap-1">
                                <Link
                                  to={`/financeiro/receber/${financeiro.id}`}
                                  target="_blank"
                                >
                                  <FiEye className="text-blue-700 hover:text-blue-800 cursor-pointer rounded" />
                                </Link>
                                {financeiro.codigo}
                              </div>
                            </Td>
                            <Td paddingY={"4px"} border={"none"}>
                              {(financeiro.parcelas ?? [])?.length}
                            </Td>
                            <Td paddingY={"4px"} border={"none"}>
                              <ShowArray
                                arrayName="financeiro_tipo_pagamento"
                                value={financeiro.tipo_pagamento * 1}
                              />
                            </Td>
                            <Td paddingY={"4px"} border={"none"}>
                              {formatValor(
                                financeiro.valor_total,
                                getDecimalPlacesOfSystem()
                              )}
                              <Badge
                                colorScheme="blackAlpha"
                                className="text-gray-700 font-semibold text-xs whitespace-nowrap"
                              >
                                {formatValor(
                                  (financeiro?.parcelas ?? []).reduce(
                                    (prev, curr) =>
                                      prev + (curr.valor_quitado as any) * 1,
                                    0
                                  ),
                                  getDecimalPlacesOfSystem()
                                )}{" "}
                                PAGO
                              </Badge>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                    {(i?.financeiros ?? []).length === 0 && (
                      <div className="px-3 pt-1">Nenhum encontrado.</div>
                    )}
                  </Text>
                </Box>
                <Box>
                  {i.faturado != 1 && i.cancelado != 1 && (
                    <Button
                      borderRadius={"2px"}
                      m={"2px"}
                      size={"sm"}
                      onClick={() => onFaturarPedido(i)}
                      colorScheme="green"
                    >
                      Faturar
                    </Button>
                  )}
                  {i.faturado != 1 && i.cancelado != 1 && (
                    <Button
                      borderRadius={"2px"}
                      m={"2px"}
                      size={"sm"}
                      onClick={() => setPedidoIdToCancel(i.id as number)}
                      colorScheme="red"
                    >
                      Cancelar
                    </Button>
                  )}
                  {/*(i.cancelado == 1 || i.faturado == 1) && (
                    <Button
                      colorScheme="blackAlpha"
                      borderRadius={"2px"}
                      m={"2px"}
                      size={"sm"}
                      onClick={() => onVoltaPedidoEmCarteira(i)}
                      bg={"white"}
                      color={"black"}
                      _hover={{ backgroundColor: "#f3f3f3" }}
                    >
                      Voltar em carteira
                    </Button>
                  )*/}
                  <Flex width={"full"} />
                  <AgendamentosButton pessoa_id={i.pessoa_id as number} />
                  <ContatosButton pessoa_id={i.pessoa_id as number} />
                  <UltimosPedidosButton pessoa_id={i.pessoa_id as number} />
                  <DadosPessoaButton
                    pessoa_id={i.pessoa_id as number}
                    onDelete={onSearch}
                  />
                </Box>
              </Stack>
            </CardBody>
          </Card>
        ))}
      </Grid>

      {relatorioPedidos && (
        <Pagination info={relatorioPedidos.itens} paginate={setPage} />
      )}
    </Layout>
  );
};
