import { Box, Button, ButtonGroup, Text } from "@chakra-ui/react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { ApiPaginationResWithoutData } from "../types/apiPaginationRes";

interface IProps {
  info: ApiPaginationResWithoutData;
  paginate: (val: number) => void;
}

export const Pagination = ({ info, paginate }: IProps) => {
  const cor_primaria = useSelector((state: RootState) => state.sistema?.parametros?.cor_primaria ? state.sistema?.parametros?.cor_primaria : state.sistema.cor_primaria);

  const maxPagesToShow = 5; // Set the maximum number of pages to show

  const calculatePageRange = () => {
    const totalPageCount = info.last_page;
    const currentPage = info.current_page;

    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPageCount, startPage + maxPagesToShow - 1);

    // Adjust if the range is too small
    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
  };

  return (
    <>
      {info.last_page > 1 && (
        <Box display="flex" justifyContent="center" flexDirection={"column"} alignItems="center" mt={4}>
          <ButtonGroup isAttached variant="solid">
            {info.current_page > 1 && (
              <Button leftIcon={<FiArrowLeft />} onClick={() => paginate(info.current_page - 1)}>
                Anterior
              </Button>
            )}
            {calculatePageRange().map((pageNumber) => (
              <Button
                key={pageNumber}
                onClick={() => paginate(pageNumber)}
                bg={info.current_page === pageNumber ? cor_primaria : ""}
                color={info.current_page === pageNumber ? "white" : "black"}
                _hover={
                  info.current_page === pageNumber
                    ? {
                        backgroundColor: cor_primaria,
                        color: "white",
                      }
                    : {}
                }
              >
                {pageNumber}
              </Button>
            ))}
            {info.current_page < info.last_page && (
              <Button rightIcon={<FiArrowRight />} onClick={() => paginate(info.current_page + 1)}>
                Próxima
              </Button>
            )}
          </ButtonGroup>
          <Text>Total de {info?.total} registros</Text>
        </Box>
      )}
    </>
  );
};
