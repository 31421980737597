import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useIsAuth } from "../../hooks/useIsAuth"
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from 'react'
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import InputField from "../../sistema/components/InputField";
import { Form, Formik, useField, useFormikContext } from "formik";
import { Box, Button, ButtonGroup, CircularProgress, Divider, Flex, FormControl, FormLabel, Img, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import validateForm from "../../utils/validateForm";
import { ButtonHistorico } from "../../sistema/components/ButtonHistorico";
import { entradaesaidaActions } from "../recucer";
import InputNumber from "../../sistema/components/InputNumber";
import { CamposPersonalizadosForm } from "../../sistema/components/CamposPersonalizadosForm";
import { PessoaBobjeto } from "../../cadastros/components/PessoaBobjeto";
import { InputSelectArray } from "../../sistema/components/InputSelectArray";
import { Filhos } from "../../sistema/components/Filhos";
import { formatNumber } from "../../utils/formatNumber";
import { ProdutoBobjeto } from "../components/ProdutoBobjeto";
import { useFocus } from "../../hooks/useFocus";
import { mensagemErro, mensagemSucesso } from "../../utils/toasts";
import { apiCall } from "../../utils/apiCall";
import { formatValor } from "../../utils/formatValor";
import { NotaPromissoriaItem } from "../types/nota_promissoria_item";
import { NotaPromissoriaPagamento } from "../types/nota_promissoria_pagamento";
import { novaNotaPromissoriaItem } from "../data/nota_promissoria_item";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { novaNotaPromissoriaPagamento } from "../data/nota_promissoria_pagamento";
import { NotaPromissoria } from "../types/nota_promissoria";
import InputCheck from "../../sistema/components/InputCheck";
import ResponsiveTable, { TableHeaders } from "../../sistema/components/ResponsiveTable";
import { FiX } from "react-icons/fi";
import { ShowArray } from "../../sistema/components/ShowArray";
import { formatData } from "../../utils/data";
import { DeleteButton } from "../../sistema/components/DeleteButton";
import { formatError } from "../../utils/formatError";
import { round } from "../../utils/round";
import InputAssinatura from "../../sistema/components/InputAssinatura";
import { SellerBobjeto } from "../../cadastros/components/SellerBobjeto";

export const NotaPromissoriaEdit = () => {
  useIsAuth();

  const { id } = useParams();

  const nota_promissoria = useSelector((state: RootState) => state.entradaesaida.nota_promissoria)
  const isLoading = useSelector((state: RootState) => !!state.entradaesaida.isLoading)
  const isMobile = useSelector((state: RootState) => !!state.sistema.isMobile)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!id) return;

    dispatch(entradaesaidaActions.notaPromissoriaRequest(id))
  }, [dispatch, id])

  const w_75 = isMobile ? '100%' : '75%';
  const w_25 = isMobile ? '100%' : '25%';

  const forceSubmitForm = () => {
    document.getElementById('botao-salvar')?.click();
  }

  const NotaPromissoriaItens = () => {
    const [codBarras, setCodBarras] = useState('');
    const [isLoadingLocal, setIsLoadingLocal] = useState(false);
    const [focusQuantidade, setFocusQuantidade] = useState(false);
    const [inputRef, setInputFocus] = useFocus()

    const buscaProdutoPeloCodDeBarras = async () => {
      try {
        setIsLoadingLocal(true);
        const { data } = await apiCall({ url: `produto-pelo-cod-barras/${codBarras}`, method: 'GET' });

        setIsLoadingLocal(false);
        return data;
      } catch (err) {
        setIsLoadingLocal(false);
        console.log('err', err);
        return null;
      }
    }

    return (
      <Filhos
        name="itens"
        tituloForm="Itens da Nota"
        editTituloForm="Item"
        novoItemObject={novaNotaPromissoriaItem()}
        validation={{
          produto_id: 'required',
          quantidade: 'min_number|0',
          valor: 'min_number|0',
        }}
        headers={[
          {
            label: "Produto",
            wrapped: false,
            render: (item) => item.produto?.nome,
          },
          {
            label: "Quantidade",
            wrapped: false,
            render: (item) => formatNumber(item.quantidade ?? '0', 2),
          },
          {
            label: "Preço Unitário",
            wrapped: false,
            render: (item) => formatNumber(item.valor ?? '0', 2),
          },
          {
            label: "Total",
            wrapped: false,
            render: (item) => formatNumber(item.quantidade * item.valor, 2),
          },
        ]}
        canSave={(value) => {
          const itemExiste = (nota_promissoria?.itens ?? []).find((i) => i.id != value.id && i.produto_id == value.produto_id);

          if (itemExiste) {
            mensagemErro("Este produto já está nesta nota! Apenas altere a quantidade.");
            return false;
          }

          return true;
        }}
        abriuModal={() => (setInputFocus as any)()}
        antesAbrirModal={() => { setFocusQuantidade(false) }}
        afterDelete={() => forceSubmitForm()}
        afterSave={() => forceSubmitForm()}
        form={(values, setFieldValue) =>
          <Box width={"full"}>
            {!values?.produto_id && <>
              <FormControl width={'full'} p={1}>
                <FormLabel mb={0} mt={2}>Buscar item por código de barras</FormLabel>
                <Input
                  bg={'white'}
                  color={'black'}
                  placeholder={'Buscar item por código de barras'}
                  borderRadius={2}
                  value={codBarras}
                  ref={inputRef}
                  onChange={(e) => setCodBarras(e.target.value ?? '')}
                  onKeyDown={async (e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      const produto = await buscaProdutoPeloCodDeBarras();

                      if (!produto) {
                        mensagemErro("Produto não encontrado pelo código de barras. Pesquise o produto pelo campo de produto.");
                        setCodBarras('');
                        return;
                      }

                      console.log('produto encontrado ', produto);

                      setFieldValue('produto_id', produto.id);
                      setCodBarras('');
                      setFocusQuantidade(true);
                    }
                  }}
                />
              </FormControl>

              <Button onClick={async () => {
                const produto = await buscaProdutoPeloCodDeBarras();

                if (!produto) {
                  mensagemErro("Produto não encontrado pelo código de barras. Pesquise o produto pelo campo de produto.");
                  setCodBarras('');
                  return;
                }

                console.log('produto encontrado ', produto);

                setFieldValue('produto_id', produto.id);
                setCodBarras('');
                setFocusQuantidade(true);
              }} colorScheme="green" type="button" size={"sm"} width={'full'}>
                {isLoadingLocal ? <CircularProgress isIndeterminate size={"14px"} /> : 'Buscar (Clique ou tecle enter)'}
              </Button>

              <Divider />
            </>}

            <Flex width={"full"} wrap={"wrap"}>
              <ProdutoBobjeto onSelectItem={(produto) => {
                if (produto) {
                  if (values.valor == 0) {
                    setFieldValue('valor', produto.preco)
                  }
                  return;
                }
                setFieldValue('valor', 0)
              }} width="full" />
              <InputNumber focus={focusQuantidade} name="quantidade" decimalPlaces={0} label="Quantidade" width={"full"} />
              <InputNumber name="valor" decimalPlaces={2} label="Preço" width={"full"} />
            </Flex>
          </Box>
        }
      />
    )
  }

  const ModalRegistrarPagamento = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [, { value: pagamentos }, { setValue: setPagamentos }] = useField('pagamentos');
    const formik = useFormikContext();

    const values = formik.values as NotaPromissoria;

    const totalEmAberto = round((formik.values as NotaPromissoria).itens.reduce((acumulador, atual: NotaPromissoriaItem) => {
      return acumulador += parseFloat(atual.quantidade) * parseFloat(atual.valor);
    }, 0) - (formik.values as NotaPromissoria).pagamentos.reduce((acumulador, atual: NotaPromissoriaPagamento) => {
      return acumulador += parseFloat(atual.valor);
    }, 0), 2)

    return (
      <>
        {totalEmAberto > 0 && <Button my={"auto"} ml={2} colorScheme="green" onClick={onOpen}> Registrar Pagamento </Button>}

        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"2xl"}>
          <ModalOverlay />
          <ModalContent top={-10}>
            <ModalHeader>
              <ListHeaderSecondary label={'Registrar Pagamento'} />
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {isOpen && <Formik
                enableReinitialize
                initialValues={{ ...novaNotaPromissoriaPagamento(), valor: totalEmAberto as any }}
                onSubmit={(val, { setErrors }) => {
                  const validation = validateForm({
                    valor: 'min_number|0',
                  }, val)
                  if (Object.keys(validation).length > 0) {
                    setErrors(validation)
                    return;
                  }

                  const pagamentosConst: NotaPromissoriaPagamento[] = JSON.parse(JSON.stringify(pagamentos));

                  const totalPago = pagamentosConst.reduce((acumulador, atual: NotaPromissoriaPagamento) => {
                    return acumulador += parseFloat(atual.valor);
                  }, 0);

                  if ((totalPago + val.valor) > parseFloat(values?.valor_total ?? '0')) {
                    mensagemErro("O valor a pagar é maior que o valor total da nota.");
                    return;
                  }

                  pagamentosConst.push({
                    ...val,
                    changed: true,
                  });

                  setPagamentos(pagamentosConst);

                  onClose();

                  formik.submitForm();
                }}
              >
                {({ values }) => (
                  <Form>
                    <Flex width="full" wrap="wrap">
                      <InputNumber name="valor" width={"full"} label="Valor" />
                      <InputSelectArray width={"50%"} label="Tipo de Pagamento" arrayName="financeiro_tipo_pagamento" name="tipo_pagamento" />
                      <InputField type="datetime-local" label="Data/Hora" width={"50%"} name="data_hora" />~

                      <InputField label="Observações" name="observacoes" width={"full"} textarea rows={3} />

                      <InputCheck name="_gera_financeiro" label="Gera financeiro a receber" width={"full"} />

                      <DefaultButton type="submit" mt={2} width={"full"}>
                        Registrar Pagamento
                      </DefaultButton>
                    </Flex>
                  </Form>)}
              </Formik>}
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    )
  }

  const ModalPagamentosRegistrados = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const formik = useFormikContext();

    const nota = formik.values as NotaPromissoria;

    const [isLoadingDeletandoPagamento, setIsLoadingDeletandoPagamento] = useState(false);

    const onDeletePagamento = async (pagamento: NotaPromissoriaPagamento) => {
      try {
        setIsLoadingDeletandoPagamento(true);
        await apiCall({ url: `notas-promissorias-pagamentos/${pagamento.id}`, method: 'DELETE' });
        mensagemSucesso("Pagamento deletado com sucesso.");
        setIsLoadingDeletandoPagamento(false);
        dispatch(entradaesaidaActions.notaPromissoriaRequest(id as string));
      } catch (err: any) {
        setIsLoadingDeletandoPagamento(false);
        mensagemErro(formatError(err));
      }
    }

    const navigate = useNavigate();

    return (
      <>
        {(nota.pagamentos ?? []).length > 0 && <Button my={"auto"} ml={2} colorScheme="blackAlpha" onClick={onOpen}> Pagamentos Feitos ({nota_promissoria?.pagamentos?.length ?? 0}) </Button>}

        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"4xl"}>
          <ModalOverlay />
          <ModalContent top={-10}>
            <ModalHeader>
              <ListHeaderSecondary label={'Pagamentos Feitos'} />
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {(nota.pagamentos ?? []).length > 0 && <ResponsiveTable isSmall headers={[
                {
                  label: "Valor",
                  wrapped: true,
                  render: (item) => `R$ ${formatValor(item.valor)}`,
                },
                {
                  label: "Tipo Pagamento",
                  wrapped: false,
                  render: (item) => <ShowArray arrayName="financeiro_tipo_pagamento" value={item.tipo_pagamento * 1} />
                },
                {
                  label: "Observações",
                  wrapped: true,
                  render: (item) => item.observacoes,
                },
                {
                  label: "Data/Hora",
                  wrapped: true,
                  render: (item) => formatData(item.data_hora, 'dd/MM/yyy HH:mm')
                },
                {
                  label: "Ações",
                  wrapped: true,
                  render: (item) => <ButtonGroup>
                    {!!item.financeiro ? <Button onClick={() => navigate(`/financeiro/receber/${item.financeiro?.id}`)} size={"sm"} colorScheme="green">Financeiro</Button> : ''}
                    <DeleteButton isLoading={isLoadingDeletandoPagamento} confirmou={() => onDeletePagamento(item)} />
                  </ButtonGroup>
                },
              ] as TableHeaders<NotaPromissoriaPagamento>[]} data={nota?.pagamentos ?? []} />}
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    )
  }

  return (
    <Layout>
      <ListHeader label={`Nota Promissória ${nota_promissoria?.numero}`}>
        <DefaultButton ml={4} to={`/notas-promissorias`}> Voltar </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      {nota_promissoria && <Formik
        enableReinitialize
        initialValues={nota_promissoria}
        onSubmit={(val, { setErrors }) => {
          const validation = validateForm({ pessoa_id: 'required', status: 'required' }, val)
          if (Object.keys(validation).length > 0) {
            setErrors(validation)
            return;
          }

          dispatch(entradaesaidaActions.saveNotaPromissoriaRequest({
            ...val,
            changed: true,
          }));
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Flex width="full" wrap="wrap">
              <PessoaBobjeto name="pessoa_id" label="Cliente" width={w_75} />
              <InputSelectArray
                width={w_25}
                name="status"
                label="Status"
                arrayName="nota_promissoria_status"
              />

              <SellerBobjeto width={'full'} />

              <InputField textarea rows={3} label="Observações" name="observacoes" width={"full"} />

              <CamposPersonalizadosForm par="nota_promissoria" idp={nota_promissoria?.id as number} />

              <Flex gap={2} width={"full"} wrap={"wrap"}>
                <Flex bg={"white"} rounded={"15px"} boxShadow={"base"} fontSize={"18px"} direction={"column"} p={4} alignItems={"center"} textAlign={"center"}>
                  <b style={{ marginBottom: '10px' }}> TOTAL GERAL </b>
                  R$ {formatValor(
                    values.itens.reduce((acumulador, atual: NotaPromissoriaItem) => {
                      return acumulador += parseFloat(atual.quantidade) * parseFloat(atual.valor);
                    }, 0)
                    , 2)}
                </Flex>
                <Flex bg={"white"} rounded={"15px"} boxShadow={"base"} fontSize={"18px"} direction={"column"} p={4} alignItems={"center"} textAlign={"center"}>
                  <b style={{ marginBottom: '10px' }}> TOTAL PAGO </b>
                  R$ {formatValor(
                    values.pagamentos.reduce((acumulador, atual: NotaPromissoriaPagamento) => {
                      return acumulador += parseFloat(atual.valor);
                    }, 0)
                    , 2)}
                </Flex>
                <Flex bg={"white"} rounded={"15px"} boxShadow={"base"} fontSize={"18px"} direction={"column"} p={4} alignItems={"center"} textAlign={"center"}>
                  <b style={{ marginBottom: '10px' }}> TOTAL EM ABERTO </b>
                  R$ {formatValor(
                    values.itens.reduce((acumulador, atual: NotaPromissoriaItem) => {
                      return acumulador += parseFloat(atual.quantidade) * parseFloat(atual.valor);
                    }, 0) - values.pagamentos.reduce((acumulador, atual: NotaPromissoriaPagamento) => {
                      return acumulador += parseFloat(atual.valor);
                    }, 0)
                    , 2)}
                </Flex>

                <ModalRegistrarPagamento />
                <ModalPagamentosRegistrados />
              </Flex>

              <NotaPromissoriaItens />

              <Flex wrap={"wrap"} maxW={"60vh"} mt={2} mx={"auto"} >
                {!(values.assinatura && values.assinatura.includes('http')) ?
                  <InputAssinatura width={isMobile ? '340' : '500'} name="assinatura" label="Assinatura do Cliente" />
                  :
                  <Box bg={"white"} p={4} borderRadius={"10px"} textAlign={"center"} position={"relative"}>
                    <Button onClick={() => setFieldValue('assinatura', '')} size={"xs"} colorScheme="red" position={"absolute"} top={1} title="Remover imagem" right={1}>
                      <FiX />
                    </Button>
                    Assinatura do Cliente <br />
                    <Img src={values.assinatura} />
                  </Box>
                }
              </Flex>

              <Flex width={"full"} />
              <Box
                bg={"white"}
                mt={2}
                width={"full"}
                position="sticky"
                bottom="10px"
                zIndex="10"
                p={2}
              >
                <DefaultButton type="submit" id="botao-salvar">Salvar Nota Promissória</DefaultButton>
                {values.id && <ButtonHistorico par="nota_promissoria" idpar={values.id} ml={2} />}
              </Box>
            </Flex>
          </Form>
        )}
      </Formik>}
    </Layout>
  )
}