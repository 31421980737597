import { Button, ButtonProps, Modal, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { ListHeaderSecondary } from './ListHeaderSecondary';

type IProps = ButtonProps & {
  children: ReactNode,
  confirm: () => void;
  colorSheme?: string;
  size?: string;
}

export const ConfirmButton = ({ children, confirm, colorSheme = 'red', size = "sm", ...props }: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button size={size} colorScheme={colorSheme} onClick={onOpen} {...props}>
        {children}
      </Button>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"sm"}>
        <ModalOverlay />
        <ModalContent top={-10}>
          <ModalHeader>
            <ListHeaderSecondary label={'Tem certeza?'} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalFooter>
            <Button ml={2} mt={2} colorScheme='gray' onClick={onClose}>Cancelar</Button>
            <Button onClick={() => { confirm(); onClose(); }} ml={2} mt={2} colorScheme={colorSheme}>Sim!</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


    </>
  )
}