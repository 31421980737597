import { useIsAuth } from "../../hooks/useIsAuth"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { FiTrash } from "react-icons/fi";
import { cadastroActions } from "../reducer";
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import { ListInput } from "../../sistema/components/ListInput";
import ResponsiveTable, { TableHeaders } from "../../sistema/components/ResponsiveTable";
import { Pagination } from "../../sistema/components/Pagination";
import { ConfirmButton } from "../../sistema/components/ConfirmButton";
import { Cidade } from "../types/cidade";

export const CidadeList = () => {
  useIsAuth();

  const cidades = useSelector((state: RootState) => state.cadastro.cidades);
  const isLoading = useSelector((state: RootState) => !!state.cadastro.isLoading);
  const success = useSelector((state: RootState) => !!state.cadastro.success);
  const dispatch = useDispatch()
  const [page, setPage] = useState(1);
  const [termo, setTermo] = useState("");

  useEffect(() => {
    dispatch(cadastroActions.cidadesRequest({
      page,
      termo,
    }))
  }, [dispatch, page, termo])

  useEffect(() => {
    if (!success) {
      return;
    }
    dispatch(cadastroActions.cidadesRequest({
      page,
      termo,
    }))
  }, [dispatch, success, page, termo])

  const headers: TableHeaders<Cidade>[] = [
    {
      label: "Nome",
      wrapped: true,
      render: (item) => item.nome,
    },
    {
      label: "UF",
      wrapped: true,
      render: (item) => item.uf,
    },
    {
      label: "Ações",
      wrapped: true,
      render: (item, index) => <>{<ConfirmButton confirm={() => onDeleteItem(item.id as number)}><FiTrash /></ConfirmButton>}</>,
      notClicable: true,
    },
  ];

  const onDeleteItem = (id: number) => {
    dispatch(cadastroActions.deleteCidadeRequest(id));
  }

  const navigate = useNavigate();

  return (
    <Layout>
      <ListHeader label={`Cidades`}>
        <DefaultButton ml={4} to={`/cidades/novo`}> Novo </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      <ListInput label="Filtrar por nome, cpf/cnpj, email, cidade ou uf" onSubmit={setTermo} />

      {cidades && <ResponsiveTable isSmall onClick={(item: any) => navigate(`/cidades/${item.item.id}`)} headers={headers} data={cidades.data} />}

      {cidades && <Pagination info={cidades} paginate={setPage} />}
    </Layout>
  )
}