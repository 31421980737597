import { PayloadAction } from "@reduxjs/toolkit";
import { put, all, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { call } from "redux-saga/effects";
import { apiCall } from "../utils/apiCall";
import { formatError } from "../utils/formatError";
import { ListPayload } from "../sistema/types/listPayload";
import {
  IResponseRelatorioContatosRealizados,
  IResponseRelatorioPedido,
  crmActions,
} from "./reducer";
import { novaFicha } from "./data/ficha";
import { Ficha } from "./types/ficha";
import {
  IFichaCrmCriarFiltros,
  IPessoaFichaCrmCriar,
} from "./components/FichaCrmCriar";
import { Rota } from "../cadastros/types/rota";
import { Cidade } from "../cadastros/types/cidade";
import { IFiltros } from "./components/FichaCrmGerenciar";
import { ApiPaginationRes } from "../sistema/types/apiPaginationRes";
import { FichaPessoa } from "./types/ficha_pessoa";
import { IFiltrosAgendamentos } from "./views/Agendamentos";
import { Agendamento } from "./types/agendamento";
import {
  IFiltrosRelatorioCliente,
  IPessoaRelatorioCliente,
} from "./views/RelatorioRelacaoCliente";
import { IFiltrosRelatorioPedido } from "./views/RelatorioRelacaoPedido";
import { IFiltrosRelatorioContatosRealizados } from "./views/RelatorioRelacaoContatosRealizados";

function* fichasRequestWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<Ficha[]> = yield call(apiCall, {
      url: `fichas`,
      method: "get",
      data: payload,
    });
    yield put(crmActions.fichasRequestSuccess(res.data));
  } catch (error: any) {
    yield put(crmActions.error(formatError(error)));
  }
}

export interface IResponseFicha {
  cidades: { label: string; value: number }[];
  itens: ApiPaginationRes<FichaPessoa[]>;
  rotas: { label: string; value: number }[];
  ufs: { label: string; value: string }[];
  quantidade: {
    "0": number;
    "1": number;
    "2": number;
    "3": number;
    "4": number;
    "5": number;
    "6": number;
    "7": number;
  };
  total_geral: number;
}

function* fichaRequestWorker({ payload }: PayloadAction<IFiltros>) {
  try {
    const res: AxiosResponse<IResponseFicha> = yield call(apiCall, {
      url: `fichas-page?filtros=${JSON.stringify({
        ...payload,
        cidades: payload.cidades.map((i) => i.value),
        rotas: payload.rotas.map((i) => i.value),
        ufs: payload.ufs.map((i) => i.value),
        status: payload.status.map((i) => i.value),
      })}&page=${payload.page}`,
      method: "get",
    });
    yield put(crmActions.fichaRequestSuccess(res.data));
  } catch (error: any) {
    yield put(crmActions.error(formatError(error)));
  }
}

function* buscaFichaPessoasRequestWorker({
  payload,
}: PayloadAction<IFichaCrmCriarFiltros>) {
  try {
    const res: AxiosResponse<IPessoaFichaCrmCriar[]> = yield call(apiCall, {
      url: `fichas-busca-cliente?filtros=${JSON.stringify(payload)}`,
      method: "get",
    });
    yield put(crmActions.buscaFichaPessoasRequestSuccess(res.data));
  } catch (error: any) {
    yield put(crmActions.error(formatError(error)));
  }
}

export type IFichaCriarSelectInputs = {
  rotas: Rota[];
  cidades: Cidade[];
  fichas?: Ficha[];
};
function* buscaFichaSelectInputsRequestWorker() {
  try {
    const res: AxiosResponse<IFichaCriarSelectInputs> = yield call(apiCall, {
      url: `fichas-select-input`,
      method: "get",
    });
    yield put(crmActions.buscaFichaSelectInputsRequestSuccess(res.data));
  } catch (error: any) {
    yield put(crmActions.error(formatError(error)));
  }
}

export interface IResponseAgendamento {
  cidades: { label: string; value: number }[];
  itens: ApiPaginationRes<Agendamento[]>;
  rotas: { label: string; value: number }[];
  ufs: { label: string; value: string }[];
}

function* agendamentosRequestWorker({
  payload,
}: PayloadAction<IFiltrosAgendamentos>) {
  try {
    const res: AxiosResponse<IResponseAgendamento> = yield call(apiCall, {
      url: `agendamentos-page?filtros=${JSON.stringify({
        ...payload,
        cidades: payload.cidades.map((i) => i.value),
        rotas: payload.rotas.map((i) => i.value),
        ufs: payload.ufs.map((i) => i.value),
        status: payload.status.map((i) => i.value),
      })}&page=${payload.page}`,
      method: "get",
    });
    yield put(crmActions.agendamentosRequestSuccess(res.data));
  } catch (error: any) {
    yield put(crmActions.error(formatError(error)));
  }
}

function* buscaRelatorioClientesRequestWorker({
  payload,
}: PayloadAction<IFiltrosRelatorioCliente>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<IPessoaRelatorioCliente[]>> =
      yield call(apiCall, {
        url: `relacao-clientes?filtros=${JSON.stringify({
          ...payload,
          fichas: payload.fichas.map((i) => i.value),
          status_fichas: payload.status_fichas.map((i) => i.value),
          cliente_cidade_ufs: payload.cliente_cidade_ufs.map((i) => i.value),
          status: payload.status.map((i) => i.value),
        })}&page=${payload.page}`,
        method: "get",
      });
    yield put(crmActions.buscaRelatorioClientesRequestSuccess(res.data));
  } catch (error: any) {
    yield put(crmActions.error(formatError(error)));
  }
}

function* buscaRelatorioPedidosRequestWorker({
  payload,
}: PayloadAction<IFiltrosRelatorioPedido>) {
  try {
    const res: AxiosResponse<IResponseRelatorioPedido> = yield call(apiCall, {
      url: `relacao-pedidos?filtros=${JSON.stringify({
        ...payload,
        cliente_cidade_ufs: payload.cliente_cidade_ufs.map((i) => i.value),
      })}&page=${payload.page}`,
      method: "get",
    });
    yield put(crmActions.buscaRelatorioPedidosRequestSuccess(res.data));
  } catch (error: any) {
    yield put(crmActions.error(formatError(error)));
  }
}

function* buscaRelatorioContatosRealizadosRequestWorker({
  payload,
}: PayloadAction<IFiltrosRelatorioContatosRealizados>) {
  try {
    const res: AxiosResponse<IResponseRelatorioContatosRealizados> = yield call(
      apiCall,
      {
        url: `relacao-contatos-realizados?filtros=${JSON.stringify({
          ...payload,
          cliente_cidade_ufs: payload.cliente_cidade_ufs.map((i) => i.value),
          forma_contato: payload.forma_contato.map((i) => i.value),
          motivo_nao_venda: payload.motivo_nao_venda.map((i) => i.value),
        })}&page=${payload.page}`,
        method: "get",
      }
    );
    yield put(
      crmActions.buscaRelatorioContatosRealizadosRequestSuccess(res.data)
    );
  } catch (error: any) {
    yield put(crmActions.error(formatError(error)));
  }
}

function* agendamentosANotificarRequestWorker() {
  try {
    const res: AxiosResponse<Agendamento[]> = yield call(apiCall, {
      url: `agendamentos-despertar`,
      method: "get",
    });
    yield put(crmActions.agendamentosANotificarRequestSuccess(res.data));
  } catch (error: any) {
    console.warn("agendamentosANotificarRequestWorker", formatError(error));
    // yield put(crmActions.error(formatError(error)));
  }
}

export function* crmSaga() {
  yield all([
    takeLatest("crm/fichasRequest", fichasRequestWorker),
    takeLatest("crm/fichaRequest", fichaRequestWorker),
    takeLatest("crm/buscaFichaPessoasRequest", buscaFichaPessoasRequestWorker),
    takeLatest(
      "crm/buscaFichaSelectInputsRequest",
      buscaFichaSelectInputsRequestWorker
    ),
    takeLatest("crm/agendamentosRequest", agendamentosRequestWorker),
    takeLatest(
      "crm/buscaRelatorioClientesRequest",
      buscaRelatorioClientesRequestWorker
    ),
    takeLatest(
      "crm/buscaRelatorioPedidosRequest",
      buscaRelatorioPedidosRequestWorker
    ),
    takeLatest(
      "crm/buscaRelatorioContatosRealizadosRequest",
      buscaRelatorioContatosRealizadosRequestWorker
    ),
    takeLatest(
      "crm/agendamentosANotificarRequest",
      agendamentosANotificarRequestWorker
    ),
  ]);
}
