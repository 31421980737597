import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { ApiPaginationRes } from "../sistema/types/apiPaginationRes";
import { mensagemErro, mensagemSucesso } from "../utils/toasts";
import { CashRegister } from "./types/cash_register";
import { ListPayload } from "../sistema/types/listPayload";

type TInitialState = {
  error?: string;
  success?: string;
  isLoading?: boolean;
  cashRegisters?: ApiPaginationRes<CashRegister[]>;
  cashRegister?: CashRegister | null;
};

const initialState: TInitialState = {};

const reducer = createSlice({
  name: "cashRegister",
  initialState,
  reducers: {
    error(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
      state.success = "";
      mensagemErro(payload);
    },
    success(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = "";
      state.success = payload;
      if (payload) {
        mensagemSucesso(payload);
      }
    },
    setLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload;
    },

    cashRegistersRequest(state, _: PayloadAction<ListPayload>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    cashRegistersRequestSuccess(
      state,
      { payload }: PayloadAction<{data: ApiPaginationRes<CashRegister[]>; page: number}>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      if (payload.page == 1 || !state.cashRegisters) {
        state.cashRegisters = payload.data;
        state.cashRegister = (state.cashRegisters?.data ?? [])[0];
        return;
      }
      state.cashRegisters = {
        ...state.cashRegisters,
        data: [
          ...state.cashRegisters.data,
          ...payload.data.data,
        ],
      };
    },
    cashRegisterRequest(state, _: PayloadAction<null | string | number>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    cashRegisterRequestSuccess(
      state,
      { payload }: PayloadAction<null | CashRegister>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.cashRegister = payload;
    },
  },
});

export const cashRegisterActions = reducer.actions;

export default reducer;
