import { SistemaAssinatura } from "../types/sistema_assinatura";

export const novaSistemaAssinatura = (): SistemaAssinatura => {
  return {
    boleto: "",
    pago: 0,
    data_vencimento: "",
    competencia_mes: "",
    competencia_ano: (new Date()).getFullYear().toString(),
    empresa_destino_id: "",
    empresa_id: "",
    valor: 0,
  };
};
