export const formatError = (error: any) => {
  console.log(error);

  if (error?.response?.data?.error) {
    return error.response.data.error;
  }

  return error?.response?.data?.message
    ? error.response?.data?.message
    : JSON.stringify(error);
};
