export const arrayMotivoNaoCompra = () => {
  return [
    {
      name: "Não Informado",
    },
    {
      name: "Comprou",
    },
    {
      name: "Não Atendeu",
    },
    {
      name: "Não quer comprar",
    },
    {
      name: "Cliente Estocado",
    },
    {
      name: "Preço",
    },
    {
      name: "Atraso na Entrega",
    },
    {
      name: "Problema com Vendedor",
    },
    {
      name: "Comprador Indisponível",
    },
    {
      name: "Comprou em Atacado",
    },
    {
      name: "Não tem interesse",
    },
    {
      name: "Produto com giro lento",
    },
    {
      name: "Cliente com vendas fracas",
    },
  ];
};
