import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { sistemaActions } from "../recucer";
import { useIsAuth } from "../../hooks/useIsAuth";

export const Logout = () => {
  useIsAuth();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sistemaActions.logout())
  }, [dispatch])

  return (
    <div></div>
  )
}
