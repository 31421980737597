import { BobjetoPadrao } from "../../sistema/components/BobjetoPadrao"
import { Cidade } from "../types/cidade"

interface IProps {
  width?: string;
  name?: string;
  label?: string;
  onSelectItem?: (item: Cidade) => void;
}

export const CidadeBobjeto = ({ width = '100%', name = 'cidade_id', label = 'Cidade', onSelectItem }: IProps) => {
  const onSelect = (item: Cidade) => {
    if (typeof onSelectItem !== 'function') return;

    onSelectItem(item);
  }

  return (
    <BobjetoPadrao onSelectItem={onSelect} endpoint="cidades" label={label} name={name} visualizacao={(item: Cidade) => (<>{item?.nome} - {item?.uf}</>)} width={width} />
  )
}