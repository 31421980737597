import { FiTrash } from "react-icons/fi";
import { DefaultButton } from "../../../sistema/components/DefaultButton";
import { ListHeader } from "../../../sistema/components/ListHeader";
import { useDispatch } from "react-redux";
import { pdvActions } from "../../reducer";
import { novoPedido } from "../../../entradaesaida/data/pedido";
import { Button } from "@chakra-ui/react";

export const Header = () => {
  const dispatch = useDispatch();

  const resetCart = () => {
    dispatch(pdvActions.updateOrder(novoPedido()));
    window.location.reload();
  };

  return (
    <ListHeader label="Carrinho">
      <Button
        colorScheme="red"
        className="ml-3 flex items-center gap-1"
        onClick={resetCart}
        type="button"
      >
        Limpar Carrinho <FiTrash />
      </Button>
    </ListHeader>
  );
};
