import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { RootState } from "../../app/mainReducer";
import { sistemaActions } from "../recucer";

export type InputSelectProps = {
  value: number;
  arrayName: string;
};

export const ShowArray: React.FC<InputSelectProps> = ({
  arrayName,
  value,
}) => {
  const itens = useSelector((state: RootState) => (state.sistema?.listas ?? {})[arrayName] ?? []);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!itens) {
      dispatch(sistemaActions.getListaRequest(arrayName));
    }
  }, [dispatch, arrayName, itens]);

  const valor = () => {
    if ((itens ?? []).length === 0) return '';

    return itens.find((i) => i.value == value)?.label ?? '';
  }

  return (
    <>
      {valor()}
    </>
  )
}