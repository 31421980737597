import { useIsAuth } from "../../hooks/useIsAuth"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { FiTrash } from "react-icons/fi";
import { cadastroActions } from "../reducer";
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import { ListInput } from "../../sistema/components/ListInput";
import ResponsiveTable, { TableHeaders } from "../../sistema/components/ResponsiveTable";
import { Pagination } from "../../sistema/components/Pagination";
import { Pessoa } from "../types/pessoa";
import { ConfirmButton } from "../../sistema/components/ConfirmButton";
import { APIURL } from "../../utils/apiCall";

export const TabelaPrecoList = () => {
  useIsAuth();

  const tabelas = useSelector((state: RootState) => state.cadastro.tabelas);
  const isLoading = useSelector((state: RootState) => !!state.cadastro.isLoading);
  const success = useSelector((state: RootState) => !!state.cadastro.success);
  const dispatch = useDispatch()
  const [page, setPage] = useState(1);
  const [termo, setTermo] = useState("");

  useEffect(() => {
    dispatch(cadastroActions.tabelasRequest({
      page,
      termo,
    }))
  }, [dispatch, page, termo])

  useEffect(() => {
    if (!success) {
      return;
    }
    dispatch(cadastroActions.tabelasRequest({
      page,
      termo,
    }))
  }, [dispatch, success, page, termo])

  const onClickImprimir = (id: number) => {
    window.open(`${APIURL}/tabelas-imprimir/${id}`, '_blank')
  }

  const headers: TableHeaders<Pessoa>[] = [
    {
      label: "Nome",
      wrapped: true,
      render: (item) => item.nome,
    },
    {
      label: "Ações",
      wrapped: true,
      render: (item, index) => <><DefaultButton size={"sm"} mr={1} onClick={() => onClickImprimir(item.id as number)}> Imprimir </DefaultButton><ConfirmButton confirm={() => onDeleteItem(item.id as number)}><FiTrash /></ConfirmButton></>,
      notClicable: true,
    },
  ];

  const onDeleteItem = (id: number) => {
    dispatch(cadastroActions.deleteTabelaRequest(id));
  }

  const navigate = useNavigate();

  return (
    <Layout>
      <ListHeader label={"Tabelas de Preço"}>
        <DefaultButton ml={4} to={`/tabelas/novo`}> Nova </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      <ListInput label="Filtre por nome" onSubmit={setTermo} />

      {tabelas && <ResponsiveTable isSmall onClick={(item: any) => navigate(`/tabelas/${item.item.id}`)} headers={headers} data={tabelas.data} />}

      {tabelas && <Pagination info={tabelas} paginate={setPage} />}
    </Layout>
  )
}