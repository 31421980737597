import { useIsAuth } from "../../hooks/useIsAuth";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import React, { useState, useEffect } from "react";
import { entradaesaidaActions } from "../recucer";
import ResponsiveTable, {
  TableHeaders,
} from "../../sistema/components/ResponsiveTable";
import { ConfirmButton } from "../../sistema/components/ConfirmButton";
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import Loader from "../../sistema/components/Loader";
import { ListInput } from "../../sistema/components/ListInput";
import { Pagination } from "../../sistema/components/Pagination";
import { Badge, ButtonGroup } from "@chakra-ui/react";
import { Stock, StockType } from "../types/stock";
import { formatValor } from "../../utils/formatValor";
import { getDecimalPlacesOfSystem } from "../../sistema/utils/getDecimalPlacesOfSystem";
import { FaTimesCircle } from "react-icons/fa";
import { IncludeStock } from "../components/IncludeStock";
import { formatData } from "../../utils/data";
import { pdvActions } from "../../pdv/reducer";

export const StockMovimentation = () => {
  useIsAuth();

  const stocks = useSelector(
    (state: RootState) => state.entradaesaida.stocksMovimentations
  );
  const isLoading = useSelector(
    (state: RootState) => !!state.entradaesaida.isLoading
  );
  const success = useSelector(
    (state: RootState) => !!state.entradaesaida.success
  );
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [termo, setTermo] = useState("");

  useEffect(() => {
    dispatch(
      entradaesaidaActions.stocksMovimentationsRequest({
        page,
        termo,
      })
    );
  }, [dispatch, page, termo]);

  useEffect(() => {
    if (!success) {
      return;
    }
    dispatch(
      entradaesaidaActions.stocksMovimentationsRequest({
        page,
        termo,
      })
    );
  }, [dispatch, success, page, termo]);

  useEffect(() => {
    dispatch(pdvActions.getLocationsStockRequest());
  }, []);

  const headers: TableHeaders<Stock>[] = [
    {
      label: "Código",
      wrapped: true,
      render: (item) => item.id,
      notClicable: true,
    },
    {
      label: "Descrição",
      wrapped: true,
      render: (item) => (
        <React.Fragment>
          {item.description}
          {item.canceled == 1 && (
            <>
              {" "}
              <br /> <Badge colorScheme="red">CANCELADO</Badge>{" "}
            </>
          )}
          {item.canceled == 0 && <br />}{" "}
          <Badge colorScheme="blue">{formatData(item.created_at ?? "")}</Badge>
        </React.Fragment>
      ),
      notClicable: true,
    },
    {
      label: "Local",
      wrapped: false,
      render: (item) => item.stockLocation?.name,
      notClicable: true,
    },
    {
      label: "Produto",
      wrapped: false,
      render: (item) => `${item?.product?.codigo} - ${item?.product?.nome}`,
      notClicable: true,
    },
    {
      label: "Quantidade",
      wrapped: false,
      render: (item) => formatValor(item.quantity, getDecimalPlacesOfSystem()),
      notClicable: true,
    },
    {
      label: "Tipo",
      wrapped: false,
      render: (item) => (item.type == StockType.Input ? "Entrada" : "Saída"),
      notClicable: true,
    },
    {
      label: "Ações",
      wrapped: true,
      render: (item, index) => (
        <>
          {item.canceled == 0 && (
            <ButtonGroup>
              <ConfirmButton
                confirm={() => onCancelItem(item.id as number)}
                display={"flex"}
                alignItems={"center"}
                gap={1}
              >
                <FaTimesCircle /> Cancelar
              </ConfirmButton>
            </ButtonGroup>
          )}
        </>
      ),
      notClicable: true,
    },
  ];

  const onCancelItem = (id: number) => {
    dispatch(entradaesaidaActions.stocksMovimentationCancelRequest(id));
  };

  return (
    <Layout>
      <ListHeader label="Movimentação de estoque">
        {" "}
        <IncludeStock />
      </ListHeader>

      <Loader isLoading={isLoading} />

      <ListInput label="Filtrar pela descrição" onSubmit={setTermo} />

      {stocks && (
        <ResponsiveTable
          onClick={(item: any) => {}}
          headers={headers}
          data={stocks.data}
        />
      )}

      {stocks && <Pagination info={stocks} paginate={setPage} />}
    </Layout>
  );
};
