import { Model } from "../../sistema/types/model";

export enum CashRegisterTransactionType {
  Input = 'input',
  Output = 'output',
}

export interface CashRegisterTransaction extends Model {
  cash_register_id: string | number;
  financeiro_parcela_id: string | number;
  value: number;
  type: CashRegisterTransactionType;
  description: string;
  date: string;
}