import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../app/mainReducer";

export function useIsDesenvolvedor() {
  const navigate = useNavigate();
  const e_desenvolvedor = useSelector(
    (state: RootState) => state.sistema.user?.e_desenvolvedor == "1"
  );

  useEffect(() => {
    if (!e_desenvolvedor) {
      navigate("/");
      return;
    }
  }, [navigate, e_desenvolvedor]);
}
