import { useIsAuth } from "../../hooks/useIsAuth";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FiTrash } from "react-icons/fi";
import { entradaesaidaActions } from "../recucer";
import ResponsiveTable, {
  TableHeaders,
} from "../../sistema/components/ResponsiveTable";
import { formatNumber } from "../../utils/formatNumber";
import { ConfirmButton } from "../../sistema/components/ConfirmButton";
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import { ListInput } from "../../sistema/components/ListInput";
import { Pagination } from "../../sistema/components/Pagination";
import { CamposPersonalizadosInfoButton } from "../../sistema/components/CamposPersonalizadosInfoButton";
import { Badge, ButtonGroup } from "@chakra-ui/react";
import { formatData } from "../../utils/data";
import { ShowArray } from "../../sistema/components/ShowArray";
import { InputSelectArray } from "../../sistema/components/InputSelectArray";
import { Form, Formik } from "formik";
import { NotaPromissoria } from "../types/nota_promissoria";

export const NotaPromissoriaList = () => {
  useIsAuth();

  const notas_promissorias = useSelector(
    (state: RootState) => state.entradaesaida.notas_promissorias
  );
  const isLoading = useSelector(
    (state: RootState) => !!state.entradaesaida.isLoading
  );
  const success = useSelector(
    (state: RootState) => !!state.entradaesaida.success
  );
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [termo, setTermo] = useState("");
  const [filtros, setFiltros] = useState({
    status: "",
  });

  useEffect(() => {
    dispatch(
      entradaesaidaActions.notasPromissoriasRequest({
        page,
        termo,
        filtros,
      })
    );
  }, [dispatch, page, termo, filtros]);

  useEffect(() => {
    if (!success) {
      return;
    }
    dispatch(
      entradaesaidaActions.notasPromissoriasRequest({
        page,
        termo,
        filtros,
      })
    );
  }, [dispatch, success, page, termo, filtros]);

  const headers: TableHeaders<NotaPromissoria>[] = [
    {
      label: "Id",
      wrapped: true,
      render: (item) => item.id,
    },
    {
      label: "Cliente",
      wrapped: true,
      render: (item) => (
        <>
          {item?.pessoa?.nome}
          {item?.seller && (
            <>
              {" "}
              <br />{" "}
              <Badge color={"green.500"}> Vendedor: {item.seller?.name} </Badge>
            </>
          )}
        </>
      ),
    },
    {
      label: "Valor Total",
      wrapped: false,
      render: (item) => formatNumber(item.valor_total ?? "0", 2),
    },
    {
      label: "Valor Pago",
      wrapped: false,
      render: (item) => formatNumber(item.valor_total_pago ?? "0", 2),
    },
    {
      label: "Status",
      wrapped: true,
      render: (item) => (
        <ShowArray
          arrayName="nota_promissoria_status"
          value={item.status * 1}
        />
      ),
    },
    {
      label: "Data",
      wrapped: true,
      render: (item) => formatData(item?.created_at ?? ""),
    },
    {
      label: "Ações",
      wrapped: true,
      render: (item, index) => (
        <>
          {
            <ButtonGroup>
              <ConfirmButton confirm={() => onDeleteItem(item.id as number)}>
                <FiTrash />
              </ConfirmButton>
              <CamposPersonalizadosInfoButton
                camposPersonalizados={item?.camposPersonalizados ?? []}
              />
            </ButtonGroup>
          }
        </>
      ),
      notClicable: true,
    },
  ];

  const onDeleteItem = (id: number) => {
    dispatch(entradaesaidaActions.deleteNotaPromissoriaRequest(id));
  };

  const navigate = useNavigate();

  return (
    <Layout>
      <ListHeader label="Notas Promissórias">
        <DefaultButton ml={4} to="/notas-promissorias/novo">
          {" "}
          Nova{" "}
        </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      <ListInput label="Filtrar pelo cliente" onSubmit={setTermo}>
        <Formik
          initialValues={{
            status: filtros.status,
          }}
          enableReinitialize={true}
          onSubmit={() => {}}
        >
          <Form>
            <InputSelectArray
              name="status"
              label=""
              arrayName="nota_promissoria_status"
              onChangeVal={(val) =>
                val !== "add_option" && setFiltros({ ...filtros, status: val })
              }
            />
          </Form>
        </Formik>
      </ListInput>

      {notas_promissorias && (
        <ResponsiveTable
          onClick={(item: any) =>
            navigate(`/notas-promissorias/${item.item.id}`)
          }
          headers={headers}
          data={notas_promissorias.data}
        />
      )}

      {notas_promissorias && (
        <Pagination info={notas_promissorias} paginate={setPage} />
      )}
    </Layout>
  );
};
