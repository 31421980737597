import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useIsAuth } from "../../hooks/useIsAuth";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { cadastroActions } from "../reducer";
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import InputField from "../../sistema/components/InputField";
import { Form, Formik } from "formik";
import { Box, Flex } from "@chakra-ui/react";
import validateForm from "../../utils/validateForm";
import { ButtonHistorico } from "../../sistema/components/ButtonHistorico";
import InputCheck from "../../sistema/components/InputCheck";
import { retornaApenasNumero } from "../../utils/formatString";
import InputNumber from "../../sistema/components/InputNumber";

export const SellerEdit = () => {
  useIsAuth();

  const { id } = useParams();

  const seller = useSelector((state: RootState) => state.cadastro.seller);
  const isLoading = useSelector(
    (state: RootState) => !!state.cadastro.isLoading
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!id) return;

    dispatch(cadastroActions.sellerRequest(id));
  }, [dispatch, id]);

  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);

  return (
    <Layout>
      <ListHeader label={`Vendedor ${seller?.code ?? ""}`}>
        <DefaultButton ml={4} to={`/sellers`}>
          {" "}
          Voltar{" "}
        </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      {seller && (
        <Formik
          enableReinitialize
          initialValues={seller}
          onSubmit={(val, { setErrors }) => {
            const validation = validateForm({ name: "required" }, val);
            if (Object.keys(validation).length > 0) {
              setErrors(validation);
              return;
            }

            dispatch(
              cadastroActions.saveSellerRequest({
                ...val,
                cellphone: retornaApenasNumero(val.cellphone),
                changed: true,
              })
            );
          }}
        >
          {({ values }) => (
            <Form>
              <Flex width="full" wrap="wrap">
                <InputField
                  label="Nome"
                  name="name"
                  width={isMobile ? "100%" : "50%"}
                />
                <InputField
                  label="Celular"
                  name="cellphone"
                  width={isMobile ? "100%" : "50%"}
                  mask="(99)99999-9999"
                />
                <InputNumber
                  label="Porcentagem de comissão"
                  name="comission_percentage"
                  width={isMobile ? "100%" : "50%"}
                />
                <InputCheck label="Ativo?" name="active" />

                <Flex width={"full"} />
                <Box
                  bg={"white"}
                  mt={2}
                  width={"full"}
                  position="sticky"
                  bottom="10px"
                  zIndex="10"
                  p={2}
                >
                  <DefaultButton type="submit">Salvar vendedor</DefaultButton>
                  {values.id && (
                    <ButtonHistorico par="sellers" idpar={values.id} ml={2} />
                  )}
                </Box>
              </Flex>
            </Form>
          )}
        </Formik>
      )}
    </Layout>
  );
};
