import { useIsAuth } from "../../hooks/useIsAuth"
import { useIsDesenvolvedor } from "../../hooks/useIsDesenvolvedor"
import { useEffect } from 'react';
import Layout from "../components/Layout"
import { useDispatch, useSelector } from "react-redux";
import { sistemaActions } from "../recucer";
import { RootState } from "../../app/mainReducer";
import { ListHeader } from "../components/ListHeader";
import { DefaultButton } from "../components/DefaultButton";
import Loader from "../components/Loader";
import { Form, Formik } from "formik";
import { Flex } from "@chakra-ui/react";
import InputField from "../components/InputField";
import InputImage from "../components/InputImage";
import InputMultiSelect from "../components/InpurMultiSelect";
import { ButtonHistorico } from "../components/ButtonHistorico";
import InputCheck from "../components/InputCheck";
import InputNumber from "../components/InputNumber";

export const Parametros = () => {
  useIsDesenvolvedor()
  useIsAuth()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(sistemaActions.parametrosRequest())
  }, [dispatch])

  const parametros = useSelector((state: RootState) => state.sistema?.parametros);
  const isLoading = useSelector((state: RootState) => !!state.sistema?.isLoading);

  const tiposAcessos = [
    {
      label: 'Cadastros',
      value: 'Cadastros'
    },
    {
      label: 'Financeiro',
      value: 'Financeiro'
    },
  ]

  return (
    <Layout>
      <ListHeader label="Parâmetros">
        <DefaultButton ml={4} to="/clientes"> Voltar </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      {parametros && <Formik
        initialValues={parametros}
        enableReinitialize
        onSubmit={(values, { setErrors }) => {
          console.log("parametros form", values);
          dispatch(sistemaActions.saveParametrosRequest({
            ...values,
            changed: true,
          }));
        }}
      >
        {({ values }) => (
        <Form>
          <Flex width="full" wrap={"wrap"}>
            <InputField label="Cor primária (Hexadecimal)" name="cor_primaria" autoFocus width={"50%"} />
            <InputField label="Cor primária ao passar o mouse em cima (Hexadecimal)" name="cor_primaria_hover" width={"50%"} />
            <InputField label="Nome da empresa" name="nome_empresa" width={"50%"} />
            <InputField label="Emails das notificações (Separado por vírgula)" name="emails_responsaveis" width={"50%"} />
            <InputMultiSelect label="Acessos ao sistema" name="modulos" options={tiposAcessos} width={"50%"} />
            <InputNumber decimalPlaces={0} label="QTD. Casas Decimais" name="decimal_places" width={"50%"} />
            <InputCheck label="Usa cadastro de produto?" name="usa_cadastro_de_produto" />

            <Flex width="full"></Flex>
            <InputImage name="logo" label="Logo do sistema" />
          </Flex>
          <DefaultButton type="submit" mt={2}>Salvar</DefaultButton>
          {values.id && <ButtonHistorico par="parametros" idpar={values.id} mt={2} ml={2} />}
        </Form>)}
      </Formik>}
    </Layout>
  )
}