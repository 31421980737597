import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import { formatData, getCurrentData } from "../../utils/data";
import { retornaApenasNumero } from "../../utils/formatString";
import { formatCpfCnpj } from "../../utils/formatCpfCnpj";
import { IResponseRelatorioPedido } from "../reducer";
import { Pedido } from "../../entradaesaida/types/pedido";
import { formatValor } from "../../utils/formatValor";
import { getDecimalPlacesOfSystem } from "../../sistema/utils/getDecimalPlacesOfSystem";

Font.register({
  family: "Arial",
  fontStyle: "normal",
  fontWeight: "normal",
  src: "/fonts/Arial Unicode.ttf",
});

Font.register({
  family: "Arial",
  fontStyle: "normal",
  fontWeight: "bold",
  src: "/fonts/Arial Bold.ttf",
});

Font.register({
  family: "Arial",
  fontStyle: "italic",
  fontWeight: "normal",
  src: "/fonts/Arial Italic.ttf",
});

Font.register({
  family: "Arial",
  fontStyle: "italic",
  fontWeight: "bold",
  src: "/fonts/Arial Bold Italic.ttf",
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    marginTop: "1cm",
    marginBottom: "1cm",
    marginLeft: "1cm",
    marginRight: "1cm",
    width: "19cm",
    fontFamily: "Arial",
  },
  topoDemonstrativo: {
    flexDirection: "row",
    border: 1,
    borderRadius: 5,
    maxWidth: "19cm",
  },
  topoDadosBasicos: {
    flexDirection: "column",
    fontSize: 10,
    flexGrow: 1,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  topoDescricao: {
    marginLeft: "5mm",
    marginRight: "5mm",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  containerInfoHolerith: {
    flexDirection: "column",
    border: 1,
    borderRadius: 5,
    fontSize: 10,
    maxWidth: "19cm",
    marginTop: "0.5cm",
  },
  containerItensHolerith: {
    flexDirection: "row",
    minHeight: "24cm",
  },
  containerColunaHolerith: {
    flexDirection: "column",
    borderRight: 1,
    paddingLeft: "2mm",
    paddingRight: "2mm",
    paddingBottom: "2mm",
    fontSize: "8px",
  },
  textBold: {
    fontWeight: "bold",
  },
  textRight: {
    textAlign: "right",
  },
  table: {
    width: "100%",
    borderWidth: 2,
    display: "flex",
    flexDirection: "column",
    marginVertical: 12,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
  },
  cell: {
    borderWidth: 1,
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
    flexWrap: "wrap",
  },
});

export const RelacaoPedidoPdf = ({
  relatorio,
  parametros,
  filtros,
  itensArrayEmpresas,
}: {
  relatorio: IResponseRelatorioPedido;
  parametros: any;
  filtros: string[];
  itensArrayEmpresas: any;
}) => {
  return (
    <Document title={`Relação de Pedidos-${getCurrentData()}`}>
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={{ fontSize: 9 }}>
            Relação de pedidos emitida em {formatData(getCurrentData())}{" "}
          </Text>
        </View>
        <View style={styles.topoDemonstrativo}>
          <Image
            src="/logo512.png"
            cache
            style={{ width: "50px", height: "50px", margin: "5mm" }}
          />

          <View style={styles.topoDadosBasicos}>
            <Text style={styles.textBold}>
              {parametros?.nome_empresa
                ? parametros?.nome_empresa
                : "AG Solution"}
            </Text>
          </View>
          <View style={styles.topoDescricao}>
            <Text style={{ ...styles.textBold, fontSize: "9px" }}>
              Filtros utilizados
            </Text>
            {"\n"}
            <Text>
              <>
                {filtros.map((item, index) => (
                  <Text key={index} style={{ fontSize: "7px" }}>
                    &bull; {item} {"\n"}
                  </Text>
                ))}
              </>
            </Text>
          </View>
          <View style={styles.topoDescricao}>
            <Text style={styles.textBold}>Relação</Text>
            <Text style={styles.textBold}>De</Text>
            <Text style={styles.textBold}>Pedidos</Text>
          </View>
        </View>
        <View style={styles.containerInfoHolerith}>
          {((relatorio?.itens as any) ?? [])?.map((item: Pedido, i: number) => (
            <View
              key={i}
              style={{
                padding: "5px",
                borderBottom: "1px solid #d3d3d3",
                maxWidth: "19cm",
              }}
            >
              <Text
                style={{
                  fontWeight: 700,
                  fontSize: "10px",
                  color: "black",
                  width: "100%",
                }}
              >
                {item.numero} - {formatData(item.data)} (
                {item.cancelado == 1
                  ? "Cancelado"
                  : item.faturado == 1
                  ? "Faturado"
                  : "Em Carteira"}
                )
              </Text>
              <View style={{ flexDirection: "row" }}>
                <View style={{ width: "60%" }}>
                  <Text style={{ fontSize: "7px" }}>
                    CPF/CNPJ:{" "}
                    <Text style={styles.textBold}>
                      {" "}
                      {formatCpfCnpj(
                        retornaApenasNumero(item?.pessoa?.cpf_cnpj ?? "")
                      )}
                    </Text>{" "}
                    {"\n"}
                    Nome:{" "}
                    <Text style={styles.textBold}>
                      {item?.pessoa?.nome}
                    </Text>{" "}
                    {"\n"}
                    Vendedor:{" "}
                    <Text style={styles.textBold}>
                      {item?.seller?.name ?? 'Nenhum'}
                    </Text>{" "}
                    {"\n"}
                    {/* Empresa:{" "}
                    <Text style={styles.textBold}>
                      {" "}
                      {item?.pessoa?.array_empresas
                        ? itensArrayEmpresas.find(
                            (j: any) =>
                              j.value == (item?.pessoa?.array_empresas as any)
                          )?.label
                        : "Nenhuma"}
                    </Text>
                    {"\n"} */}
                    Valor Total:{" "}
                    <Text style={styles.textBold}>
                      R${" "}
                      {formatValor(
                        item?.valor_total,
                        getDecimalPlacesOfSystem()
                      )}{" "}
                    </Text>
                    {"\n"}
                  </Text>
                  <Text style={{ fontSize: "7px", marginTop: "8px" }}>
                    <Text
                      style={{
                        fontSize: "8px",
                        marginTop: "5px",
                        ...styles.textBold,
                      }}
                    >
                      Produtos
                    </Text>{" "}
                    {"\n"}
                  </Text>
                  {(item?.itens ?? []).map((j, index) => (
                    <Text
                      key={index}
                      style={{
                        width: "10cm",
                        marginBottom: "3px",
                        paddingBottom: "3px",
                        borderBottom:
                          index === (item?.itens ?? []).length - 1
                            ? ""
                            : "1px solid #f3f3f3",
                        fontSize: "7px",
                      }}
                    >
                      Produto:{" "}
                      <Text style={styles.textBold}>
                        {j.item ? j.item?.nome : j.produto}
                      </Text>{" "}
                      {"\n"}
                      Quantidade:{" "}
                      <Text style={styles.textBold}>
                        {formatValor(j.quantidade, getDecimalPlacesOfSystem())}
                      </Text>{" "}
                      | Valor unitário:{" "}
                      <Text style={styles.textBold}>
                        {formatValor(j.valor_unitario, getDecimalPlacesOfSystem())}
                      </Text>{" "}
                      | Total:{" "}
                      <Text style={styles.textBold}>
                        {formatValor(j.valor_total, getDecimalPlacesOfSystem())}
                      </Text>{" "}
                      {"\n"}
                    </Text>
                  ))}
                </View>
                <View style={{ maxWidth: "40%" }}>
                  <Text style={{ fontSize: "7px" }}>
                    Endereço:{" "}
                    <Text style={styles.textBold}>
                      {" "}
                      {item?.pessoa?.endereco}, n° {item?.pessoa?.numero}{" "}
                    </Text>
                  </Text>
                  <Text style={{ fontSize: "7px" }}>
                    Cidade:{" "}
                    <Text style={styles.textBold}>
                      {" "}
                      {item?.pessoa?.cidade?.nome} - {item?.pessoa?.cidade?.uf}{" "}
                    </Text>
                  </Text>
                  <Text style={{ fontSize: "7px" }}>
                    Limite crédito:{" "}
                    <Text style={styles.textBold}>
                      {" "}
                      {formatValor(item?.pessoa?.limite_credito ?? 0)}{" "}
                    </Text>
                  </Text>
                </View>
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};
