import { Box, ButtonGroup, Card, CardBody, CardHeader, Flex, Grid, Heading, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, StackDivider, Text, useDisclosure } from "@chakra-ui/react"
import { Form, Formik } from "formik"
import { crmActions } from "../reducer"
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../sistema/components/InputField";
import { RootState } from "../../app/mainReducer";
import InputMultiSelect from "../../sistema/components/InpurMultiSelect";
import { getArrayUfs } from "../../utils/ufs";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { Pessoa } from "../../cadastros/types/pessoa";
import { useHref, useNavigate, useParams, useRoutes } from "react-router-dom";
import { coresStatusCards } from "../components/FichaCrmGerenciar";
import Layout from "../../sistema/components/Layout";
import { useIsAuth } from "../../hooks/useIsAuth";
import { ListHeader } from "../../sistema/components/ListHeader";
import { Pagination } from "../../sistema/components/Pagination";
import { AgendamentosButton } from "../components/AgendamentosButton";
import { ContatosButton } from "../components/ContatosButton";
import { UltimosPedidosButton } from "../components/UltimosPedidosButton";
import { DadosPessoaButton } from "../components/DadosPessoaButton";
import { formatValor } from "../../utils/formatValor";
import { formatCelular } from "../../utils/formatCelular";
import Loader from "../../sistema/components/Loader";
import { formatData, parseData } from "../../utils/data";
import { InputSelectArray } from "../../sistema/components/InputSelectArray";
import { apiCall } from "../../utils/apiCall";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { PDFViewer } from "@react-pdf/renderer";
import { RelacaoClientePdf } from "../components/RelacaoClientePdf";
import { parse } from "date-fns";
import { CamposPersonalizadosView } from "../../sistema/components/CamposPersonalizadosView";
import { CamposPersonalizadosFiltros } from "../../sistema/components/CamposPersonalizadosFiltros";
import { SellerBobjeto } from "../../cadastros/components/SellerBobjeto";

export type IFiltrosRelatorioCliente = {
  fichas: { label: string; value: number }[];
  status_fichas: { label: string; value: number }[];
  cliente_cidade_ufs: { label: string; value: number }[];
  status: { label: string; value: string }[];
  tipos_pessoa: { label: string; value: string }[];
  cliente_cidade_nome: string;
  cliente_nome: string;
  data_ultima_compra_ini: string;
  data_ultima_compra_fim: string;
  page: number;
  empresas: number | string;
  data_integracao: string;
  e_cliente: number;
  e_fornecedor: number;
}

export interface IPessoaRelatorioCliente extends Pessoa {
  rota_codigo: string;
  rota_descricao: string;
  ultima_compra: string;
  ultima_ficha: string;
  ultima_agenda: string;
  ultima_ficha_id: number;
}

export const RelatorioRelacaoCliente = () => {
  useIsAuth();

  const [page, setPage] = useState(1);

  const { tipo } = useParams();

  const relacaoDeClientes = tipo == 'clientes';

  const novosFiltros = () => {
    return {
      data_ultima_compra_ini: '',
      data_ultima_compra_fim: '',
      cliente_nome: '',
      cliente_cidade_nome: '',
      cliente_cidade_ufs: [],
      status: [],
      tipos_pessoa: [],
      status_fichas: [],
      fichas: [],
      empresas: '',
      page: 1,
      data_integracao: '',
      e_fornecedor: relacaoDeClientes ? 0 : 1,
      e_cliente: relacaoDeClientes ? 1 : 0,
    }
  }

  const [currentItem, setCurrentItem] = useState<IFiltrosRelatorioCliente>(novosFiltros())

  useEffect(() => {
    setCurrentItem({
      ...currentItem,
      page,
      e_fornecedor: relacaoDeClientes ? 0 : 1,
      e_cliente: relacaoDeClientes ? 1 : 0,
    })
    dispatch(crmActions.buscaRelatorioClientesRequest({
      ...currentItem,
      page,
      e_fornecedor: relacaoDeClientes ? 0 : 1,
      e_cliente: relacaoDeClientes ? 1 : 0,
    }));
  }, [page])

  useEffect(() => {
    setCurrentItem(novosFiltros())
    dispatch(crmActions.buscaRelatorioClientesRequest(novosFiltros()));
  }, [relacaoDeClientes])

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(crmActions.buscaFichaSelectInputsRequest())
  }, [dispatch])

  const isMobile = useSelector((state: RootState) => state.sistema.isMobile);
  const isLoading = useSelector((state: RootState) => !!state.crm.isLoading);
  const selectInputs = useSelector((state: RootState) => state.crm.fichaSelectInputs);
  const relatorioClientes = useSelector((state: RootState) => state.crm.relatorioClientes);

  const getUfsFormatadas = () => {
    return getArrayUfs().map((i) => ({
      label: i.name,
      value: i.value,
    }))
  }

  const getStatusFormatados = () => {
    return [
      {
        label: 'Ativo',
        value: 'Ativo',
      },
      {
        label: 'Inativo',
        value: 'Inativo',
      },
    ]
  }

  const getTipoPessoaFormatados = () => {
    return [
      {
        label: 'Pessoa física',
        value: 'Pessoa física',
      },
      {
        label: 'Pessoa jurídica',
        value: 'Pessoa jurídica',
      },
    ]
  }

  const getStatusFichas = () => {
    return coresStatusCards.map((i) => ({ label: i.name, value: i.value }));
  }

  const getLarguraPagina = (partes: number) => {
    if (isMobile) return '100%';

    return `${100 / partes}%`;
  }

  const getFichasFormatadas = () => {
    return (selectInputs?.fichas ?? []).map((i) => ({ label: `${i.nome} - ${formatData(i.data)}`, value: i.id as number }));
  }

  const navigate = useNavigate();

  const itensArrayEmpresas = useSelector((state: RootState) => (state.sistema?.listas ?? {})['empresas'] ?? []);

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [itens, setItens] = useState<IPessoaRelatorioCliente[]>([]);
  const [isLoadingLocal, setIsLoadingLocal] = useState<boolean>(false);
  const onImprimir = async () => {
    setIsLoadingLocal(true);
    const { data } = await apiCall({
      url: `relacao-clientes?filtros=${JSON.stringify({
        ...currentItem,
        fichas: currentItem.fichas.map((i) => i.value),
        status_fichas: currentItem.status_fichas.map((i) => i.value),
        cliente_cidade_ufs: currentItem.cliente_cidade_ufs.map((i) => i.value),
        status: currentItem.status.map((i) => i.value),
      })}&page=${currentItem?.page}&apenasDados=sim`, method: 'GET'
    });
    console.log(data)
    setIsLoadingLocal(false);
    setItens(data);
    onOpen();
  }

  const getFiltros = () => {
    const retorno: string[] = [];

    if (currentItem.data_ultima_compra_ini) {
      retorno.push(`Última compra maior: ${formatData(currentItem.data_ultima_compra_ini, "dd/MM/yyyy")}`);
    }
    if (currentItem.data_ultima_compra_fim) {
      retorno.push(`Última compra menor: ${formatData(currentItem.data_ultima_compra_fim, "dd/MM/yyyy")}`);
    }
    if (currentItem.data_integracao) {
      retorno.push(`Data integração: ${parseData(currentItem.data_integracao, "dd/MM/yyyy")}`);
    }
    if (currentItem.cliente_nome) {
      retorno.push(`${relacaoDeClientes ? 'Cliente' : 'Fornecedor'}: ${currentItem.cliente_nome}`);
    }
    if (currentItem.cliente_cidade_nome) {
      retorno.push(`Cidade: ${currentItem.cliente_cidade_nome}`);
    }
    if (currentItem.cliente_cidade_ufs.length) {
      retorno.push(`Estados: ${(currentItem.cliente_cidade_ufs.map((j) => j.label) as string[]).join(', ')}`);
    }
    if (currentItem.status.length) {
      retorno.push(`Status Cliente: ${(currentItem.status.map((j) => j.label) as string[]).join(', ')}`);
    }
    if (currentItem.tipos_pessoa.length) {
      retorno.push(`Tipo Pessoa: ${(currentItem.tipos_pessoa.map((j) => j.label) as string[]).join(', ')}`);
    }
    if (currentItem.status_fichas.length) {
      retorno.push(`Status Ficha: ${(currentItem.status_fichas.map((j) => j.label) as string[]).join(', ')}`);
    }
    if (currentItem.fichas.length) {
      retorno.push(`Fichas: ${(currentItem.fichas.map((j) => j.label) as string[]).join(', ')}`);
    }
    if (currentItem.empresas) {
      retorno.push(`Empresa: ${itensArrayEmpresas?.find((i) => i.value == currentItem.empresas)?.label}`);
    }

    if (retorno.length === 0) return ['Nenhum'];

    return retorno;
  }

  const parametros = useSelector((state: RootState) => state.sistema?.parametros)

  const onSearch = () => {
    dispatch(crmActions.buscaRelatorioClientesRequest({
      ...currentItem,
      page,
    }));
  }

  return (
    <Layout>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"full"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ListHeaderSecondary label={'Relatório'} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isOpen && <PDFViewer style={{ width: "100%", height: "80vh" }}>
              <RelacaoClientePdf pessoas={itens} parametros={parametros} filtros={getFiltros()} itensArrayEmpresas={itensArrayEmpresas} clientes={relacaoDeClientes} />
            </PDFViewer>}
            <DefaultButton onClick={onClose} mt={2} width={"full"}>
              Fechar
            </DefaultButton>

          </ModalBody>
        </ModalContent>
      </Modal>

      <Box width={"full"}>
        <ListHeader label={`Relação de ${relacaoDeClientes ? 'Clientes' : 'Fornecedores'}`}>
          <DefaultButton ml={1} onClick={onImprimir} isLoading={isLoadingLocal}>
            Imprimir
          </DefaultButton>
          <DefaultButton ml={4} to={relacaoDeClientes ? `/pessoas/clientes/novo` : `/pessoas/fornecedores/novo`}> Novo {relacaoDeClientes ? 'cliente' : 'fornecedor'} </DefaultButton>
        </ListHeader>
        <Loader isLoading={isLoading} />
        {currentItem && <Formik
          enableReinitialize
          initialValues={currentItem}
          onSubmit={async (val, { setErrors }) => {

            setCurrentItem(val);

            setPage(1)

            dispatch(crmActions.buscaRelatorioClientesRequest({
              ...val,
              page,
            }));
          }}
        >
          {({ values, submitForm }) => (
            <Form>
              <Flex width="full" wrap="wrap">
                <InputField label={relacaoDeClientes ? 'Cliente' : 'Fornecedor'} name="cliente_nome" width={getLarguraPagina(5)} />
                <InputField label="Cidade" name="cliente_cidade_nome" width={getLarguraPagina(5)} />
                <InputMultiSelect label="UFS" name="cliente_cidade_ufs" options={getUfsFormatadas()} width={getLarguraPagina(5)} />
                <InputMultiSelect label="Status" name="status" options={getStatusFormatados()} width={getLarguraPagina(5)} />
                <InputMultiSelect label="Tipo Pessoa" name="tipos_pessoa" options={getTipoPessoaFormatados()} width={getLarguraPagina(5)} />
                {/* <InputMultiSelect label="Status Ficha CRM" name="status_fichas" options={getStatusFichas()} width={getLarguraPagina(5)} /> */}
                <SellerBobjeto width={getLarguraPagina(5)} />
                <InputMultiSelect label="Fichas" name="fichas" options={getFichasFormatadas()} width={getLarguraPagina(5)} />
                <InputField type="date" label="Última compra" name="data_ultima_compra_ini" width={getLarguraPagina(5)} />
                <InputField type="date" label="a" name="data_ultima_compra_fim" width={getLarguraPagina(5)} />
                {/* <InputSelectArray arrayName="empresas" name="empresas" label="Empresa origem" width={getLarguraPagina(5)} /> */}
                <InputField name="data_integracao" type="date" label="Data integração" width={getLarguraPagina(5)} />
                <ButtonGroup alignItems={"center"} mt={2} ml={1}>
                  <CamposPersonalizadosFiltros onFilter={submitForm} par={relacaoDeClientes ? 'pessoa' : 'pessoa-fornecedor'} />
                  <DefaultButton type="submit">Buscar {relacaoDeClientes ? 'Clientes' : 'Fornecedores'} </DefaultButton>
                  &nbsp;  Total de {relatorioClientes?.total ?? 0} {relacaoDeClientes ? 'clientes' : 'fornecedores'}
                </ButtonGroup>
              </Flex>
            </Form>)}
        </Formik>}
      </Box>

      <Grid mt={4} templateColumns={isMobile ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)'} gap={6}>
        {(relatorioClientes?.data ?? []).map((i, index) => (
          <Card key={`${index}`}>
            <CardHeader>
              <Heading size='sm'>
                {i.nome}
              </Heading>
              <Text size=''>
                CPF/CNPJ: {i.cpf_cnpj}
              </Text>
              <Text size=''>
                Status: <b>{i.status}</b> <br />
                Integração: <b>{i.data_integracao ? parseData(i.data_integracao) : 'Nenhuma'} </b>
              </Text>
            </CardHeader>

            <CardBody>
              <Stack divider={<StackDivider />} spacing='4'>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    Resumo
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    Endereço: <b> {i.endereco}, n° {i.numero} </b> <br />
                    Cidade: <b>{i.cidade?.nome} - {i.cidade?.uf} </b> <br />
                    Limite de crédito: <b>{formatValor(i.limite_credito ?? 0)}</b> <br />
                    E-mail: <b>{i.email ? i.email : 'Nenhum'}</b> <br />
                    <CamposPersonalizadosView values={i.camposPersonalizados ?? []} />
                  </Text>
                </Box>
                {(i?.contatos ?? []).map((cont) => (
                  <Box key={cont.id}>
                    <Heading size='xs' textTransform='uppercase'>
                      Contato {cont.tipo}
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                      Nome: <b>{cont.nome}</b> | <b> <span><u> <a href={`https://wa.me/${cont.celular}`} target="_blank"> {formatCelular(cont.celular)} </a></u></span> </b> <br />
                      {cont.observacoes}
                    </Text>
                  </Box>
                ))}
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    Ficha CRM
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    Última ficha: <b> {i.ultima_ficha ? i.ultima_ficha : 'Nenhuma'} </b> {!!i.ultima_ficha && <Link href={`/fichas-crm/${i.ultima_ficha_id}`} target="_blank"> <DefaultButton size={"xs"}>Ver</DefaultButton></Link>} <br />
                    Última compra: <b> {i.ultima_compra ? formatData(i.ultima_compra) : 'Nenhuma'} </b> <br />
                    Última agenda: <b> {i.ultima_agenda ? formatData(i.ultima_agenda) : 'Nenhuma'} </b> <br />
                  </Text>
                </Box>
                <Box>
                  <AgendamentosButton pessoa_id={i.id as number} />
                  <ContatosButton pessoa_id={i.id as number} />
                  <UltimosPedidosButton pessoa_id={i.id as number} />
                  <DadosPessoaButton pessoa_id={i.id as number} onDelete={onSearch} />
                </Box>
              </Stack>
            </CardBody>
          </Card>
        ))}

      </Grid>

      {relatorioClientes && <Pagination info={relatorioClientes} paginate={setPage} />}
    </Layout>
  )

}