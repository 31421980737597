import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { Formik, Form, useFormikContext, useField } from "formik";
import validateForm from "../../utils/validateForm";
import { useState } from "react";
import { crmActions } from "../reducer";
import { useDispatch, useSelector } from "react-redux";
import { apiCall } from "../../utils/apiCall";
import { RootState } from "../../app/mainReducer";
import InputField from "../../sistema/components/InputField";
import { mensagemErro } from "../../utils/toasts";
import { formatError } from "../../utils/formatError";
import { novoPedido } from "../../entradaesaida/data/pedido";
import { Pedido } from "../../entradaesaida/types/pedido";
import InputCheck from "../../sistema/components/InputCheck";
import { Filhos } from "../../sistema/components/Filhos";
import { novoPedidoItem } from "../../entradaesaida/data/pedido_item";
import InputNumber from "../../sistema/components/InputNumber";
import { formatValor } from "../../utils/formatValor";
import ResponsiveTable, {
  TableHeaders,
} from "../../sistema/components/ResponsiveTable";
import { formatData } from "../../utils/data";
import { FiEdit, FiTrash } from "react-icons/fi";
import { CamposPersonalizadosForm } from "../../sistema/components/CamposPersonalizadosForm";
import { ProdutoBobjeto } from "../../entradaesaida/components/ProdutoBobjeto";
import { getDecimalPlacesOfSystem } from "../../sistema/utils/getDecimalPlacesOfSystem";
import { SellerBobjeto } from "../../cadastros/components/SellerBobjeto";

export const UltimosPedidosButton = ({
  pessoa_id,
}: {
  pessoa_id: number | string;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenNovoPedido,
    onOpen: onOpenNovoPedido,
    onClose: onCloseNovoPedido,
  } = useDisclosure();
  const dispatch = useDispatch();
  const [pedidos, setPedidos] = useState<Pedido[]>([]);
  const [currentItem, setCurrentItem] = useState<Pedido | null>(null);
  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);

  const onBuscaDados = async () => {
    try {
      dispatch(crmActions.isLoading(true));

      const { data } = await apiCall({
        url: `pedidos-pessoa/${pessoa_id}`,
        method: "GET",
      });

      setPedidos(data);

      dispatch(crmActions.isLoading(false));
    } catch (err: any) {
      dispatch(crmActions.isLoading(false));
      console.log(err);
    }
  };

  const getLarguraPagina = (partes: number) => {
    if (isMobile) return "100%";

    return `${100 / partes}%`;
  };

  const onSavePedido = async (values: Pedido) => {
    try {
      dispatch(crmActions.isLoading(true));

      await apiCall({
        url: `pedidos`,
        method: "POST",
        data: {
          ...values,
          changed: true,
          pessoa_id,
        },
      });

      onCloseNovoPedido();
      onBuscaDados();

      dispatch(crmActions.isLoading(false));
    } catch (err: any) {
      mensagemErro(formatError(err));
      dispatch(crmActions.isLoading(false));
      console.log(err);
    }
  };

  const onClickAdicionarPedido = () => {
    const novoPedidoReg = novoPedido();
    novoPedidoReg.pessoa_id = pessoa_id;

    setCurrentItem(novoPedidoReg);
    onOpenNovoPedido();
  };

  const ValorUnitarioInput = () => {
    const formik = useFormikContext();
    const [, { value: quantidade }] = useField("quantidade");
    const [, { value: valorDesconto }] = useField("valor_desconto");

    const onChangeVal = (valor: number) => {
      formik.setFieldValue("valor_total", quantidade * valor - valorDesconto);
    };

    return (
      <InputNumber
        decimalPlaces={getDecimalPlacesOfSystem()}
        onChangeVal={onChangeVal}
        name="valor_unitario"
        label="Valor unitário"
        width={getLarguraPagina(2)}
      />
    );
  };

  const ValorDescontoInput = () => {
    const formik = useFormikContext();
    const [, { value: quantidade }] = useField("quantidade");
    const [, { value: unitario }] = useField("valor_unitario");

    const onChangeVal = (valor: number) => {
      formik.setFieldValue("valor_total", quantidade * unitario - valor);
    };

    return (
      <InputNumber
        decimalPlaces={getDecimalPlacesOfSystem()}
        onChangeVal={onChangeVal}
        name="valor_desconto"
        label="Valor deconto"
        width={getLarguraPagina(2)}
      />
    );
  };

  const onDeleteItem = async (values: Pedido) => {
    if (!window.confirm("Deseja realmente excluir o pedido?")) {
      return;
    }

    try {
      dispatch(crmActions.isLoading(true));

      await apiCall({
        url: `pedidos/${values.id}`,
        method: "DELETE",
        data: {
          ...values,
          changed: true,
        },
      });

      onBuscaDados();

      dispatch(crmActions.isLoading(false));
    } catch (err: any) {
      mensagemErro(formatError(err));
      dispatch(crmActions.isLoading(false));
      console.log(err);
    }
  };

  const QuantidadeInput = () => {
    const formik = useFormikContext();
    const [, { value: unitario }] = useField("valor_unitario");
    const [, { value: desconto }] = useField("valor_desconto");

    const onChangeVal = (valor: number) => {
      formik.setFieldValue("valor_total", valor * unitario - desconto);
    };

    return (
      <InputNumber
        decimalPlaces={getDecimalPlacesOfSystem()}
        onChangeVal={onChangeVal}
        name="quantidade"
        label="Quantidade"
        width={getLarguraPagina(2)}
      />
    );
  };

  const headers: TableHeaders<Pedido>[] = [
    {
      label: "Número",
      wrapped: false,
      render: (item) => item.numero,
    },
    {
      label: "Data",
      wrapped: true,
      render: (item) => formatData(item.data),
    },
    {
      label: "Valor",
      wrapped: true,
      render: (item) =>
        formatValor(item.valor_total, getDecimalPlacesOfSystem()),
    },
    {
      label: "Cancelado?",
      wrapped: true,
      render: (item) => (item.cancelado ? "Sim" : "Não"),
    },
    {
      label: "Faturado?",
      wrapped: true,
      render: (item) => (item.faturado ? "Sim" : "Não"),
    },
    {
      label: "Itens",
      wrapped: false,
      render: (item) => (
        <Flex direction={"column"}>
          {item.itens?.map((i, index) => (
            <Box key={i.id}>
              <Text whiteSpace="nowrap" fontSize={"12px"}>
                {" "}
                Qtd: {formatValor(i.quantidade, getDecimalPlacesOfSystem())} |
                Valor Un.:{" "}
                {formatValor(i.valor_unitario, getDecimalPlacesOfSystem())}
              </Text>
              <Text> Produto: {i?.item ? i.item?.nome : i.produto}</Text>
              {index !== (item.itens ?? []).length - 1 && (
                <hr style={{ margin: "5px 0" }} />
              )}
            </Box>
          ))}
        </Flex>
      ),
    },
    {
      label: "Ações",
      wrapped: true,
      render: (item, index) => (
        <>
          {
            <DefaultButton
              size={"xs"}
              onClick={() => {
                setCurrentItem(item);
                onOpenNovoPedido();
              }}
            >
              <FiEdit />
            </DefaultButton>
          }
          <Button
            ml={1}
            size={"xs"}
            onClick={() => onDeleteItem(item)}
            colorScheme="red"
          >
            <FiTrash />{" "}
          </Button>
        </>
      ),
      notClicable: true,
    },
  ];

  const parametros = useSelector(
    (state: RootState) => state.sistema.parametros
  );

  return (
    <>
      {pessoa_id && (
        <DefaultButton
          onClick={() => {
            onOpen();
            onBuscaDados();
          }}
          borderRadius={"2px"}
          m={"2px"}
          size={"sm"}
        >
          Pedidos
        </DefaultButton>
      )}

      <Modal
        autoFocus={false}
        returnFocusOnClose={false}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size={"4xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ListHeaderSecondary label={"Pedidos"}>
              <DefaultButton onClick={onClose} ml={2}>
                Voltar
              </DefaultButton>
            </ListHeaderSecondary>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={2}>
            {/* <Flex width={"full"} wrap={"wrap"}>
              <DefaultButton
                onClick={() => onClickAdicionarPedido()}
                borderRadius={"2px"}
                m={"2px"}
                size={"sm"}
              >
                Adicionar Pedido
              </DefaultButton>
            </Flex> */}

            <div className="text-gray-700 font-semibold text-center mx-auto mb-3">
              PARA ADICIONAR UM NOVO PEDIDO, ACESSE O MENÚ "Vendas" e "Carrinho".
            </div>

            {pedidos.length === 0 && (
              <Heading my={4} size={"md"}>
                Nenhum encontrado
              </Heading>
            )}

            {pedidos && (
              <ResponsiveTable isSmall headers={headers} data={pedidos} />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        autoFocus={false}
        returnFocusOnClose={false}
        closeOnOverlayClick={false}
        isOpen={isOpenNovoPedido}
        onClose={onCloseNovoPedido}
        size={"2xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ListHeaderSecondary label={"Pedido"}>
              <DefaultButton onClick={onCloseNovoPedido} ml={2}>
                Voltar
              </DefaultButton>
            </ListHeaderSecondary>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody mb={2}>
            {currentItem && (
              <Formik
                initialValues={currentItem}
                enableReinitialize
                onSubmit={async (values, { setErrors }) => {
                  const validation = validateForm(
                    {
                      data: "required",
                    },
                    values
                  );

                  if (Object.keys(validation).length > 0) {
                    setErrors(validation);
                    return;
                  }

                  if (values.itens?.length === 0) {
                    mensagemErro("Coloque ao menos 1 produto no pedido.");
                    return;
                  }

                  onSavePedido(values);
                }}
              >
                {({ values }) => (
                  <Form>
                    <Flex width="full" wrap={"wrap"}>
                      <InputField
                        label="Número"
                        name="numero"
                        width={getLarguraPagina(2)}
                      />
                      <InputField
                        type="datetime-local"
                        label="Data / Hora"
                        width={getLarguraPagina(2)}
                        name="data"
                      />
                      {/* <InputSelectArray
                        arrayName="tipo_pedido"
                        label="Tipo do Produto"
                        width={getLarguraPagina(3)}
                        name="array_tipo_pedido"
                      /> */}
                      <SellerBobjeto />
                      <ButtonGroup>
                        <InputCheck name="cancelado" label="Cancelado?" />
                        <InputCheck name="faturado" label="Faturado?" />
                      </ButtonGroup>

                      <Flex width="full" wrap={"wrap"}>
                        <CamposPersonalizadosForm
                          tamanho="25%"
                          par="pedido"
                          idp={values?.id as number}
                        />
                      </Flex>

                      <Filhos
                        name="itens"
                        tituloForm="Produtos"
                        editTituloForm="Produto"
                        novoItemObject={novoPedidoItem()}
                        hasMenuActions={
                          (values?.financeiros ?? []).length === 0
                        }
                        validation={{
                          quantidade: "min_number|0",
                          produto:
                            parametros?.usa_cadastro_de_produto == 1
                              ? ""
                              : "required",
                          produto_id:
                            parametros?.usa_cadastro_de_produto == 1
                              ? "required"
                              : "",
                        }}
                        formatValuesBeforeSave={async (val) => {
                          if (parametros?.usa_cadastro_de_produto != 1)
                            return val;
                          let produto = null;
                          try {
                            const { data } = await apiCall({
                              url: `produtos/${val.produto_id}`,
                              method: "GET",
                            });

                            produto = data;
                          } catch (err) {
                            console.log("err", err);
                          }
                          return {
                            ...val,
                            item: produto,
                          };
                        }}
                        headers={[
                          {
                            label: "Qtd",
                            wrapped: true,
                            render: (item) =>
                              formatValor(
                                item.quantidade,
                                getDecimalPlacesOfSystem()
                              ),
                          },
                          {
                            label: "Unitário",
                            wrapped: true,
                            render: (item) =>
                              formatValor(
                                item.valor_unitario,
                                getDecimalPlacesOfSystem()
                              ),
                          },
                          {
                            label: "Desconto",
                            wrapped: true,
                            render: (item) =>
                              formatValor(
                                item.valor_desconto,
                                getDecimalPlacesOfSystem()
                              ),
                          },
                          {
                            label: "Valor Total",
                            wrapped: true,
                            render: (item) =>
                              formatValor(
                                item.valor_total,
                                getDecimalPlacesOfSystem()
                              ),
                          },
                          {
                            label: "Produto",
                            wrapped: false,
                            render: (item) =>
                              item?.item ? item.item?.nome : item.produto,
                          },
                        ]}
                        form={(values, setFieldValue) => (
                          <Flex pb={"150px"} width={"full"} wrap={"wrap"}>
                            <QuantidadeInput />
                            <ValorUnitarioInput />
                            <ValorDescontoInput />
                            <InputNumber
                              disabled
                              name="valor_total"
                              label="Valor Total"
                              width={getLarguraPagina(2)}
                              decimalPlaces={getDecimalPlacesOfSystem()}
                            />
                            {/* <InputNumber
                              name="peso"
                              label="Peso por Unidade"
                              width={getLarguraPagina(4)}
                              decimalPlaces={getDecimalPlacesOfSystem()}
                            /> */}

                            {parametros?.usa_cadastro_de_produto == 1 ? (
                              <ProdutoBobjeto
                                onSelectItem={(item) => {
                                  if (!item) return;
                                  setFieldValue("quantidade", 1);
                                  setFieldValue("valor_unitario", item.preco);
                                  setFieldValue("valor_total", item.preco);
                                }}
                                name="produto_id"
                                label="Produto"
                                width={"100%"}
                              />
                            ) : (
                              <InputField
                                label="Produto"
                                name="produto"
                                width={"100%"}
                              />
                            )}
                          </Flex>
                        )}
                      />

                      {(values?.financeiros ?? []).length > 0 ? (
                        <div className="text-gray-700 font-semibold text-center mx-auto">
                          NÃO É POSSÍVEL EDITAR O PEDIDO POIS <br />
                          TEM INTEGRAÇÃO NO FINANCEIRO JÁ GERADA.
                        </div>
                      ) : (
                        <DefaultButton mt={1} type="submit" width={"full"}>
                          Salvar Pedido
                        </DefaultButton>
                      )}
                    </Flex>
                  </Form>
                )}
              </Formik>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
