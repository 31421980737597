import { useIsAuth } from "../../hooks/useIsAuth"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useState, useEffect } from 'react';
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import { crmActions } from "../reducer";
import { AgendamentosButton } from "../components/AgendamentosButton";
import { ContatosButton } from "../components/ContatosButton";
import { UltimosPedidosButton } from "../components/UltimosPedidosButton";
import { DadosPessoaButton } from "../components/DadosPessoaButton";
import { Box, Button, Card, CardBody, CardHeader, Flex, Grid, Heading, Stack, StackDivider, Text } from "@chakra-ui/react";
import { apiCall } from "../../utils/apiCall";
import { Form, Formik } from "formik";
import InputField from "../../sistema/components/InputField";
import InputMultiSelect from "../../sistema/components/InpurMultiSelect";
import InputSelect from "../../sistema/components/InputSelect";
import { formatData, formatDataObject } from "../../utils/data";
import { Pagination } from "../../sistema/components/Pagination";
import { formatCelular } from "../../utils/formatCelular";
import { Agendamento } from "../types/agendamento";
import { addDays, addHours, addMonths, addWeeks } from "date-fns";
import { novoAgendamento } from "../data/agendamento";
import { mensagemSucesso } from "../../utils/toasts";
import { useNavigate, useParams } from "react-router-dom";

const coresStatusCards = [
  {
    name: 'Em branco',
    value: 0,
    bgColor: 'white',
    color: 'black',
    bgColorCard: 'white',
  },
  {
    name: 'Não ligar',
    value: 1,
    bgColor: '#dc3545',
    color: 'white',
    bgColorCard: 'rgb(255, 160, 122)',
  },
  {
    name: 'Ligou',
    value: 2,
    bgColor: '#ffc107',
    color: 'black',
    bgColorCard: 'rgb(255, 215, 0)',
  },
  {
    name: 'Vendeu',
    value: 3,
    bgColor: '#28a745',
    color: 'white',
    bgColorCard: 'rgb(152, 251, 152)',
  },
]

const rederspertarButtons = [
  {
    name: 'Não mais',
    bgColor: '#dc3545',
    color: 'white',
    naoRederspertar: true,
  },
  {
    name: '1 h',
    bgColor: '#ffc107',
    color: 'black',
    redespertarHora: 1,
  },
  {
    name: '1 dia',
    bgColor: '#ffc107',
    color: 'black',
    redespertarDia: 1,
  },
  {
    name: '1 semana',
    bgColor: '#ffc107',
    color: 'black',
    redespertarSemana: 1,
  },
  {
    name: '1 mês',
    bgColor: '#ffc107',
    color: 'black',
    redespertarMes: 1,
  },
]

export interface IFiltrosAgendamentos {
  nome: string;
  status: { label: string; value: number }[];
  ufs: { label: string; value: string }[];
  cidades: { label: string; value: number }[];
  rotas: { label: string; value: number }[];
  page: number;
  order: string;
  ids?: string;
}

export const Agendamentos = () => {
  useIsAuth();
  const isLoading = useSelector((state: RootState) => !!state.crm.isLoading);
  const isLoadingCadastro = useSelector((state: RootState) => !!state.cadastro.isLoading);
  const dispatch = useDispatch();
  const agendamentos = useSelector((state: RootState) => state.crm.agendamentos);
  const isMobile = useSelector((state: RootState) => state.sistema.isMobile);
  const { ids } = useParams();
  const [page, setPage] = useState(1);

  useEffect(() => {
    setCurrentItem({
      ...currentItem,
      page: page,
      ids: ids,
    })

    dispatch(crmActions.agendamentosRequest({
      ...currentItem,
      page: page,
      ids: ids,
    }));
  }, [dispatch, page, ids])

  const [currentItem, setCurrentItem] = useState<IFiltrosAgendamentos>({ nome: '', status: [], ufs: [], cidades: [], rotas: [], page: 1, order: 'data_proxima', ids: ids, });

  const getLarguraPagina = (partes: number) => {
    if (isMobile) return '100%';

    return `${100 / partes}%`;
  }

  const getStatusFormatados = () => {
    return [
      {
        label: 'Ativo',
        value: 'Ativo',
      },
      {
        label: 'Inativo',
        value: 'Inativo',
      },
    ]
  }


  const onChangeStatus = async (id: number, status: number) => {
    try {
      dispatch(crmActions.isLoading(true))
      await apiCall({
        url: `agenamentos-altera-status`, method: 'POST', data: {
          id,
          status,
        }
      });
      dispatch(crmActions.agendamentosRequest(currentItem));
    } catch (err: any) {
      dispatch(crmActions.isLoading(false))
    }
  }

  type IRederspertarButtons = typeof rederspertarButtons[0];

  const onRedespertarManual = async (data: string, agendamento: Agendamento) => {
    try {
      dispatch(crmActions.isLoading(true))

      await apiCall({
        url: `agendamentos`, method: 'POST', data: {
          ...agendamento,
          finalizado: 1,
          changed: true,
        }
      });

      const novaAgenda = novoAgendamento();
      novaAgenda.pessoa_id = agendamento.pessoa_id;
      novaAgenda.descricao = agendamento.descricao;
      novaAgenda.data = data;

      await apiCall({
        url: `agendamentos`, method: 'POST', data: {
          ...novaAgenda,
          changed: true,
        }
      });

      mensagemSucesso(`Reagendado para o dia ${formatData(data)} com sucesso.`)

      dispatch(crmActions.agendamentosRequest(currentItem));
    } catch (err: any) {
      dispatch(crmActions.isLoading(false))
    }
  }

  const onRedespertarButton = async (item: IRederspertarButtons, agendamento: Agendamento) => {
    try {
      dispatch(crmActions.isLoading(true))

      await apiCall({
        url: `agendamentos`, method: 'POST', data: {
          ...agendamento,
          finalizado: 1,
          changed: true,
        }
      });

      if (!item?.naoRederspertar) {
        const dataReferencia = new Date();

        let novaData = dataReferencia;

        if (item?.redespertarHora) {
          novaData = addHours(dataReferencia, 1);
        }
        if (item?.redespertarDia) {
          novaData = addDays(dataReferencia, 1);
        }
        if (item?.redespertarSemana) {
          novaData = addWeeks(dataReferencia, 1);
        }
        if (item?.redespertarMes) {
          novaData = addMonths(dataReferencia, 1);
        }

        const novaAgenda = novoAgendamento();
        novaAgenda.pessoa_id = agendamento.pessoa_id;
        novaAgenda.descricao = agendamento.descricao;
        novaAgenda.data = formatDataObject(novaData);

        await apiCall({
          url: `agendamentos`, method: 'POST', data: {
            ...novaAgenda,
            changed: true,
          }
        });

        mensagemSucesso(`Reagendado para o dia ${formatData(novaAgenda.data)} com sucesso.`)
      }

      dispatch(crmActions.agendamentosRequest(currentItem));
    } catch (err: any) {
      dispatch(crmActions.isLoading(false))
    }
  }

  const navigate = useNavigate();

  const onSearch = () => {
    dispatch(crmActions.agendamentosRequest({
      ...currentItem,
      page: page,
      ids: ids,
    }));
  }

  return (
    <Layout>
      <ListHeader label={'Agendamentos'} />

      <Loader isLoading={isLoading || isLoadingCadastro} />

      <Box width={"full"}>
        {currentItem && <Formik
          enableReinitialize
          initialValues={currentItem}
          onSubmit={async (val, { setErrors }) => {

            console.log('submit', val)
            setCurrentItem(val);

            dispatch(crmActions.agendamentosRequest(val));
          }}
        >
          {({ values, submitForm, setFieldValue }) => (
            <Form>
              <Flex width="full" wrap="wrap" alignItems={"center"}>
                <InputField
                  label="Nome"
                  name="nome"
                  width={getLarguraPagina(6)}
                />
                {/* <InputMultiSelect label="Rotas" name="rotas" options={(agendamentos?.rotas ?? [])} width={getLarguraPagina(6)} /> */}
                <InputMultiSelect label="Cidades" name="cidades" options={(agendamentos?.cidades ?? [])} width={getLarguraPagina(6)} />
                <InputMultiSelect label="UFS" name="ufs" options={(agendamentos?.ufs ?? [])} width={getLarguraPagina(6)} />
                <InputMultiSelect label="Status" name="status" options={getStatusFormatados()} width={getLarguraPagina(6)} />
                <InputSelect
                  label="Ordenação"
                  name="order"
                  width={getLarguraPagina(6)}
                >
                  <option value="data_proxima">
                    Data mais próxima
                  </option>
                  <option value="data_longe">
                    Data mais longe
                  </option>
                  <option value="nome">
                    Nome
                  </option>
                  <option value="cpfcnpj">
                    CPF / CNPJ
                  </option>
                  <option value="cidade">
                    Cidade
                  </option>
                  {/* <option value="rota">
                    Rota
                  </option> */}
                  <option value="nao_compra_mais_tempo">
                    Não compra a mais tempo
                  </option>
                  <option value="nao_compra_menos_tempo">
                    Não compra a menos tempo
                  </option>
                </InputSelect>
                <DefaultButton m={1} type="submit">Filtrar</DefaultButton>
                {values?.ids && <Button colorScheme="green" onClick={() => navigate('/agendamentos')}>Mostrar todos</Button>}
              </Flex>
            </Form>)}
        </Formik>}

        <Heading ml={2} size={"md"}>
          Total de {agendamentos?.itens?.total ?? 0} agendamentos
        </Heading>

        <Grid mt={4} templateColumns={isMobile ? 'repeat(1, 1fr)' : 'repeat(3, 1fr)'} gap={6}>
          {(agendamentos?.itens?.data ?? []).map((i, index) => (
            <Card bg={coresStatusCards.find((cor) => cor.value == i.status)?.bgColorCard} key={`${index}`}>
              <CardHeader>
                <Heading size='sm'>
                  {i.pessoa?.nome}
                </Heading>
                <Text size=''>
                  CPF/CNPJ: {i.pessoa?.cpf_cnpj}
                </Text>
                <Text size=''>
                  Status: <b>{i.pessoa?.status}</b>
                </Text>
              </CardHeader>

              <CardBody>
                <Stack divider={<StackDivider />} spacing='4'>
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      Resumo
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                      Agendado para: <b> {formatData(i.data)} </b><br />
                      Descrição: <b> {i.descricao ? i.descricao : 'Nenhuma'} </b><br />
                      Última compra: <b>{(i as any)?.data_ultimo_pedido ? <> {formatData((i as any).data_ultimo_pedido)} </> : <>Nenhum encontrado</>} </b> <br />
                    </Text>
                  </Box>
                  {(i?.pessoa?.contatos ?? []).map((cont) => (
                    <Box key={cont.id}>
                      <Heading size='xs' textTransform='uppercase'>
                        Contato {cont.tipo}
                      </Heading>
                      <Text pt='2' fontSize='sm'>
                        Nome: <b>{cont.nome}</b> | <b> <span><u> <a href={`https://wa.me/${cont.celular}`} target="_blank"> {formatCelular(cont.celular)} </a></u></span> </b> <br />
                        {cont.observacoes}
                      </Text>
                    </Box>
                  ))}
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      Localização
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                      Cidade: <b>{i.pessoa?.cidade?.nome} - {i.pessoa?.cidade?.uf} </b> <br />
                      {/* Rota: <b>{!!(i as any)?.rota_codigo ? <>{(i as any)?.rota_codigo} - {(i as any)?.rota_descricao}</> : <>Nenhuma cadastrada</>}</b> <br /> */}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize='sm'>
                      {coresStatusCards.map((j) => (
                        <Button _hover={{ backgroundColor: j.bgColor }} onClick={() => onChangeStatus(i.id as number, j.value)} borderRadius={"2px"} m={"2px"} size={"xs"} key={j.bgColor} bg={j.bgColor} color={j.color}>
                          {j.name}
                        </Button>
                      ))}
                    </Text>
                  </Box>
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      Redespertar?
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                      {rederspertarButtons.map((j, index) => (
                        <Button _hover={{ backgroundColor: j.bgColor }} onClick={() => onRedespertarButton(j, i)} borderRadius={"2px"} m={"2px"} size={"xs"} key={index} bg={j.bgColor} color={j.color}>
                          {j.name}
                        </Button>
                      ))}
                      <Formik
                        enableReinitialize
                        initialValues={{ data: '' }}
                        onSubmit={async (val, { setErrors }) => {
                          if (!val.data) {
                            setErrors({
                              data: 'Campo obrigatório'
                            })
                            return;
                          }

                          onRedespertarManual(val.data, i);
                        }}
                      >
                        {({ values }) => (
                          <Form>
                            <Flex width="full" alignItems={"end"}>
                              <InputField
                                label="Data / Hora"
                                name="data"
                                type="datetime-local"
                              />
                              <DefaultButton mb={1} type="submit">
                                Redespertar
                              </DefaultButton>
                            </Flex>
                          </Form>)}
                      </Formik>
                    </Text>
                  </Box>


                  <Box>
                    <AgendamentosButton pessoa_id={i.pessoa_id} />
                    <ContatosButton pessoa_id={i.pessoa_id} />
                    <UltimosPedidosButton pessoa_id={i.pessoa_id} />
                    <DadosPessoaButton pessoa_id={i.pessoa_id} onDelete={onSearch} />
                  </Box>
                </Stack>
              </CardBody>
            </Card>
          ))}
        </Grid>

        {agendamentos?.itens && <Pagination info={agendamentos?.itens} paginate={setPage} />}
      </Box>

    </Layout>
  )
}