import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import { formatData, getCurrentData } from "../../utils/data";
import { retornaApenasNumero } from "../../utils/formatString";
import { formatCpfCnpj } from "../../utils/formatCpfCnpj";
import { IPessoaRelatorioCliente } from "../views/RelatorioRelacaoCliente";
import { formatCelular } from "../../utils/formatCelular";
import { CamposPersonalizadosView } from "../../sistema/components/CamposPersonalizadosView";

Font.register({
  family: "Arial",
  fontStyle: "normal",
  fontWeight: "normal",
  src: "/fonts/Arial Unicode.ttf",
});

Font.register({
  family: "Arial",
  fontStyle: "normal",
  fontWeight: "bold",
  src: "/fonts/Arial Bold.ttf",
});

Font.register({
  family: "Arial",
  fontStyle: "italic",
  fontWeight: "normal",
  src: "/fonts/Arial Italic.ttf",
});

Font.register({
  family: "Arial",
  fontStyle: "italic",
  fontWeight: "bold",
  src: "/fonts/Arial Bold Italic.ttf",
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    marginTop: "1cm",
    marginBottom: "1cm",
    marginLeft: "1cm",
    marginRight: "1cm",
    width: "19cm",
    fontFamily: "Arial",
  },
  topoDemonstrativo: {
    flexDirection: "row",
    border: 1,
    borderRadius: 5,
    maxWidth: "19cm",
  },
  topoDadosBasicos: {
    flexDirection: "column",
    fontSize: 10,
    flexGrow: 1,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  topoDescricao: {
    marginLeft: "5mm",
    marginRight: "5mm",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  containerInfoHolerith: {
    flexDirection: "column",
    border: 1,
    borderRadius: 5,
    fontSize: 10,
    maxWidth: "19cm",
    marginTop: '0.5cm',
  },
  containerItensHolerith: {
    flexDirection: "row",
    minHeight: "24cm",
  },
  containerColunaHolerith: {
    flexDirection: "column",
    borderRight: 1,
    paddingLeft: "2mm",
    paddingRight: "2mm",
    paddingBottom: "2mm",
    fontSize: '8px',
  },
  textBold: {
    fontWeight: "bold",
  },
  textRight: {
    textAlign: "right",
  },
});

export const RelacaoClientePdf = ({ pessoas, parametros, filtros, itensArrayEmpresas, clientes }: { pessoas: IPessoaRelatorioCliente[], parametros: any, filtros: string[], itensArrayEmpresas: any; clientes: boolean; }) => {
  return (
    <Document title={`Relação de Clientes-${getCurrentData()}`}>
      <Page size="A4" style={styles.page}>
        <View>
          <Text style={{ fontSize: 9 }}>Relação de {clientes ? 'clientes' : 'fornecedores'} emitida em {formatData(getCurrentData())} </Text>
        </View>
        <View style={styles.topoDemonstrativo}>
          <Image
            src="/logo512.png"
            cache
            style={{ width: "50px", height: "50px", margin: "5mm" }}
          />

          <View style={styles.topoDadosBasicos}>
            <Text style={styles.textBold}>
              {parametros?.nome_empresa ? parametros?.nome_empresa : 'AG Solution'}
            </Text>
          </View>
          <View style={styles.topoDescricao}>
            <Text style={{ ...styles.textBold, fontSize: '9px' }}>Filtros utilizados</Text>{"\n"}
            <Text>
              <>
                {filtros.map((item, index) => (
                  <Text key={index} style={{ fontSize: '7px' }}>
                    &bull; {item} {"\n"}
                  </Text>
                ))}
              </>
            </Text>
          </View>
          <View style={styles.topoDescricao}>
            <Text style={styles.textBold}>Relação</Text>
            <Text style={styles.textBold}>De</Text>
            <Text style={styles.textBold}> {clientes ? 'Clientes' : 'Fornecedores'} </Text>
          </View>
        </View>
        <View style={styles.containerInfoHolerith}>
          {pessoas?.map((item, i: number) => (
            <View key={i} style={{ padding: '5px', borderBottom: '1px solid #d3d3d3', maxWidth: "19cm", }}>
              <Text style={{ fontWeight: 700, fontSize: '10px', color: 'black', width: '100%' }}>
                {item.nome}
              </Text>
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '60%' }}>
                  <Text style={{ fontSize: '7px' }}>
                    CPF/CNPJ: <Text style={styles.textBold}> {formatCpfCnpj(retornaApenasNumero(item?.cpf_cnpj ?? ''))}</Text> {"\n"}
                    Status: <Text style={styles.textBold}> {item.status} </Text>{"\n"}
                    E-mail: <Text style={styles.textBold}> {item.email ? item.email : 'Nenhum'} </Text>{"\n"}
                    <CamposPersonalizadosView isPdf={true} values={item?.camposPersonalizados ?? []} />
                  </Text>
                  {item?.contatos?.map((contato, index) => (
                    <Text key={index} style={{ fontSize: '7px', marginTop: '8px' }}>
                      <Text style={{ fontSize: '8px', marginTop: '5px', ...styles.textBold }}>
                        Contato {contato.tipo}
                      </Text> {"\n"}
                      Nome: <Text style={styles.textBold}> {contato.nome} </Text> -
                      Celular: <Text style={styles.textBold}> {formatCelular(contato.celular)} </Text>{"\n"}
                    </Text>
                  ))}
                </View>
                <View style={{ maxWidth: '40%' }}>
                  <Text style={{ fontSize: '7px' }}>
                    Endereço: <Text style={styles.textBold}> {item.endereco}, n° {item.numero} </Text>
                  </Text>
                  <Text style={{ fontSize: '7px' }}>
                    Cidade: <Text style={styles.textBold}> {item.cidade?.nome} - {item.cidade?.uf} </Text>
                  </Text>
                  <Text style={{ fontSize: '7px' }}>
                    Última Compra: <Text style={styles.textBold}> {item.ultima_compra ? formatData(item.ultima_compra) : 'Nenhuma'} </Text>
                  </Text>
                  <Text style={{ fontSize: '7px' }}>
                    Última Agenda: <Text style={styles.textBold}> {item.ultima_agenda ? formatData(item.ultima_agenda) : 'Nenhuma'} </Text>
                  </Text>
                  <Text style={{ fontSize: '7px' }}>
                    Última Ficha: <Text style={styles.textBold}> {item.ultima_ficha ? item.ultima_ficha : 'Nenhuma'} </Text>
                  </Text>
                </View>
              </View>

            </View>

          ))}
        </View>
      </Page >
    </Document >
  );
};
