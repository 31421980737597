import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useIsAuth } from "../../hooks/useIsAuth";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { cadastroActions } from "../reducer";
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import InputField from "../../sistema/components/InputField";
import { Form, Formik, useField } from "formik";
import { Box, Button, Flex } from "@chakra-ui/react";
import validateForm from "../../utils/validateForm";
import { ButtonHistorico } from "../../sistema/components/ButtonHistorico";
import InputNumber from "../../sistema/components/InputNumber";
import { TabelaPrecoItem } from "../types/tabela_preco_item";
import { ListHeaderSecondary } from "../../sistema/components/ListHeaderSecondary";
import { novaTabelaPrecoItem } from "../data/tabela_preco_item";
import { APIURL } from "../../utils/apiCall";
import { getDecimalPlacesOfSystem } from "../../sistema/utils/getDecimalPlacesOfSystem";

export const TabelaPrecoEdit = () => {
  useIsAuth();

  const { id } = useParams();

  const tabela = useSelector(
    (state: RootState) => state.cadastro.tabela || null
  );
  const isLoading = useSelector(
    (state: RootState) => !!state.cadastro.isLoading
  );
  const isMobile = useSelector((state: RootState) => !!state.sistema.isMobile);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!id) return;

    dispatch(cadastroActions.tabelaRequest(id));
  }, [dispatch, id]);

  const w1_2 = isMobile ? "100%" : "50%";
  const w1_3 = isMobile ? "100%" : "33%";
  const w1_4 = isMobile ? "100%" : "25%";
  const w1_5 = isMobile ? "100%" : "20%";
  const w1_10 = isMobile ? "100%" : "10%";

  const Itens = () => {
    const reordenarItens = (itens: TabelaPrecoItem[]) => {
      let posicao = 0;
      return itens.map((i) => {
        posicao++;

        return {
          ...i,
          posicao,
        };
      });
    };

    const [, { value }, { setValue }] = useField("itens");

    const onClickNovoItem = (posicao = -1) => {
      const itens = JSON.parse(JSON.stringify(value));

      const novoItem = novaTabelaPrecoItem();

      if (posicao === -1) {
        itens.push(novoItem);
      } else {
        itens.splice(posicao, 0, novoItem);
      }

      setValue(reordenarItens(itens));
      return;
    };

    const onClickRemoveItem = (index: number) => {
      const itens = JSON.parse(JSON.stringify(value));

      itens.splice(index, 1);

      setValue(reordenarItens(itens));
    };

    return (
      <Flex width={"full"} direction={"column"}>
        <ListHeaderSecondary label="Itens da Tabela de Preço" />

        {value &&
          value.map((item: TabelaPrecoItem, index: number) => (
            <Flex
              key={`${index}`}
              mx="auto"
              my={1}
              wrap={"wrap"}
              maxW={"750px"}
              py={2}
              px={4}
              width={"full"}
              bg={"white"}
              borderRadius={"15px"}
              boxShadow={"lg"}
            >
              <InputField
                label="Produto"
                name={`itens[${index}].produto`}
                width={isMobile ? "100%" : "70%"}
              />
              <InputNumber
                decimalPlaces={getDecimalPlacesOfSystem()}
                label="Preço"
                name={`itens[${index}].valor`}
                width={isMobile ? "100%" : "20%"}
              />
              <Flex
                direction={"column"}
                width={isMobile ? "100%" : "10%"}
                p={1}
                justifyContent={"center"}
              >
                <DefaultButton
                  size={"xs"}
                  mb={0.5}
                  fontSize={"18px"}
                  alignItems={"center"}
                  onClick={() => onClickNovoItem(index)}
                >
                  +
                </DefaultButton>
                <Button
                  colorScheme="red"
                  size={"xs"}
                  mb={0.5}
                  fontSize={"18px"}
                  alignItems={"center"}
                  onClick={() => onClickRemoveItem(index)}
                >
                  -
                </Button>
                <DefaultButton
                  size={"xs"}
                  fontSize={"18px"}
                  alignItems={"center"}
                  onClick={() => onClickNovoItem(index + 1)}
                >
                  +
                </DefaultButton>
              </Flex>
            </Flex>
          ))}
        <Flex mx={"auto"}>
          <DefaultButton px={10} my={2} onClick={() => onClickNovoItem()}>
            Novo Item
          </DefaultButton>
        </Flex>
      </Flex>
    );
  };

  const onClickImprimir = () => {
    window.open(`${APIURL}/tabelas-imprimir/${tabela?.id}`, "_blank");
  };

  return (
    <Layout>
      <ListHeader label="Tabela de Preço">
        <DefaultButton ml={4} to={`/tabelas`}>
          {" "}
          Voltar{" "}
        </DefaultButton>
        {tabela?.id && (
          <DefaultButton ml={1} onClick={onClickImprimir}>
            {" "}
            Imprimir{" "}
          </DefaultButton>
        )}
      </ListHeader>

      <Loader isLoading={isLoading} />

      {tabela && (
        <Formik
          enableReinitialize
          initialValues={tabela}
          onSubmit={(val, { setErrors }) => {
            const validation = validateForm({ nome: "required" }, val);
            if (Object.keys(validation).length > 0) {
              setErrors(validation);
              return;
            }

            dispatch(
              cadastroActions.saveTabelaRequest({
                ...val,
                changed: true,
              })
            );
          }}
        >
          {({ values }) => (
            <Form>
              <Flex width="full" wrap="wrap">
                <InputField name="nome" label="Nome" width={"full"} />

                <Itens />

                <Flex width={"full"} />
                <Box
                  bg={"white"}
                  mt={2}
                  width={"full"}
                  position="sticky"
                  bottom="10px"
                  zIndex="999"
                  p={2}
                >
                  <DefaultButton type="submit">
                    Salvar Tabela de Preço
                  </DefaultButton>
                  {values.id && (
                    <ButtonHistorico
                      par="tabela_preco"
                      idpar={values.id}
                      ml={2}
                    />
                  )}
                </Box>
              </Flex>
            </Form>
          )}
        </Formik>
      )}
    </Layout>
  );
};
