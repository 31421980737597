import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useIsAuth } from "../../hooks/useIsAuth";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import InputField from "../../sistema/components/InputField";
import { Form, Formik } from "formik";
import { Box, Flex } from "@chakra-ui/react";
import validateForm from "../../utils/validateForm";
import { ButtonHistorico } from "../../sistema/components/ButtonHistorico";
import { entradaesaidaActions } from "../recucer";
import InputCheck from "../../sistema/components/InputCheck";
import { CamposPersonalizadosForm } from "../../sistema/components/CamposPersonalizadosForm";

export const StockLocationEdit = () => {
  useIsAuth();

  const { id } = useParams();

  const stockLocation = useSelector(
    (state: RootState) => state.entradaesaida.stockLocation
  );
  const isLoading = useSelector(
    (state: RootState) => !!state.entradaesaida.isLoading
  );
  const isMobile = useSelector((state: RootState) => !!state.sistema.isMobile);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!id) return;

    dispatch(entradaesaidaActions.stockLocationRequest(id));
  }, [dispatch, id]);

  return (
    <Layout>
      <ListHeader label={"Local de Estoque"}>
        <DefaultButton ml={4} to={`/stock-location`}>
          {" "}
          Voltar{" "}
        </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      {stockLocation && (
        <Formik
          enableReinitialize
          initialValues={stockLocation}
          onSubmit={(val, { setErrors }) => {
            const validation = validateForm({ name: "required" }, val);
            if (Object.keys(validation).length > 0) {
              setErrors(validation);
              return;
            }

            dispatch(
              entradaesaidaActions.saveStockLocationRequest({
                ...val,
                changed: true,
              })
            );
          }}
        >
          {({ values }) => (
            <Form>
              <Flex width="full" wrap="wrap">
                <InputField width={"100%"} label="Nome" name="name" />

                <InputCheck
                  label="Ativo?"
                  name="active"
                  className="ml-3"
                  width={isMobile ? "100%" : "10%"}
                />

                <div className="w-full"></div>

                <CamposPersonalizadosForm
                  par="stock_locations"
                  idp={stockLocation?.id as number}
                />

                <Flex width={"full"} />
                <Box
                  bg={"white"}
                  mt={2}
                  width={"full"}
                  position="sticky"
                  bottom="10px"
                  zIndex="10"
                  p={2}
                >
                  <DefaultButton type="submit">
                    Salvar local de estoque
                  </DefaultButton>
                  {values.id && (
                    <ButtonHistorico
                      par="stock_locations"
                      idpar={values.id}
                      ml={2}
                    />
                  )}
                </Box>
              </Flex>
            </Form>
          )}
        </Formik>
      )}
    </Layout>
  );
};
