import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sistemaActions } from "../sistema/recucer";
import { RootState } from "../app/mainReducer";

export function useBuscaMensalidadesEmAberto() {
  const dispatch = useDispatch();
  const isLogged = useSelector((state: RootState) => !!state.sistema.token);

  useEffect(() => {
    if (isLogged) {
      dispatch(sistemaActions.mensalidadesEmAbertoRequest());
    }
  }, [dispatch, isLogged]);
}
