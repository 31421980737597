import { PayloadAction } from "@reduxjs/toolkit";
import { put, all, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { call } from "redux-saga/effects";
import { apiCall } from "../utils/apiCall";
import { Produto } from "./types/produto";
import { novoProduto } from "./data/produto";
import { entradaesaidaActions } from "./recucer";
import { formatError } from "../utils/formatError";
import { ListPayload } from "../sistema/types/listPayload";
import { ApiPaginationRes } from "../sistema/types/apiPaginationRes";
import { Sacola } from "./types/sacola";
import { novaSacola } from "./data/sacola";
import { NotaPromissoria } from "./types/nota_promissoria";
import { novaNotaPromissoria } from "./data/nota_promissoria";
import { StockLocation } from "./types/stock_location";
import { newStockLocation } from "./data/stock_location";
import { StockCurrent } from "./types/stock_current";
import { Stock } from "./types/stock";

function* produtosRequestWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<Produto[]>> = yield call(apiCall, {
      url: `produtos?termo=${payload.termo}&page=${payload.page}`,
      method: "get",
      data: payload,
    });
    yield put(entradaesaidaActions.produtosRequestSuccess(res.data));
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}

function* produtoRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "novo") {
      yield put(entradaesaidaActions.produtoRequestSuccess(novoProduto()));
      return;
    }

    const res: AxiosResponse<Produto> = yield call(apiCall, {
      url: `produtos/${payload}`,
      method: "get",
      data: payload,
    });
    yield put(entradaesaidaActions.produtoRequestSuccess(res.data));
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}


function* saveProdutoRequestWorker({ payload }: PayloadAction<Produto>) {
  try {
    const res: AxiosResponse<Produto> = yield call(apiCall, {
      url: `produtos`,
      method: "post",
      data: payload,
    });
    yield put(entradaesaidaActions.saveProdutoRequestSuccess(res.data));
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}

function* deleteProdutosRequestWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(apiCall, {
      url: `produtos/${payload}`,
      method: "delete",
      data: payload,
    });
    yield put(entradaesaidaActions.deleteProdutosRequestSuccess());
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}


function* sacolasRequestWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<Sacola[]>> = yield call(apiCall, {
      url: `sacolas?termo=${payload.termo}&page=${payload.page}&filtros=${JSON.stringify(payload.filtros)}`,
      method: "get",
      data: payload,
    });
    yield put(entradaesaidaActions.sacolasRequestSuccess(res.data));
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}

function* sacolaRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "novo") {
      yield put(entradaesaidaActions.sacolaRequestSuccess(novaSacola()));
      return;
    }

    const res: AxiosResponse<Sacola> = yield call(apiCall, {
      url: `sacolas/${payload}?include=itens,itens.produto`,
      method: "get",
      data: payload,
    });
    yield put(entradaesaidaActions.sacolaRequestSuccess(res.data));
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}


function* saveSacolaRequestWorker({ payload }: PayloadAction<Sacola>) {
  try {
    const res: AxiosResponse<Sacola> = yield call(apiCall, {
      url: `sacolas?include=itens,itens.produto`,
      method: "post",
      data: payload,
    });
    yield put(entradaesaidaActions.saveSacolaRequestSuccess(res.data));
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}

function* deleteSacolaRequestWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(apiCall, {
      url: `sacolas/${payload}`,
      method: "delete",
      data: payload,
    });
    yield put(entradaesaidaActions.deleteSacolaRequestSuccess());
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}


function* notasPromissoriasRequestWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<NotaPromissoria[]>> = yield call(apiCall, {
      url: `notas-promissorias?termo=${payload.termo}&page=${payload.page}&filtros=${JSON.stringify(payload.filtros)}`,
      method: "get",
      data: payload,
    });
    yield put(entradaesaidaActions.notasPromissoriasRequestSuccess(res.data));
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}

function* notaPromissoriaRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "novo") {
      yield put(entradaesaidaActions.notaPromissoriaRequestSuccess(novaNotaPromissoria()));
      return;
    }

    const res: AxiosResponse<NotaPromissoria> = yield call(apiCall, {
      url: `notas-promissorias/${payload}?include=itens,itens.produto,pagamentos,pagamentos.financeiro`,
      method: "get",
      data: payload,
    });
    yield put(entradaesaidaActions.notaPromissoriaRequestSuccess(res.data));
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}


function* saveNotaPromissoriaRequestWorker({ payload }: PayloadAction<NotaPromissoria>) {
  try {
    const res: AxiosResponse<NotaPromissoria> = yield call(apiCall, {
      url: `notas-promissorias?include=itens,itens.produto,pagamentos,pagamentos.financeiro`,
      method: "post",
      data: payload,
    });
    yield put(entradaesaidaActions.saveNotaPromissoriaRequestSuccess(res.data));
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}

function* deleteNotaPromissoriaRequestWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(apiCall, {
      url: `notas-promissorias/${payload}`,
      method: "delete",
      data: payload,
    });
    yield put(entradaesaidaActions.deleteNotaPromissoriaRequestSuccess());
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}



function* stocksLocationRequestWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<StockLocation[]>> = yield call(apiCall, {
      url: `stock-location?termo=${payload.termo}&page=${payload.page}`,
      method: "get",
      data: payload,
    });
    yield put(entradaesaidaActions.stocksLocationRequestSuccess(res.data));
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}

function* stockLocationRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (payload === "novo") {
      yield put(entradaesaidaActions.stockLocationRequestSuccess(newStockLocation()));
      return;
    }

    const res: AxiosResponse<StockLocation> = yield call(apiCall, {
      url: `stock-location/${payload}`,
      method: "get",
      data: payload,
    });
    yield put(entradaesaidaActions.stockLocationRequestSuccess(res.data));
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}


function* saveStockLocationRequestWorker({ payload }: PayloadAction<StockLocation>) {
  try {
    const res: AxiosResponse<StockLocation> = yield call(apiCall, {
      url: `stock-location`,
      method: "post",
      data: payload,
    });
    yield put(entradaesaidaActions.saveStockLocationRequestSuccess(res.data));
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}

function* deleteStockLocationRequestWorker({ payload }: PayloadAction<string>) {
  try {
    yield call(apiCall, {
      url: `stock-location/${payload}`,
      method: "delete",
      data: payload,
    });
    yield put(entradaesaidaActions.deleteStockLocationRequestSuccess());
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}

function* stocksCurrentRequestWorker({ payload }: PayloadAction<string>) {
  try {
    if (! payload) {
      yield put(entradaesaidaActions.stocksCurrentRequestSuccess([]));
      return;
    }

    const res: AxiosResponse<StockCurrent[]> = yield call(apiCall, {
      url: `stock-current-product/${payload}`,
      method: "get",
    });
    yield put(entradaesaidaActions.stocksCurrentRequestSuccess(res.data));
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}

function* stocksMovimentationsRequestWorker({ payload }: PayloadAction<ListPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<Stock[]>> = yield call(apiCall, {
      url: `stocks?termo=${payload.termo}&page=${payload.page}`,
      method: "get",
      data: payload,
    });
    yield put(entradaesaidaActions.stocksMovimentationsRequestSuccess(res.data));
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}

function* stocksMovimentationCancelRequestWorker({ payload }: PayloadAction<number>) {
  try {
    yield call(apiCall, {
      url: `stock-cancel/${payload}`,
      method: "post",
    });
    yield put(entradaesaidaActions.success("Estoque cancelado com sucesso!"));
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}

function* saveStockMovimentationRequestWorker({ payload }: PayloadAction<Stock>) {
  try {
    yield call(apiCall, {
      url: `stock-movimentation`,
      method: "post",
      data: payload,
    });
    yield put(entradaesaidaActions.success("Estoque salvo com sucesso!"));
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}

function* stockRecalculateWorker({ payload }: PayloadAction<number | string>) {
  try {
    yield call(apiCall, {
      url: `stock-recalculate/${payload}`,
      method: "post",
      data: payload,
    });
    yield put(entradaesaidaActions.success("Estoque recalculado com sucesso!"));
  } catch (error: any) {
    yield put(entradaesaidaActions.error(formatError(error)));
  }
}

export function* entradaesaidaSaga() {
  yield all([
    takeLatest("entradaesaida/produtoRequest", produtoRequestWorker),
    takeLatest("entradaesaida/saveProdutoRequest", saveProdutoRequestWorker),
    takeLatest("entradaesaida/deleteProdutosRequest", deleteProdutosRequestWorker),
    takeLatest("entradaesaida/produtosRequest", produtosRequestWorker),
    takeLatest("entradaesaida/sacolaRequest", sacolaRequestWorker),
    takeLatest("entradaesaida/saveSacolaRequest", saveSacolaRequestWorker),
    takeLatest("entradaesaida/deleteSacolaRequest", deleteSacolaRequestWorker),
    takeLatest("entradaesaida/sacolasRequest", sacolasRequestWorker),
    takeLatest("entradaesaida/notasPromissoriasRequest", notasPromissoriasRequestWorker),
    takeLatest("entradaesaida/notaPromissoriaRequest", notaPromissoriaRequestWorker),
    takeLatest("entradaesaida/saveNotaPromissoriaRequest", saveNotaPromissoriaRequestWorker),
    takeLatest("entradaesaida/deleteNotaPromissoriaRequest", deleteNotaPromissoriaRequestWorker),

    takeLatest("entradaesaida/stocksLocationRequest", stocksLocationRequestWorker),
    takeLatest("entradaesaida/stockLocationRequest", stockLocationRequestWorker),
    takeLatest("entradaesaida/saveStockLocationRequest", saveStockLocationRequestWorker),
    takeLatest("entradaesaida/deleteStockLocationRequest", deleteStockLocationRequestWorker),

    takeLatest("entradaesaida/stocksCurrentRequest", stocksCurrentRequestWorker),
    takeLatest("entradaesaida/stocksMovimentationsRequest", stocksMovimentationsRequestWorker),
    takeLatest("entradaesaida/stocksMovimentationCancelRequest", stocksMovimentationCancelRequestWorker),
    takeLatest("entradaesaida/saveStockMovimentationRequest", saveStockMovimentationRequestWorker),
    takeLatest("entradaesaida/stockRecalculate", stockRecalculateWorker),
  ]);
}
